import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


// EB-17970, service for enabling/disabling application (clicks on application, setting opacity... when overlay is displayed)
@Injectable({
  providedIn: 'root'
})
export class DisableScreenService {
public disableScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
}
