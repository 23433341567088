import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, delay, exhaustMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PromptSessionExpiredComponent } from '../../popups/prompt-session-expired/prompt-session-expired.component';
// import { PuLogoutComponent } from '../../popups/pu-logout/pu-logout.component';
// import { PuLostPasswordComponent } from '../../popups/pu-lost-password/pu-lost-password.component';
import { AuthService } from '../../services/auth.service';
import {
  AuthActionTypes,
  Auth_userLostPassword_apply,
  Auth_userLostPassword_completed_ko, Auth_userLostPassword_completed_ok, Login,
  LoginFailure, LoginSuccess,
  LogoutCancelled, LogoutComplete, LogoutConfirmed,
  SessionExpired,
} from '../actions/auth.actions';
import { AuthErrorTypes } from '../models/auth-error.model';
import * as fromAuthReducer from '../reducers/auth.reducer';

import { UrlService } from 'src/app/services/url.service';

@Injectable()
export class AuthEffects {
  public userLostPassword$ = this.storeAuth.select(fromAuthReducer.selectUserLostPassword);

  private urlStart = '';

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dialogService: MatDialog,
    private storeAuth: Store<fromAuthReducer.AuthState>,
    private urlService: UrlService
  ) {
    this.urlStart = urlService.environmentType() === 'PPD' ? 'pre-simcard' : 'simcard';
  }

  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    // tap(() => console.log('Entered auth effects - login')),
    exhaustMap(res => this.authService.login(res.payload).pipe(
      map(res1 => new LoginSuccess(res1)),
      catchError((err) => {
        // console.log('auth effects - login', err);
        if (!isNullOrUndefined(err) && !isNullOrUndefined(err.error) && !isNullOrUndefined(err.error.code)) {
          if (err.error.code === 1024) {
            return of(new LoginFailure(AuthErrorTypes.SIM_NOT_ALLOWED));
          } else if (err.error.code === 8006) {
            return of(new LoginFailure(AuthErrorTypes.SIM_NOT_FOUND));
          }
        } else {
          if (err.status === 401) {
            return of(new LoginFailure(AuthErrorTypes.AUTHORIZATION_FAILURE));
          }
        }
        return of(new LoginFailure(AuthErrorTypes.UNKNOWN_ERROR));
      })
    ))
  ));


  // logout$ = createEffect(() => this.actions$.pipe(
  //   ofType<Logout>(AuthActionTypes.Logout),
  //   exhaustMap(() =>
  //     this.dialogService
  //       .open(PuLogoutComponent)
  //       .afterClosed()
  //       .pipe(
  //         take(1),
  //         map(confirmed => {
  //           if (confirmed === true) {
  //             return new LogoutConfirmed();
  //           } else {
  //             return new LogoutCancelled();
  //           }
  //         })
  //       ),
  //   )));

  logoutConfirmed$ = createEffect(() => this.actions$.pipe(
    ofType<LogoutConfirmed>(AuthActionTypes.LogoutConfirmed),
    exhaustMap(() =>
      this.authService
        .logout()
        .pipe(
          delay(0),
          // tap(() => console.log('Logout confirmed')),
          tap(() => this.router.navigate([this.urlStart + '/register'])),
          map(() => new LogoutComplete()),
          catchError(() => of(new LogoutComplete())),
        ),
    ),
  ));

  // Auth_userLostPassword_init_Effect$ = createEffect(() => this.actions$.pipe(
  //   ofType<Auth_userLostPassword_init>(AuthActionTypes.Auth_userLostPassword_init),
  //   exhaustMap(() =>
  //     this.dialogService.open(PuLostPasswordComponent).afterClosed().pipe(
  //       take(1),
  //       map(confirmed => {
  //         if (confirmed === true) {
  //           return new Auth_userLostPassword_apply();
  //         } else {
  //           return new Auth_userLostPassword_cancel();
  //         }
  //       })
  //     )
  //   ))
  // );

  AuthUserLostPasswordApplyEffect$ = createEffect(() => this.actions$.pipe(
    ofType<Auth_userLostPassword_apply>(AuthActionTypes.Auth_userLostPassword_apply),
    withLatestFrom(this.userLostPassword$),
    map(([action, userLostPassword]) => ({ action, userLostPassword })),
    mergeMap(res => this.authService.userLostPassword(res.userLostPassword.request.username).pipe(
      map(_res => new Auth_userLostPassword_completed_ok(_res)),
      catchError(err => of(new Auth_userLostPassword_completed_ko(err)))
    ))
  ));

  sessionExpired$ = createEffect(() => this.actions$.pipe(
    ofType<SessionExpired>(AuthActionTypes.SessionExpired),
    exhaustMap(() =>
      this.dialogService
        .open(PromptSessionExpiredComponent)
        .afterClosed()
        .pipe(
          take(1),
          map(confirmed => {
            if (confirmed) {
              // this.router.navigateByUrl('/auth/login');
              return new LogoutConfirmed();
              // return null;
            } else {
              return new LogoutCancelled();
              // return null;
            }
          })
        ),
    ),
  ));

}
