import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNullOrUndefined } from '@mvneco/eb-core';

import { CrumbsState } from '../../models/crumb.model';
import { BreadcrumbActions, BreadcrumbActionTypes } from '../actions/crumb.actions';


export const breadcrumbFeatureKey = 'breadcrumb';

export const initialCrumbsState: CrumbsState = {
  crumbs: []
};

export function reducer(
  state = initialCrumbsState,
  action: BreadcrumbActions
): CrumbsState {

  switch (action.type) {

    case BreadcrumbActionTypes.BreadcrumbInitializeCrumbsAction: {
      // console.log('in BreadcrumbInitializeCrumbsAction reducer');

      const crumbs = isNullOrUndefined(action.crumb) ? [] : [action.crumb];
      return {
        crumbs
      };
    }

    case BreadcrumbActionTypes.BreadcrumbHandleNewCrumbAction: {
      // console.log('in BreadcrumbHandleNewCrumbAction reducer, crumb:', action.crumb);
      // console.log('in BreadcrumbHandleNewCrumbAction reducer, previous state:', state);

      const crumbs = Object.assign([], state.crumbs); // since state.crumbs is immutable

      if (action.crumb.forceReset) { // erase all the crumbs
        crumbs.length = 0;
      } else { // intercept user click on a previously existing array element
        const indexOfFirstOccurrence = state.crumbs.findIndex(c => c.path === action.crumb.path);
        if (indexOfFirstOccurrence !== -1) {
          crumbs.length = indexOfFirstOccurrence;
        }
      }

      crumbs.push(action.crumb);

      return {
        crumbs
      };
    }

    default:
      return state;
  }
}

export const SELECT_CRUMBS = (state: CrumbsState) => state.crumbs;

export const selectCrumbsState = createFeatureSelector<CrumbsState>(breadcrumbFeatureKey);

export const selectCrumbs = createSelector(selectCrumbsState, SELECT_CRUMBS);
