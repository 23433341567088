// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { isEmptyInputValue, isNullOrUndefined } from '@mvneco/eb-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BehaviorSubject, combineLatest, defer, iif, of, Subject } from 'rxjs';
import { catchError, concatMap, debounceTime, delay, distinctUntilChanged, distinctUntilKeyChanged, exhaustMap, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Credentials } from 'src/app/modules/auth/store/models/credentials.model';
import { OptionTypeDto, ResetProcessRequestDto, SubscriptionGetCompactTariffOptions3RequestDto } from 'src/app/shared/api-types';
import { ProductCatalogueService } from '../../services/product-catalogue.service';
import {
  ActivationActionTypes, ResetActivation,
  LoadActivationState, ResetSimData, ResetSimDataKo, ResetSimDataOk, StartActivation, StartActivationFailure, FinishActivation, UpdateAcceptTerms, UpdateTariffOptionStatus, TariffDataLoad
} from '../actions/activation.actions';
import { ActivationErrorTypes } from '../models/activation-errors.model';
import { NavigationStart, Router } from '@angular/router';
import { ActivationService } from '../../services/activation.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'src/app/components/info-dialog/info-dialog.component';
import { ActivationState } from '../reducers/activation.reducer';
import * as fromActivationReducer from '../reducers/activation.reducer';
import { AppFeaturesService } from '@mvneco/app-features';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UrlService } from 'src/app/services/url.service';
import { Store } from '@ngrx/store';
import * as fromAppSettingsConfigActions from '../../../app-settings/store/actions/app-settings-config.actions';
import * as fromAppSettingsConfigStore from '../../../app-settings/store/reducers/app-settings-config.reducer';
import { TariffDataModel, TariffOptionSelectionStatus } from '../models/tariff-data.model';
import { HttpErrorResponse } from '@angular/common/http';
import { initAppSettingsModule } from 'src/app/modules/app-settings/app-settings.module';
@Injectable()
export class ActivationEffects {

  private urlStart = '';
  private url = null;
  private simNumber: string = null;
  private state: LoadActivationState = null
private continue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private activationService: ActivationService,
    private productCatalogueService: ProductCatalogueService,
    private router: Router,
    private dialogService: MatDialog,
    private appFeatures: AppFeaturesService,
    private urlService: UrlService,
    private store: Store<fromAppSettingsConfigStore.AppSettingsConfigState>,
    private storeActivation: Store<fromActivationReducer.ActivationState>
  ) {
    this.urlStart = urlService.environmentType() === 'PPD' ? 'pre-simcard' : 'simcard';

    this.router.events.pipe(
      filter((res) => res instanceof NavigationStart),
      // tap((res) => console.log('url: ', res)),
      tap((res) => this.url = (res as NavigationStart).url)
    ).subscribe();
  }

  

  startActivation$ = createEffect(() => this.actions$.pipe(
    ofType<StartActivation>(ActivationActionTypes.Activation_Start),
    concatMap(res => combineLatest([
      of(res),
      this.appFeatures.getAppFeatures()
    ]).pipe(
      map(([startActivation, appFeatures]) => ({ startActivation, appFeatures })),
      map((res1) => {

        // in case of status READY_FOR_ACTIVATION, need to keep sim/iccid, EB-17713
        this.simNumber = res1.startActivation.payload.sim;

        const credentials: Credentials = {
          iccid: res1.startActivation.payload.sim,
          puk2: res1.startActivation.payload.puk2,
          realm: res1.appFeatures.appType
        };
        return credentials;
      }),
      mergeMap((res2: Credentials) => this.authService.login(res2)
        .pipe(
          mergeMap((resCred) => {
             // EB-17712 MDAT-1039 FE: route customer to new page simcard/reg/confirmIdentity when READY_FOR_ACTIVATION
            let identificationConfirmed = null;

            if (!isNullOrUndefined(resCred.customer) && !isNullOrUndefined(resCred.customer.properties)
            && !isNullOrUndefined(resCred.customer.properties.property) && !isEmptyInputValue(resCred.customer.properties.property)
            && !isNullOrUndefined(resCred.customer.properties.property.filter(it => it.name === 'identityConfirmationByCustomer')[0]))
            {
              identificationConfirmed = resCred.customer.properties.property.filter(it => it.name === 'identityConfirmationByCustomer')[0];
            }
            // Checks introduced in EB-17531
            // console.log('startActivation effect - resCred', resCred);
            if (!isNullOrUndefined(resCred?.subStatus)) {

              // In case subStatus is not READY_FOR_ACTIVATION, set "simNumber" to null
              if (resCred.subStatus !== 'READY_FOR_ACTIVATION') {
                  this.simNumber = null;
              }

              if (resCred.subStatus === 'COMPLETE') {
                this.activationService.changedSimInput$.next(false);
                throw {
                  error: {
                    code: -100
                  }
                };
              } else if (resCred.subStatus === 'TERMINATED') {
                this.activationService.changedSimInput$.next(false);
                throw {
                  error: {
                    code: -200
                  }
                };
              }
               // EB-17712 MDAT-1039 FE: route customer to new page simcard/reg/confirmIdentity when READY_FOR_ACTIVATION
              // else if (resCred.subStatus === 'READY_FOR_ACTIVATION' && (isNullOrUndefined(identificationConfirmed) ||
              // (!isNullOrUndefined(identificationConfirmed.value) && identificationConfirmed.value === 'false'))) {
              //   this.activationService.changedSimInput$.next(false);
              //   throw {
              //     error: {
              //       code: -300
              //     }
              //   };
              // }
            }

            // Check introduced in #4737 MDAT-1165
            if (resCred?.resource?.status === 'PRE_INITIAL') {
              this.simNumber = null;
              this.activationService.changedSimInput$.next(false);
              throw {
                error: {
                  code: -400
                }
              };
            }

            // Load product catalog and together with user model build StartActivationSuccess Action
            return iif(() => resCred.resource !== null,
              of(resCred).pipe(
                concatMap((resCred1) => this.productCatalogueService.getProductCatalogue(resCred1.resource.sim)),
                map((tariffData) => this.activationService.mapToActivationData(tariffData, resCred))
              ),
              of(null).pipe(
                map(() => this.activationService.mapToActivationData(null, resCred, this.simNumber))
              )
            );
          }),
          map((resActState: ActivationState) => {
            this.simNumber = null;
            return new LoadActivationState(resActState);
          }),
          catchError((err: any) => {
            this.activationService.changedSimInput$.next(false);
            // console.log('startActivation effect / caught error:', err);
            if (!isNullOrUndefined(err) && !isNullOrUndefined(err.error) && !isNullOrUndefined(err.error.code)) {
              if (err.error.code === 1024) {
                return of(new StartActivationFailure(ActivationErrorTypes.SIM_NOT_ALLOWED));
              } else if (err.error.code === 1023) {
                return of(new StartActivationFailure(ActivationErrorTypes.SIM_CURRENTLY_BLOCKED));
              } else if (err.error.code === 8006) {
                return of(new StartActivationFailure(ActivationErrorTypes.SIM_NOT_FOUND));
              } else if (err.error.code === -100) {
                return of(new StartActivationFailure(ActivationErrorTypes.CUSTOMER_DATA_ALREADY_SENT));
              } else if (err.error.code === -200) {
                return of(new StartActivationFailure(ActivationErrorTypes.SIM_CARD_DEACTIVATED));
              } else if (err.error.code === -300) {
                return of(new StartActivationFailure(ActivationErrorTypes.MANUAL_CHECK_IN_PROGRESS));
              } else if (err.error.code === -400) {
                return of(new StartActivationFailure(ActivationErrorTypes.SIM_STATUS_PRE_INITIAL));
              } else {
                return of(new StartActivationFailure(ActivationErrorTypes.UNKNOWN_ERROR));
              }
            } else {
              if (err.status === 401) {
                return of(new StartActivationFailure(ActivationErrorTypes.AUTHORIZATION_FAILURE));
              }
            }
            return of(new StartActivationFailure(ActivationErrorTypes.UNKNOWN_ERROR));
          }),
          /**
           * Load app settings in case connection/login is successful.
           */
          tap((res: LoadActivationState) => {
            if (res instanceof LoadActivationState) {
              this.store.dispatch(fromAppSettingsConfigActions.loadAppSettingsConfig());
            }
          })
        )
      )
    ))));

  loadActivationState$ = createEffect(() => this.actions$.pipe(
    ofType<LoadActivationState>(ActivationActionTypes.Activation_State_Load),
    tap(res => this.state = res),
    delay(0),
    // switchMap(() => this.router.events.pipe(
    //   delay(0),
    //   filter(() => this.continue$.getValue() === true),
    //   tap(res =>{
    //    this.url = (res as NavigationStart).url;
    //   console.log('url nav start: ', (res as NavigationStart).url);
    //   this.continue$.next(false);
    // }
    // ))),
    // delay(0),
    // tap(() => this.store.dispatch(
    //   fromAppSettingsConfigActions.loadAppSettingsConfig()
    // )),
    switchMap((res) => {
      return iif(() => isNullOrUndefined(this.url),
      defer(() => this.router.events.pipe(
        delay(0),
        take(1),
        tap((res) => this.url = (res as NavigationStart).url)
      )),
      defer(() => of(this.url)))
    }),
    switchMap(() => {
      // console.log('this.url: ', this.url);
       // EB-17712 MDAT-1039 FE: route customer to new page simcard/reg/confirmIdentity when READY_FOR_ACTIVATION
      let identificationConfirmed = null;
      if (!isNullOrUndefined(this.state.payload.customerData) && !isNullOrUndefined(this.state.payload.customerData.properties) &&
      !isNullOrUndefined(this.state.payload.customerData.properties.property) && !isEmptyInputValue(this.state.payload.customerData.properties.property))
      {
      identificationConfirmed = this.state.payload.customerData.properties.property.filter(it => it.name === 'identityConfirmationByCustomer')[0];

      }
      // if (isNullOrUndefined(res) || isNullOrUndefined(res.payload)) {
      //   return;
      // }

      this.urlStart = this.urlService.environmentType() === 'PPD' ? 'pre-simcard' : 'simcard';
      // console.log('this.url', this.url);
      // console.log('this.router.url: ', this.router.url);
      if (this.url === '/simcard/register' && this.router.url !== '/simcard/register') {
        return this.authService.logout();
      }

      else if ((this.state.payload.activationStatus === 'INITIAL')) {
        this.router.navigateByUrl(this.urlStart + '/reg/customer'); // TBD: Remove comment
      } else if ((this.state.payload.activationStatus === 'REG' || this.state.payload.activationStatus === 'IDENT_ORDERED')) {
        this.store.dispatch(
          new UpdateAcceptTerms(true)
        );
        this.router.navigateByUrl(this.urlStart + '/ident/select');
      }
      // else if (res.payload.activationStatus === 'COMPLETE') {
      //   this.router.navigateByUrl(this.urlStart + '/reg/overview');
      // }

      // EB-17713, if status is READY_FOR_ACTIVATION, navigate to /reg/confirmIdentity
      // EB-17712 MDAT-1039 FE: route customer to new page simcard/reg/confirmIdentity when READY_FOR_ACTIVATION
        else if (this.state.payload.activationStatus === 'READY_FOR_ACTIVATION' && !isNullOrUndefined(identificationConfirmed) &&
        (isNullOrUndefined(identificationConfirmed.value) || identificationConfirmed.value === 'true')) {
          this.router.navigateByUrl(this.urlStart + '/reg/confirmIdentity');
        }

        else if (this.state.payload.activationStatus === 'READY_FOR_ACTIVATION' && (isNullOrUndefined(identificationConfirmed) 
        || identificationConfirmed.value === 'false')) {
          this.router.navigateByUrl(this.urlStart + '/reg/overview');
        }
        return of(null);
    }),
    // tap(() => {
    //   this.store.dispatch(fromAppSettingsConfigActions.loadAppSettingsConfig());
    // }),
    // filter(() => this.url === '/simcard/register' && this.router.url !== '/simcard/register'),
    // tap(() => console.log('logout!')),
    // mergeMap(() => this.authService.logout())

  ), { dispatch: false });

  resetSimData$ = createEffect(() => this.actions$.pipe(
    ofType<ResetSimData>(ActivationActionTypes.SimData_Reset),
    map(res => ({
      sim: res.payload.sim,
      puk2: res.payload.puk2,
      action: 'RESET',
      sendCustomerEmail: false
    }) as ResetProcessRequestDto),
    exhaustMap((res) => this.activationService.simReset(res).pipe(

      map((res2) => {
        if (res2 instanceof HttpErrorResponse) {
          return new ResetSimDataKo();
        }
        else {
          return new ResetSimDataOk();
        }
      }),

      catchError(err => {
        // tslint:disable-next-line: no-unused-expression
        new ResetSimDataKo();
        return of(err);
      }),
    )),
  ));

  resetSimDataOk$ = createEffect(() => this.actions$.pipe(
    ofType<ResetSimDataOk>(ActivationActionTypes.SimData_Reset_Ok),
    exhaustMap(() =>
      this.dialogService.open(InfoDialogComponent,
        { data: { title: 'Status zurücksetzen', infoText: 'Sim wurde erfolgreich zurückgesetzt!', btnText: 'OK' } })
        .afterClosed()
        .pipe(
          map(() => new ResetActivation())
        )
    )
  ));


  resetSimDataKo$ = createEffect(() => this.actions$.pipe(
    ofType<ResetSimDataKo>(ActivationActionTypes.SimData_Reset_Ko),
    exhaustMap(() =>
      this.dialogService.open(InfoDialogComponent,
        { data: { title: 'Status zurücksetzen', infoText: 'Sim wurde nicht zurückgesetzt.', btnText: 'OK' } })
        .afterClosed()
    )), { dispatch: false });

  resetActivation$ = createEffect(() => this.actions$.pipe(
    ofType<ResetActivation>(ActivationActionTypes.Activation_Reset),
    tap(() => this.router.navigateByUrl(this.urlStart + '/register'))
  ), { dispatch: false });

  finishActivation$ = createEffect(() => this.actions$.pipe(
    ofType<FinishActivation>(ActivationActionTypes.Activation_Finish),
    tap((res) => window.open(res.payload, '_self'))
  ), { dispatch: false });

  updateTariffOption$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTariffOptionStatus>(ActivationActionTypes.TariffData_UpdateOptionStatus),
    withLatestFrom(this.storeActivation.select(fromActivationReducer.selectSimData), this.storeActivation.select(fromActivationReducer.selectTariffData)),
    map(([payload, sim, tariffData]) => ({ payload: payload.payload, sim: sim.sim, tariffData })),
    map((res) => {

      const options = {
        option: this.getOptions(res.tariffData, res.payload) as OptionTypeDto[]
      };

      const req: SubscriptionGetCompactTariffOptions3RequestDto = {
        sim: res.sim,
        options: !isNullOrUndefined(options.option) ? options : undefined
      };
      return req;
    }),
    concatMap((res) => this.productCatalogueService.updateProductCatalogue(res)
    ),
    map((res) => {
      const tariffData = res;
      Object.keys(tariffData.tariffOptionGroups).forEach(group => {
        Object.keys(tariffData.tariffOptionGroups[group].tariffOptions).forEach(optionCode => {
          if (!tariffData.tariffOptionGroups[group].tariffOptions[optionCode].possibleActions?.action?.includes('ADD') &&
            tariffData.tariffOptionGroups[group].tariffOptions[optionCode].status === 'ACTIVE') {
            tariffData.tariffOptionGroups[group].tariffOptions[optionCode] = {
              ...tariffData.tariffOptionGroups[group].tariffOptions[optionCode],
              isSelected: true
            };
            if (!tariffData.tariffOptionGroups[group].hasOptionSelected) {
              tariffData.tariffOptionGroups[group].hasOptionSelected = true;
            }
          }
        });
      });
      tariffData.hasOptionSelected = false;

      for (const groupCode of Object.keys(tariffData.tariffOptionGroups)) {
        if (tariffData.tariffOptionGroups[groupCode].hasOptionSelected) {
          tariffData.hasOptionSelected = true;
          break;
        }
      }

      return tariffData;
    }),
    map(res => new TariffDataLoad(res))
  ));

  public getOptions(tariffData: TariffDataModel, selectedOption: TariffOptionSelectionStatus): OptionTypeDto[] {
    let options: OptionTypeDto[] = [];

    if (selectedOption.isSelected) {
      options = options.concat({
        action: 'ADD',
        optionCode: selectedOption.optionCode,
        optionGroupCode: selectedOption.groupCode
      });
      if (!isNullOrUndefined(selectedOption?.property?.value)) {
        const displayGroup = selectedOption?.property?.value;

        Object.keys(tariffData.tariffOptionGroups).forEach(group => {
          Object.keys(tariffData.tariffOptionGroups[group].tariffOptions).forEach(optionCode => {
            (tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.status === 'ACTIVE' || tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('REMOVE') ||
              tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('DELETE')) &&
              (!isNullOrUndefined(tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.properties?.property?.filter(prop => prop.name === 'DisplayGroup')[0]) &&
                tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.properties?.property?.filter(prop => prop.name === 'DisplayGroup')[0]?.value !== displayGroup) ?
              options = options.concat({
                action: 'ADD',
                optionCode,
                optionGroupCode: group
              // tslint:disable-next-line: no-unused-expression
              }) : undefined;
          });
        });

      }

      else if (selectedOption.groupCode === 'G0MNP')
      {
        Object.keys(tariffData.tariffOptionGroups).forEach(group => {
          Object.keys(tariffData.tariffOptionGroups[group].tariffOptions).forEach(optionCode => {
            (tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.status === 'ACTIVE' || tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('REMOVE') ||
              tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('DELETE')) &&
              group !== selectedOption.groupCode  ?
              options = options.concat({
                action: 'ADD',
                optionCode,
                optionGroupCode: group
              // tslint:disable-next-line: no-unused-expression
              }) : undefined;
          });
        });
      }
    }

    else if (selectedOption.optionCode === 'O1SPECIAL_001') {
      options = options.concat({
        action: 'ADD',
        optionCode: 'O1DUMMYSPEC',
        optionGroupCode: 'G1DUMMY'
      });

    } else if (selectedOption.isSelected !== true) {
      Object.keys(tariffData.tariffOptionGroups).forEach(group => {
        Object.keys(tariffData.tariffOptionGroups[group].tariffOptions).forEach(optionCode => {
          (tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.status === 'ACTIVE' || tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('REMOVE') ||
            tariffData?.tariffOptionGroups[group]?.tariffOptions[optionCode]?.possibleActions?.action?.includes('DELETE')) &&
            selectedOption.optionCode !== optionCode ?
            options = options.concat({
              action: 'ADD',
              optionCode,
              optionGroupCode: group
            // tslint:disable-next-line: no-unused-expression
            }) : undefined;
        });
      });
    }

    if (!options.some(opt => opt.optionCode === 'O1SPECIAL_001' && opt.optionGroupCode === 'G1ONLINESPEC')) {
      options = options.filter(opt => !(opt.optionCode === 'O1DUMMYSPEC' && opt.optionGroupCode === 'G1DUMMY'));
    }

    return options.length > 0 ? options : null;

  }
}
