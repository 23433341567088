/**
 * Never edit this file manually. It is generated as part of the building process
 */
export const AppInfo = {
    name: 'medion-alditalk-client',
    appName: 'medion-alditalk-client',
    version: '2.1.1' ,
    defaultLocale: 'de',
    di18nMode: 'TRANSLATE',
    git: {
        shortHash: 'dd63aab',
        hash: 'dd63aab9bf5fb2db0f908569bce113b76cc71428',
        subject: 'Merge branch \'R-7393\' into \'master\'',
        sanitizedSubject: 'Merge-branch-R-7393-into-master',
        authoredOn: '1731542581',
        committedOn: '1731542581',
        branch: 'HEAD',
    }
};

