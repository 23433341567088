import { Action } from '@ngrx/store';

import { ICrumb } from '../../models/crumb.model';


export enum BreadcrumbActionTypes {
  BreadcrumbInitializeCrumbsAction = '[Breadcrumb] Initialize crumbs action',
  BreadcrumbHandleNewCrumbAction = '[Breadcrumb] Handle new crumb action',
}


export class BreadcrumbInitializeCrumbsAction implements Action {
  readonly type = BreadcrumbActionTypes.BreadcrumbInitializeCrumbsAction;
  constructor(public crumb?: ICrumb) { }
}

export class BreadcrumbHandleNewCrumbAction implements Action {
  readonly type = BreadcrumbActionTypes.BreadcrumbHandleNewCrumbAction;
  constructor(public crumb: ICrumb) { }
}


export type BreadcrumbActions = BreadcrumbInitializeCrumbsAction |
                                BreadcrumbHandleNewCrumbAction;
