// tslint:disable: class-name
import { Action } from '@ngrx/store';
import { AuthErrorTypes } from '../models/auth-error.model';
import { Credentials } from '../models/credentials.model';

import { UserLostPasswordResult } from '../models/user-lost-password.model';
import { UserModel } from '../models/user.model';

/**
 * Action model:
 *
 * Login:
 * - Component emits action Login with credentials
 * - Effects carry out logins, are getting user details (or not) and end with LoginSuccess or LoginFailure
 */

export enum AuthActionTypes {

  CredentialsUpdate = '[Auth] Credentials Update',

  /**
   * issued by auth API in situations with OIDC or something similar requiring to set Auth state without additional effects
   */
  UserSet = '[Auth API] set user',

  /**
   * Login requested, classical login
   */
  Login = '[Login Page] Login',
  LoginSuccess = '[Auth API] Login Success',
  LoginFailure = '[Auth API] Login Failure',
  Logout = '[Auth] Confirm Logout',
  LogoutCancelled = '[Auth] Logout Cancelled',
  LogoutConfirmed = '[Auth] Logout Confirmed',
  LogoutComplete = '[Auth API] Logout Complete',

  /**
   * should cause pop-up dialog
   */
  SessionExpired = '[HTTP Interceptor] Session expired',
  SessionResume = '[Auth API] Session resume',


  Auth_userLostPassword_init = '[Auth] Auth_userLostPassword_init',
  Auth_userLostPassword_cancel = '[Auth] Auth_userLostPassword_cancel',
  Auth_userLostPassword_apply = '[Auth] Auth_userLostPassword_apply',
  Auth_userLostPassword_completed_ok = '[Auth] Auth_userLostPassword_completed_ok',
  Auth_userLostPassword_completed_ko = '[Auth] Auth_userLostPassword_completed_ko',
}


export class CredentialsUpdate implements Action {
  readonly type = AuthActionTypes.CredentialsUpdate;
  constructor(public payload: Credentials) { }
}

export class UserSet implements Action {
  readonly type = AuthActionTypes.UserSet;
  constructor(public payload: { user: UserModel }) { }
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: Credentials) { }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: UserModel) { }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;
  constructor(public payload: AuthErrorTypes) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutConfirmed implements Action {
  readonly type = AuthActionTypes.LogoutConfirmed;
}

export class LogoutCancelled implements Action {
  readonly type = AuthActionTypes.LogoutCancelled;
}

export class LogoutComplete implements Action {
  readonly type = AuthActionTypes.LogoutComplete;
}

export class SessionResume implements Action {
  readonly type = AuthActionTypes.SessionResume;
  constructor(public payload: UserModel) { }
}

export class Auth_userLostPassword_init implements Action {
  readonly type = AuthActionTypes.Auth_userLostPassword_init;
  constructor(public payload: { username: string }) { }
}

export class Auth_userLostPassword_cancel implements Action {
  readonly type = AuthActionTypes.Auth_userLostPassword_cancel;
}

export class Auth_userLostPassword_apply implements Action {
  readonly type = AuthActionTypes.Auth_userLostPassword_apply;
}

export class Auth_userLostPassword_completed_ok implements Action {
  readonly type = AuthActionTypes.Auth_userLostPassword_completed_ok;
  constructor(public payload: UserLostPasswordResult) { }
}

export class Auth_userLostPassword_completed_ko implements Action {
  readonly type = AuthActionTypes.Auth_userLostPassword_completed_ko;
  constructor(public payload: UserLostPasswordResult) { }
}


export class SessionExpired implements Action {
  readonly type = AuthActionTypes.SessionExpired;
}


export type AuthActions =
  | CredentialsUpdate
  | UserSet
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutCancelled
  | LogoutConfirmed
  | LogoutComplete
  | SessionExpired
  | SessionResume
  | Auth_userLostPassword_init
  | Auth_userLostPassword_cancel
  | Auth_userLostPassword_apply
  | Auth_userLostPassword_completed_ok
  | Auth_userLostPassword_completed_ko;
