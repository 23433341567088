import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from 'src/app/app-routing.module';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import * as breadcrumbReducer from './store/reducers/crumb.reducer';
import { Di18nModule } from '@mvneco/di18n';


@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    StoreModule.forFeature(
      breadcrumbReducer.breadcrumbFeatureKey,
      breadcrumbReducer.reducer,
      { initialState: breadcrumbReducer.initialCrumbsState }
    ),
    Di18nModule
  ],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbModule { }
