/* tslint:disable:variable-name */
/* tslint:disable:no-empty-interface */
/* tslint:disable: align */
/* tslint:disable: max-line-length */

/**
 * provides API extra models which can not be expressed in Open API
 * see: https://docs.nestjs.com/openapi/types-and-parameters#extra-models
 *
 * If you do not have extra models, just create and check-in an empty file "./api-extra-models.ts".
 * If you have extra models, look at the DTOs of the service for definitions in file "./api-extra-models.ts". Thanks a lot.
 */
import * as fromApiExtraModels from  './api-extra-models';

/**
 * ILoginRequestDto
 */
export interface ILoginRequestDto {
  /**
   * ICCID of SIM card for login
   * - minLength: 12
   * - maxLength: 20
   */
  iccid: string;
  /**
   * PUK2 of SIM card for login
   * - minLength: 0
   * - maxLength: 20
   */
  puk2: string;
  /**
   * Realm to use for login
   * - minLength: 3
   * - maxLength: 40
   * Enum, one of:
   * - app
   * - ccs
   * - web
   */
  realm: string;
}


/**
 * LoginRequestDto
 *  see ILoginRequestDto
 */
export class LoginRequestDto {
  /**
   * ICCID of SIM card for login
   * - minLength: 12
   * - maxLength: 20
   */
  readonly iccid: string;
  /**
   * PUK2 of SIM card for login
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2: string;
  /**
   * Realm to use for login
   * - minLength: 3
   * - maxLength: 40
   * Enum, one of:
   * - app
   * - ccs
   * - web
   */
  readonly realm: string;

  constructor(options: {
    iccid: string;
    puk2: string;
    realm: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.iccid =
      (options !== undefined && options !== null && options.iccid !== undefined && options.iccid !== null) ?
        options.iccid + '' :
       null;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       null;
    this.realm =
      (options !== undefined && options !== null && options.realm !== undefined && options.realm !== null) ?
        options.realm + '' :
       null;
  }
}


/**
 * ICompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto
 */
export interface ICompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto {
  /**
   * - minItems: 1
   * - maxItems: 6
   */
  action?: string[];
}


/**
 * CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto
 *  see ICompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto
 */
export class CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto {
  /**
   * - minItems: 1
   * - maxItems: 6
   */
  readonly action?: string[];

  constructor(options: {
    action?: string[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.action = new Array<string>();
    if (options !== undefined && options !== null && options.action !== undefined && options.action !== null) {
      for (const item of options.action) {
        this.action.push(item);
      }
    }
  }
}


/**
 * ICompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto
 */
export interface ICompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto {
  id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  groupCode: string;
  insufficientFunds?: boolean;
}


/**
 * CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto
 *  see ICompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto
 */
export class CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto {
  readonly id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly groupCode: string;
  readonly insufficientFunds?: boolean;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    groupCode: string;
    insufficientFunds?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.groupCode =
      (options !== undefined && options !== null && options.groupCode !== undefined && options.groupCode !== null) ?
        options.groupCode + '' :
       null;
    this.insufficientFunds = (options !== undefined && options !== null && options.insufficientFunds !== undefined && options.insufficientFunds !== null) ?
        !!options.insufficientFunds :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto
 */
export interface ICompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  alternateOption?: ICompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto[];
}


/**
 * CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto
 *  see ICompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto
 */
export class CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly alternateOption?: CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto[];

  constructor(options: {
    alternateOption?: CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.alternateOption = new Array<CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto>();
    if (options !== undefined && options !== null && options.alternateOption !== undefined && options.alternateOption !== null) {
      for (const item of options.alternateOption) {
        this.alternateOption.push(new CompactTariffOption3TypeAlternateOptionsAlternateOptionAlternateOptionAnonymDto(item, config));
      }
    }
  }
}


/**
 * ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto
 */
export interface ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto {
  id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  groupCode: string;
  insufficientFunds?: boolean;
}


/**
 * CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto
 *  see ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto
 */
export class CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto {
  readonly id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly groupCode: string;
  readonly insufficientFunds?: boolean;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    groupCode: string;
    insufficientFunds?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.groupCode =
      (options !== undefined && options !== null && options.groupCode !== undefined && options.groupCode !== null) ?
        options.groupCode + '' :
       null;
    this.insufficientFunds = (options !== undefined && options !== null && options.insufficientFunds !== undefined && options.insufficientFunds !== null) ?
        !!options.insufficientFunds :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto
 */
export interface ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  schedAlternateOption?: ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto[];
}


/**
 * CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto
 *  see ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto
 */
export class CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly schedAlternateOption?: CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto[];

  constructor(options: {
    schedAlternateOption?: CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.schedAlternateOption = new Array<CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto>();
    if (options !== undefined && options !== null && options.schedAlternateOption !== undefined && options.schedAlternateOption !== null) {
      for (const item of options.schedAlternateOption) {
        this.schedAlternateOption.push(new CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionSchedAlternateOptionAnonymDto(item, config));
      }
    }
  }
}


/**
 * IItemPriceTypeDto
 */
export interface IItemPriceTypeDto {
  /**
   * internal surrogate of item
   */
  id: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  code?: string;
  /**
   * Enum, one of:
   * - EUR
   */
  currency: string;
  /**
   * VAT as decimal with 1 decimal digit, e.g.
   * 19.0
   */
  vat: number;
  /**
   * prices without taxes (netto), in hundreds of cents
   */
  oneTimeCharge?: string;
  /**
   * prices without taxes (netto), in hundreds of cents
   */
  reoccuringCharge?: string;
  isProrata: boolean;
  /**
   * prices with taxes (brutto), in hundreds of cents
   * - minLength: 0
   * - maxLength: 45
   */
  grossCharge?: string;
  /**
   * Discount prices without taxes (netto) by subscription activation, in hundreds of cents
   */
  discountedActivationPrice?: string;
}


/**
 * ItemPriceTypeDto
 *  see IItemPriceTypeDto
 */
export class ItemPriceTypeDto {
  /**
   * internal surrogate of item
   */
  readonly id: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  readonly code?: string;
  /**
   * Enum, one of:
   * - EUR
   */
  readonly currency: string;
  /**
   * VAT as decimal with 1 decimal digit, e.g.
   * 19.0
   */
  readonly vat: number;
  /**
   * prices without taxes (netto), in hundreds of cents
   */
  readonly oneTimeCharge?: string;
  /**
   * prices without taxes (netto), in hundreds of cents
   */
  readonly reoccuringCharge?: string;
  readonly isProrata: boolean;
  /**
   * prices with taxes (brutto), in hundreds of cents
   * - minLength: 0
   * - maxLength: 45
   */
  readonly grossCharge?: string;
  /**
   * Discount prices without taxes (netto) by subscription activation, in hundreds of cents
   */
  readonly discountedActivationPrice?: string;

  constructor(options: {
    id: string;
    name: string;
    code?: string;
    currency: string;
    vat: number;
    oneTimeCharge?: string;
    reoccuringCharge?: string;
    isProrata: boolean;
    grossCharge?: string;
    discountedActivationPrice?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        options.id + '' :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       (config.viewModel === true) ? null : undefined;
    this.currency =
      (options !== undefined && options !== null && options.currency !== undefined && options.currency !== null) ?
        options.currency + '' :
       null;
    this.vat =
      (options !== undefined && options !== null && options.vat !== undefined && options.vat !== null) ?
        Number(options.vat) :
       null;
    this.oneTimeCharge =
      (options !== undefined && options !== null && options.oneTimeCharge !== undefined && options.oneTimeCharge !== null) ?
        options.oneTimeCharge + '' :
       (config.viewModel === true) ? null : undefined;
    this.reoccuringCharge =
      (options !== undefined && options !== null && options.reoccuringCharge !== undefined && options.reoccuringCharge !== null) ?
        options.reoccuringCharge + '' :
       (config.viewModel === true) ? null : undefined;
    this.isProrata = (options !== undefined && options !== null && options.isProrata !== undefined && options.isProrata !== null) ?
        !!options.isProrata :
       null;
    this.grossCharge =
      (options !== undefined && options !== null && options.grossCharge !== undefined && options.grossCharge !== null) ?
        options.grossCharge + '' :
       (config.viewModel === true) ? null : undefined;
    this.discountedActivationPrice =
      (options !== undefined && options !== null && options.discountedActivationPrice !== undefined && options.discountedActivationPrice !== null) ?
        options.discountedActivationPrice + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IPropertyTypeDto
 */
export interface IPropertyTypeDto {
  /**
   * Name of the property element.
   * - minLength: 1
   * - maxLength: 255
   */
  name: string;
  /**
   * Value of the property element.
   * - minLength: 0
   * - maxLength: 4096
   */
  value: string;
}


/**
 * PropertyTypeDto
 *  see IPropertyTypeDto
 */
export class PropertyTypeDto {
  /**
   * Name of the property element.
   * - minLength: 1
   * - maxLength: 255
   */
  readonly name: string;
  /**
   * Value of the property element.
   * - minLength: 0
   * - maxLength: 4096
   */
  readonly value: string;

  constructor(options: {
    name: string;
    value: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
  }
}


/**
 * IPropertiesTypeDto
 */
export interface IPropertiesTypeDto {
  /**
   * - minItems: 1
   */
  property?: IPropertyTypeDto[];
}


/**
 * PropertiesTypeDto
 *  see IPropertiesTypeDto
 */
export class PropertiesTypeDto {
  /**
   * - minItems: 1
   */
  readonly property?: PropertyTypeDto[];

  constructor(options: {
    property?: PropertyTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.property = new Array<PropertyTypeDto>();
    if (options !== undefined && options !== null && options.property !== undefined && options.property !== null) {
      for (const item of options.property) {
        this.property.push(new PropertyTypeDto(item, config));
      }
    }
  }
}


/**
 * IPeriodTypeDto
 */
export interface IPeriodTypeDto {
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  description: string;
  /**
   * Enum, one of:
   * - HOUR
   * - SECOND
   * - DAY
   * - WEEK
   * - MONTH
   * - YEAR
   * - EXACTMONTH
   */
  unit: string;
  /**
   * What is the ending point? Timestamp means еxact period from initialization
   * Enum, one of:
   * - TIMESTAMP
   * - MONTH
   * - QUARTER
   */
  anchor?: string;
  /**
   * Value in units
   */
  value: string;
  /**
   * Used in case of unit = EXACTMONTH|MONTH to set activationDay.
   * Possible value are PRORATA and MONTH
   * Enum, one of:
   * - PRORATA
   * - MONTH
   */
  startPeriod?: string;
}


/**
 * PeriodTypeDto
 *  see IPeriodTypeDto
 */
export class PeriodTypeDto {
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description: string;
  /**
   * Enum, one of:
   * - HOUR
   * - SECOND
   * - DAY
   * - WEEK
   * - MONTH
   * - YEAR
   * - EXACTMONTH
   */
  readonly unit: string;
  /**
   * What is the ending point? Timestamp means еxact period from initialization
   * Enum, one of:
   * - TIMESTAMP
   * - MONTH
   * - QUARTER
   */
  readonly anchor?: string;
  /**
   * Value in units
   */
  readonly value: string;
  /**
   * Used in case of unit = EXACTMONTH|MONTH to set activationDay.
   * Possible value are PRORATA and MONTH
   * Enum, one of:
   * - PRORATA
   * - MONTH
   */
  readonly startPeriod?: string;

  constructor(options: {
    description: string;
    unit: string;
    anchor?: string;
    value: string;
    startPeriod?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       null;
    this.unit =
      (options !== undefined && options !== null && options.unit !== undefined && options.unit !== null) ?
        options.unit + '' :
       null;
    this.anchor =
      (options !== undefined && options !== null && options.anchor !== undefined && options.anchor !== null) ?
        options.anchor + '' :
       (config.viewModel === true) ? null : undefined;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
    this.startPeriod =
      (options !== undefined && options !== null && options.startPeriod !== undefined && options.startPeriod !== null) ?
        options.startPeriod + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto
 */
export interface ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto {
  /**
   * Sequential numbering of usage counters starting with 1
   */
  counterNumber: number;
  /**
   * Enum, one of:
   * - QUANTITY
   * - KBYTE
   * - MBYTE
   * - MINUTE
   * - MONEY
   */
  usageUnit: string;
  /**
   * max value for this counter.
   * If unlimited this value is -1
   */
  usageLimit: number;
  /**
   * Name or type of the counter to be displayed
   * - minLength: 1
   * - maxLength: 80
   */
  usageType: string;
  /**
   * The most recent usage value for this counter from IN system.
   * 	When no value can be retrieved from network (e.g.
   * due to technical problems), this element will be omitted.
   * The 			usageValue does NOT include the proRataValue, only the real 		usage value.
   */
  usageValue?: number;
  /**
   * Optional.
   * Only present, if prorata usage has been applied by IN.
   * Contains the usable part of the option in this month.
   */
  proRataValue?: number;
}


/**
 * CompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto
 *  see ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto
 */
export class CompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto {
  /**
   * Sequential numbering of usage counters starting with 1
   */
  readonly counterNumber: number;
  /**
   * Enum, one of:
   * - QUANTITY
   * - KBYTE
   * - MBYTE
   * - MINUTE
   * - MONEY
   */
  readonly usageUnit: string;
  /**
   * max value for this counter.
   * If unlimited this value is -1
   */
  readonly usageLimit: number;
  /**
   * Name or type of the counter to be displayed
   * - minLength: 1
   * - maxLength: 80
   */
  readonly usageType: string;
  /**
   * The most recent usage value for this counter from IN system.
   * 	When no value can be retrieved from network (e.g.
   * due to technical problems), this element will be omitted.
   * The 			usageValue does NOT include the proRataValue, only the real 		usage value.
   */
  readonly usageValue?: number;
  /**
   * Optional.
   * Only present, if prorata usage has been applied by IN.
   * Contains the usable part of the option in this month.
   */
  readonly proRataValue?: number;

  constructor(options: {
    counterNumber: number;
    usageUnit: string;
    usageLimit: number;
    usageType: string;
    usageValue?: number;
    proRataValue?: number;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.counterNumber =
      (options !== undefined && options !== null && options.counterNumber !== undefined && options.counterNumber !== null) ?
        Number(options.counterNumber) :
       null;
    this.usageUnit =
      (options !== undefined && options !== null && options.usageUnit !== undefined && options.usageUnit !== null) ?
        options.usageUnit + '' :
       null;
    this.usageLimit =
      (options !== undefined && options !== null && options.usageLimit !== undefined && options.usageLimit !== null) ?
        Number(options.usageLimit) :
       null;
    this.usageType =
      (options !== undefined && options !== null && options.usageType !== undefined && options.usageType !== null) ?
        options.usageType + '' :
       null;
    this.usageValue =
      (options !== undefined && options !== null && options.usageValue !== undefined && options.usageValue !== null) ?
        Number(options.usageValue) :
       (config.viewModel === true) ? null : undefined;
    this.proRataValue =
      (options !== undefined && options !== null && options.proRataValue !== undefined && options.proRataValue !== null) ?
        Number(options.proRataValue) :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto
 */
export interface ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto {
  UsageCounter: ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto;
}


/**
 * CompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto
 *  see ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto
 */
export class CompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto {
  readonly UsageCounter: CompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto;

  constructor(options: {
    UsageCounter: CompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.UsageCounter =
      (options !== undefined && options !== null && options.UsageCounter !== undefined && options.UsageCounter !== null) ?
        new CompactTariffOption3TypeUsageInfoNumberOfUsageCounterUsageCounterUsageCounterAnonymDto(options.UsageCounter, config) :
        null;
  }
}


/**
 * ICompactTariffOption3TypeUsageInfoUsageInfoAnonymDto
 */
export interface ICompactTariffOption3TypeUsageInfoUsageInfoAnonymDto {
  numberOfUsageCounter: ICompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto;
}


/**
 * CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto
 *  see ICompactTariffOption3TypeUsageInfoUsageInfoAnonymDto
 */
export class CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto {
  readonly numberOfUsageCounter: CompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto;

  constructor(options: {
    numberOfUsageCounter: CompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.numberOfUsageCounter =
      (options !== undefined && options !== null && options.numberOfUsageCounter !== undefined && options.numberOfUsageCounter !== null) ?
        new CompactTariffOption3TypeUsageInfoNumberOfUsageCounterNumberOfUsageCounterAnonymDto(options.numberOfUsageCounter, config) :
        null;
  }
}


/**
 * ICompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto
 */
export interface ICompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto {
  validFrom: string;
  validUntil?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  value: string;
  /**
   * Enum, one of:
   * - PENDING_ACTIVATION
   * - ACTIVE
   * - PENDING_TERMINATION
   */
  status: string;
}


/**
 * CompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto
 *  see ICompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto
 */
export class CompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto {
  readonly validFrom: string;
  readonly validUntil?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly value: string;
  /**
   * Enum, one of:
   * - PENDING_ACTIVATION
   * - ACTIVE
   * - PENDING_TERMINATION
   */
  readonly status: string;

  constructor(options: {
    validFrom: string;
    validUntil?: string;
    value: string;
    status: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.validFrom =
      (options !== undefined && options !== null && options.validFrom !== undefined && options.validFrom !== null) ?
        options.validFrom + '' :
       null;
    this.validUntil =
      (options !== undefined && options !== null && options.validUntil !== undefined && options.validUntil !== null) ?
        options.validUntil + '' :
       (config.viewModel === true) ? null : undefined;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       null;
  }
}


/**
 * ICompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto
 */
export interface ICompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto {
  /**
   * unique mvneco ID
   */
  id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 45
   */
  code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  dic_tooltip?: string;
  /**
   * start of validity
   */
  valid_from: string;
  /**
   * end of validity, usually open end
   */
  valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  sort: number;
  /**
   * This goup is mandatory for tariff
   */
  isMandatory: boolean;
  /**
   * Enum, one of:
   * - BOOL
   * - STRING
   * - INT
   * - DECIMAL
   * - DATE
   */
  dataType: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  pattern?: string;
  instance: ICompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto;
}


/**
 * CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto
 *  see ICompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto
 */
export class CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto {
  /**
   * unique mvneco ID
   */
  readonly id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 45
   */
  readonly code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  readonly dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dic_tooltip?: string;
  /**
   * start of validity
   */
  readonly valid_from: string;
  /**
   * end of validity, usually open end
   */
  readonly valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  readonly sort: number;
  /**
   * This goup is mandatory for tariff
   */
  readonly isMandatory: boolean;
  /**
   * Enum, one of:
   * - BOOL
   * - STRING
   * - INT
   * - DECIMAL
   * - DATE
   */
  readonly dataType: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly pattern?: string;
  readonly instance: CompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    description?: string;
    dic_name?: string;
    dic_tooltip?: string;
    valid_from: string;
    valid_until?: string;
    sort: number;
    isMandatory: boolean;
    dataType: string;
    pattern?: string;
    instance: CompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_name =
      (options !== undefined && options !== null && options.dic_name !== undefined && options.dic_name !== null) ?
        options.dic_name + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_tooltip =
      (options !== undefined && options !== null && options.dic_tooltip !== undefined && options.dic_tooltip !== null) ?
        options.dic_tooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.valid_from =
      (options !== undefined && options !== null && options.valid_from !== undefined && options.valid_from !== null) ?
        options.valid_from + '' :
       null;
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.isMandatory = (options !== undefined && options !== null && options.isMandatory !== undefined && options.isMandatory !== null) ?
        !!options.isMandatory :
       null;
    this.dataType =
      (options !== undefined && options !== null && options.dataType !== undefined && options.dataType !== null) ?
        options.dataType + '' :
       null;
    this.pattern =
      (options !== undefined && options !== null && options.pattern !== undefined && options.pattern !== null) ?
        options.pattern + '' :
       (config.viewModel === true) ? null : undefined;
    this.instance =
      (options !== undefined && options !== null && options.instance !== undefined && options.instance !== null) ?
        new CompactTariffOption3TypeOptionDetailsOptionDetailInstanceInstanceAnonymDto(options.instance, config) :
        null;
  }
}


/**
 * ICompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto
 */
export interface ICompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto {
  optionDetail?: ICompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto;
}


/**
 * CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto
 *  see ICompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto
 */
export class CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto {
  readonly optionDetail?: CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto;

  constructor(options: {
    optionDetail?: CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.optionDetail =
      (options !== undefined && options !== null && options.optionDetail !== undefined && options.optionDetail !== null) ?
        new CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto(options.optionDetail, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypeOptionDetailsOptionDetailOptionDetailMergedDto( null, config) : undefined );
  }
}


/**
 * ICompactTariffOption3TypeDto
 */
export interface ICompactTariffOption3TypeDto {
  id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  groupCode: string;
  sort: number;
  /**
   * Enum, one of:
   * - UNASSIGNED
   * - PENDING_ACTIVATION
   * - ACTIVE
   * - PENDING_TERMINATION
   * - SCHEDULED_FOR_REMOVAL
   * - PAUSED
   */
  status: string;
  possibleActions?: ICompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto;
  alternateOptions?: ICompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto;
  schedAlternateOptions?: ICompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto;
  /**
   * Enum, one of:
   * - INITIAL
   * - ANY_TIME
   * - REBOOKABLE
   */
  bookingType: string;
  /**
   * Enum, one of:
   * - UNLIMITED
   * - FIXED_DURATION
   * - REPETITION
   * - CONSUMPTION
   */
  contractType: string;
  price?: IItemPriceTypeDto;
  insufficientFunds?: boolean;
  properties?: IPropertiesTypeDto;
  contractPeriod?: IPeriodTypeDto;
  cancellationPeriod?: IPeriodTypeDto;
  pausedTimeout?: IPeriodTypeDto;
  minimumDuration?: IPeriodTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  validFrom?: string;
  pausedFrom?: string;
  validUntil?: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  dicName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  dicTooltip?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  promotionText?: string;
  /**
   * promotional discount in percent
   */
  promotionRelativeDiscount?: number;
  /**
   * promotional credit, in EURO incl.
   * tax
   */
  promotionCredit?: number;
  usageInfo?: ICompactTariffOption3TypeUsageInfoUsageInfoAnonymDto;
  optionDetails?: ICompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto;
}


/**
 * CompactTariffOption3TypeDto
 *  see ICompactTariffOption3TypeDto
 */
export class CompactTariffOption3TypeDto {
  readonly id: number;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly code: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly groupCode: string;
  readonly sort: number;
  /**
   * Enum, one of:
   * - UNASSIGNED
   * - PENDING_ACTIVATION
   * - ACTIVE
   * - PENDING_TERMINATION
   * - SCHEDULED_FOR_REMOVAL
   * - PAUSED
   */
  readonly status: string;
  readonly possibleActions?: CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto;
  readonly alternateOptions?: CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto;
  readonly schedAlternateOptions?: CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto;
  /**
   * Enum, one of:
   * - INITIAL
   * - ANY_TIME
   * - REBOOKABLE
   */
  readonly bookingType: string;
  /**
   * Enum, one of:
   * - UNLIMITED
   * - FIXED_DURATION
   * - REPETITION
   * - CONSUMPTION
   */
  readonly contractType: string;
  readonly price?: ItemPriceTypeDto;
  readonly insufficientFunds?: boolean;
  readonly properties?: PropertiesTypeDto;
  readonly contractPeriod?: PeriodTypeDto;
  readonly cancellationPeriod?: PeriodTypeDto;
  readonly pausedTimeout?: PeriodTypeDto;
  readonly minimumDuration?: PeriodTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  readonly validFrom?: string;
  readonly pausedFrom?: string;
  readonly validUntil?: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly dicName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dicTooltip?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly promotionText?: string;
  /**
   * promotional discount in percent
   */
  readonly promotionRelativeDiscount?: number;
  /**
   * promotional credit, in EURO incl.
   * tax
   */
  readonly promotionCredit?: number;
  readonly usageInfo?: CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto;
  readonly optionDetails?: CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    groupCode: string;
    sort: number;
    status: string;
    possibleActions?: CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto;
    alternateOptions?: CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto;
    schedAlternateOptions?: CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto;
    bookingType: string;
    contractType: string;
    price?: ItemPriceTypeDto;
    insufficientFunds?: boolean;
    properties?: PropertiesTypeDto;
    contractPeriod?: PeriodTypeDto;
    cancellationPeriod?: PeriodTypeDto;
    pausedTimeout?: PeriodTypeDto;
    minimumDuration?: PeriodTypeDto;
    description?: string;
    validFrom?: string;
    pausedFrom?: string;
    validUntil?: string;
    dicName?: string;
    dicTooltip?: string;
    promotionText?: string;
    promotionRelativeDiscount?: number;
    promotionCredit?: number;
    usageInfo?: CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto;
    optionDetails?: CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.groupCode =
      (options !== undefined && options !== null && options.groupCode !== undefined && options.groupCode !== null) ?
        options.groupCode + '' :
       null;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       null;
    this.possibleActions =
      (options !== undefined && options !== null && options.possibleActions !== undefined && options.possibleActions !== null) ?
        new CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto(options.possibleActions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypePossibleActionsPossibleActionsAnonymDto( null, config) : undefined );
    this.alternateOptions =
      (options !== undefined && options !== null && options.alternateOptions !== undefined && options.alternateOptions !== null) ?
        new CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto(options.alternateOptions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypeAlternateOptionsAlternateOptionsAnonymDto( null, config) : undefined );
    this.schedAlternateOptions =
      (options !== undefined && options !== null && options.schedAlternateOptions !== undefined && options.schedAlternateOptions !== null) ?
        new CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto(options.schedAlternateOptions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypeSchedAlternateOptionsSchedAlternateOptionsAnonymDto( null, config) : undefined );
    this.bookingType =
      (options !== undefined && options !== null && options.bookingType !== undefined && options.bookingType !== null) ?
        options.bookingType + '' :
       null;
    this.contractType =
      (options !== undefined && options !== null && options.contractType !== undefined && options.contractType !== null) ?
        options.contractType + '' :
       null;
    this.price =
      (options !== undefined && options !== null && options.price !== undefined && options.price !== null) ?
        new ItemPriceTypeDto(options.price, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ItemPriceTypeDto( null, config) : null ) : (config.hierarchy === true ? new ItemPriceTypeDto( null, config) : undefined );
    this.insufficientFunds = (options !== undefined && options !== null && options.insufficientFunds !== undefined && options.insufficientFunds !== null) ?
        !!options.insufficientFunds :
       (config.viewModel === true) ? null : undefined;
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        new PropertiesTypeDto(options.properties, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
    this.contractPeriod =
      (options !== undefined && options !== null && options.contractPeriod !== undefined && options.contractPeriod !== null) ?
        new PeriodTypeDto(options.contractPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.cancellationPeriod =
      (options !== undefined && options !== null && options.cancellationPeriod !== undefined && options.cancellationPeriod !== null) ?
        new PeriodTypeDto(options.cancellationPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.pausedTimeout =
      (options !== undefined && options !== null && options.pausedTimeout !== undefined && options.pausedTimeout !== null) ?
        new PeriodTypeDto(options.pausedTimeout, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.minimumDuration =
      (options !== undefined && options !== null && options.minimumDuration !== undefined && options.minimumDuration !== null) ?
        new PeriodTypeDto(options.minimumDuration, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.validFrom =
      (options !== undefined && options !== null && options.validFrom !== undefined && options.validFrom !== null) ?
        options.validFrom + '' :
       (config.viewModel === true) ? null : undefined;
    this.pausedFrom =
      (options !== undefined && options !== null && options.pausedFrom !== undefined && options.pausedFrom !== null) ?
        options.pausedFrom + '' :
       (config.viewModel === true) ? null : undefined;
    this.validUntil =
      (options !== undefined && options !== null && options.validUntil !== undefined && options.validUntil !== null) ?
        options.validUntil + '' :
       (config.viewModel === true) ? null : undefined;
    this.dicName =
      (options !== undefined && options !== null && options.dicName !== undefined && options.dicName !== null) ?
        options.dicName + '' :
       (config.viewModel === true) ? null : undefined;
    this.dicTooltip =
      (options !== undefined && options !== null && options.dicTooltip !== undefined && options.dicTooltip !== null) ?
        options.dicTooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.promotionText =
      (options !== undefined && options !== null && options.promotionText !== undefined && options.promotionText !== null) ?
        options.promotionText + '' :
       (config.viewModel === true) ? null : undefined;
    this.promotionRelativeDiscount =
      (options !== undefined && options !== null && options.promotionRelativeDiscount !== undefined && options.promotionRelativeDiscount !== null) ?
        Number(options.promotionRelativeDiscount) :
       (config.viewModel === true) ? null : undefined;
    this.promotionCredit =
      (options !== undefined && options !== null && options.promotionCredit !== undefined && options.promotionCredit !== null) ?
        Number(options.promotionCredit) :
       (config.viewModel === true) ? null : undefined;
    this.usageInfo =
      (options !== undefined && options !== null && options.usageInfo !== undefined && options.usageInfo !== null) ?
        new CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto(options.usageInfo, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypeUsageInfoUsageInfoAnonymDto( null, config) : undefined );
    this.optionDetails =
      (options !== undefined && options !== null && options.optionDetails !== undefined && options.optionDetails !== null) ?
        new CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto(options.optionDetails, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CompactTariffOption3TypeOptionDetailsOptionDetailsAnonymDto( null, config) : undefined );
  }
}


/**
 * IResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto
 */
export interface IResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto {
  /**
   * - minItems: 0
   */
  option?: ICompactTariffOption3TypeDto[];
}


/**
 * ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto
 *  see IResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto
 */
export class ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto {
  /**
   * - minItems: 0
   */
  readonly option?: CompactTariffOption3TypeDto[];

  constructor(options: {
    option?: CompactTariffOption3TypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.option = new Array<CompactTariffOption3TypeDto>();
    if (options !== undefined && options !== null && options.option !== undefined && options.option !== null) {
      for (const item of options.option) {
        this.option.push(new CompactTariffOption3TypeDto(item, config));
      }
    }
  }
}


/**
 * IResourceGetSim2ResponseResourceResourceAnonymDto
 */
export interface IResourceGetSim2ResponseResourceResourceAnonymDto {
  /**
   * As provided in request
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * If MSISDN is assigned to SIM (State=PAIRED)
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Creation of ressources
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  created: string;
  /**
   * States of a ssn
   * Enum, one of:
   * - UNDEFINED
   * - PRE_INITIAL
   * - INITIAL
   * - PAIRED
   * - IN_USE
   * - TERMINATED
   */
  status: string;
  /**
   * Indicates if and when the SIM had been reset last time.
   */
  statusReset?: string;
  /**
   * Enum, one of:
   * - STD
   * - MINI
   * - MICRO
   * - NANO
   */
  cardType: string;
  /**
   * Type for internal Ids (surrogates)
   */
  tenant?: string;
  /**
   * Internal id for product assigned to SIM
   */
  productId?: string;
  /**
   * Tariff or brand name
   * - minLength: 0
   * - maxLength: 80
   */
  productRef?: string;
  /**
   * Is this a CC-Voucher
   */
  contentCard?: boolean;
  /**
   * Initial balance in Euro incl.
   * VAT.
   * Value in hundreds of cents
   */
  initialBalance?: string;
  productoptions?: IResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto;
  /**
   * Date after activation is not possible
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  valid_until?: string;
  /**
   * Type for internal Ids (surrogates)
   */
  tariffId?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  tariffName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  tariffCode?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  tariffDescription?: string;
  /**
   * HTML-Text to be diplayed in FE for this bundle
   */
  infoText?: string;
  /**
   * HTML-Text to be diplayed in FE for this bundle
   */
  promotionText?: string;
  /**
   * Comma separated list of valid optioncodes for promotion
   * - minLength: 0
   * - maxLength: 255
   */
  promotionOptions?: string;
}


/**
 * ResourceGetSim2ResponseResourceResourceAnonymDto
 *  see IResourceGetSim2ResponseResourceResourceAnonymDto
 */
export class ResourceGetSim2ResponseResourceResourceAnonymDto {
  /**
   * As provided in request
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * If MSISDN is assigned to SIM (State=PAIRED)
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Creation of ressources
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly created: string;
  /**
   * States of a ssn
   * Enum, one of:
   * - UNDEFINED
   * - PRE_INITIAL
   * - INITIAL
   * - PAIRED
   * - IN_USE
   * - TERMINATED
   */
  readonly status: string;
  /**
   * Indicates if and when the SIM had been reset last time.
   */
  readonly statusReset?: string;
  /**
   * Enum, one of:
   * - STD
   * - MINI
   * - MICRO
   * - NANO
   */
  readonly cardType: string;
  /**
   * Type for internal Ids (surrogates)
   */
  readonly tenant?: string;
  /**
   * Internal id for product assigned to SIM
   */
  readonly productId?: string;
  /**
   * Tariff or brand name
   * - minLength: 0
   * - maxLength: 80
   */
  readonly productRef?: string;
  /**
   * Is this a CC-Voucher
   */
  readonly contentCard?: boolean;
  /**
   * Initial balance in Euro incl.
   * VAT.
   * Value in hundreds of cents
   */
  readonly initialBalance?: string;
  readonly productoptions?: ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto;
  /**
   * Date after activation is not possible
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly valid_until?: string;
  /**
   * Type for internal Ids (surrogates)
   */
  readonly tariffId?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  readonly tariffName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  readonly tariffCode?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly tariffDescription?: string;
  /**
   * HTML-Text to be diplayed in FE for this bundle
   */
  readonly infoText?: string;
  /**
   * HTML-Text to be diplayed in FE for this bundle
   */
  readonly promotionText?: string;
  /**
   * Comma separated list of valid optioncodes for promotion
   * - minLength: 0
   * - maxLength: 255
   */
  readonly promotionOptions?: string;

  constructor(options: {
    sim: string;
    msisdn?: string;
    created: string;
    status: string;
    statusReset?: string;
    cardType: string;
    tenant?: string;
    productId?: string;
    productRef?: string;
    contentCard?: boolean;
    initialBalance?: string;
    productoptions?: ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto;
    valid_until?: string;
    tariffId?: string;
    tariffName?: string;
    tariffCode?: string;
    tariffDescription?: string;
    infoText?: string;
    promotionText?: string;
    promotionOptions?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.created =
      (options !== undefined && options !== null && options.created !== undefined && options.created !== null) ?
        options.created + '' :
       null;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       null;
    this.statusReset =
      (options !== undefined && options !== null && options.statusReset !== undefined && options.statusReset !== null) ?
        options.statusReset + '' :
       (config.viewModel === true) ? null : undefined;
    this.cardType =
      (options !== undefined && options !== null && options.cardType !== undefined && options.cardType !== null) ?
        options.cardType + '' :
       null;
    this.tenant =
      (options !== undefined && options !== null && options.tenant !== undefined && options.tenant !== null) ?
        options.tenant + '' :
       (config.viewModel === true) ? null : undefined;
    this.productId =
      (options !== undefined && options !== null && options.productId !== undefined && options.productId !== null) ?
        options.productId + '' :
       (config.viewModel === true) ? null : undefined;
    this.productRef =
      (options !== undefined && options !== null && options.productRef !== undefined && options.productRef !== null) ?
        options.productRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.contentCard = (options !== undefined && options !== null && options.contentCard !== undefined && options.contentCard !== null) ?
        !!options.contentCard :
       (config.viewModel === true) ? null : undefined;
    this.initialBalance =
      (options !== undefined && options !== null && options.initialBalance !== undefined && options.initialBalance !== null) ?
        options.initialBalance + '' :
       (config.viewModel === true) ? null : undefined;
    this.productoptions =
      (options !== undefined && options !== null && options.productoptions !== undefined && options.productoptions !== null) ?
        new ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto(options.productoptions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseResourceProductoptionsProductoptionsAnonymDto( null, config) : undefined );
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariffId =
      (options !== undefined && options !== null && options.tariffId !== undefined && options.tariffId !== null) ?
        options.tariffId + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariffName =
      (options !== undefined && options !== null && options.tariffName !== undefined && options.tariffName !== null) ?
        options.tariffName + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariffCode =
      (options !== undefined && options !== null && options.tariffCode !== undefined && options.tariffCode !== null) ?
        options.tariffCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariffDescription =
      (options !== undefined && options !== null && options.tariffDescription !== undefined && options.tariffDescription !== null) ?
        options.tariffDescription + '' :
       (config.viewModel === true) ? null : undefined;
    this.infoText =
      (options !== undefined && options !== null && options.infoText !== undefined && options.infoText !== null) ?
        options.infoText + '' :
       (config.viewModel === true) ? null : undefined;
    this.promotionText =
      (options !== undefined && options !== null && options.promotionText !== undefined && options.promotionText !== null) ?
        options.promotionText + '' :
       (config.viewModel === true) ? null : undefined;
    this.promotionOptions =
      (options !== undefined && options !== null && options.promotionOptions !== undefined && options.promotionOptions !== null) ?
        options.promotionOptions + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IPrivateCustomerTypeDto
 */
export interface IPrivateCustomerTypeDto {
  /**
   * Optional title allows to provision title for natural person, such as Dr., Prof.
   * ...
   * - minLength: 2
   * - maxLength: 20
   */
  title?: string;
  /**
   * Optional salutation helps to identify customer type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - UNATTRIBUTED
   * - OTHER
   */
  salutation: string;
  /**
   * First name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  firstName: string;
  /**
   * Last name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  lastName: string;
  /**
   * Date of birth of customer person or founding date for companies.
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  dateOfBirth?: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  street: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 6
   */
  streetNumber?: string;
  /**
   * Zip code of customer residence.
   * - minLength: 4
   * - maxLength: 6
   */
  zipCode?: string;
  /**
   * Eir code of customer residence.
   * - minLength: 8
   * - maxLength: 8
   */
  eirCode?: string;
  /**
   * City of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  city: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 80
   */
  additionalAddressInfo?: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 255
   */
  additionalAddressInfo1?: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 255
   */
  additionalAddressInfo2?: string;
  /**
   * Longitude in ETRS89
   */
  addressLongitude?: number;
  /**
   * Latitude in ETRS89
   */
  addressLatitude?: number;
  /**
   * Post office box of the customer
   * - minLength: 0
   * - maxLength: 45
   */
  postOfficeBox?: string;
}


/**
 * PrivateCustomerTypeDto
 *  see IPrivateCustomerTypeDto
 */
export class PrivateCustomerTypeDto {
  /**
   * Optional title allows to provision title for natural person, such as Dr., Prof.
   * ...
   * - minLength: 2
   * - maxLength: 20
   */
  readonly title?: string;
  /**
   * Optional salutation helps to identify customer type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - UNATTRIBUTED
   * - OTHER
   */
  readonly salutation: string;
  /**
   * First name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly firstName: string;
  /**
   * Last name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly lastName: string;
  /**
   * Date of birth of customer person or founding date for companies.
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly dateOfBirth?: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly street: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly streetNumber?: string;
  /**
   * Zip code of customer residence.
   * - minLength: 4
   * - maxLength: 6
   */
  readonly zipCode?: string;
  /**
   * Eir code of customer residence.
   * - minLength: 8
   * - maxLength: 8
   */
  readonly eirCode?: string;
  /**
   * City of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 80
   */
  readonly additionalAddressInfo?: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 255
   */
  readonly additionalAddressInfo1?: string;
  /**
   * Optional customer additional address information.
   * - minLength: 0
   * - maxLength: 255
   */
  readonly additionalAddressInfo2?: string;
  /**
   * Longitude in ETRS89
   */
  readonly addressLongitude?: number;
  /**
   * Latitude in ETRS89
   */
  readonly addressLatitude?: number;
  /**
   * Post office box of the customer
   * - minLength: 0
   * - maxLength: 45
   */
  readonly postOfficeBox?: string;

  constructor(options: {
    title?: string;
    salutation: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: string;
    street: string;
    streetNumber?: string;
    zipCode?: string;
    eirCode?: string;
    city: string;
    additionalAddressInfo?: string;
    additionalAddressInfo1?: string;
    additionalAddressInfo2?: string;
    addressLongitude?: number;
    addressLatitude?: number;
    postOfficeBox?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.title =
      (options !== undefined && options !== null && options.title !== undefined && options.title !== null) ?
        options.title + '' :
       (config.viewModel === true) ? null : undefined;
    this.salutation =
      (options !== undefined && options !== null && options.salutation !== undefined && options.salutation !== null) ?
        options.salutation + '' :
       null;
    this.firstName =
      (options !== undefined && options !== null && options.firstName !== undefined && options.firstName !== null) ?
        options.firstName + '' :
       null;
    this.lastName =
      (options !== undefined && options !== null && options.lastName !== undefined && options.lastName !== null) ?
        options.lastName + '' :
       null;
    this.dateOfBirth =
      (options !== undefined && options !== null && options.dateOfBirth !== undefined && options.dateOfBirth !== null) ?
        options.dateOfBirth + '' :
       (config.viewModel === true) ? null : undefined;
    this.street =
      (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        options.street + '' :
       null;
    this.streetNumber =
      (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        options.streetNumber + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.eirCode =
      (options !== undefined && options !== null && options.eirCode !== undefined && options.eirCode !== null) ?
        options.eirCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       null;
    this.additionalAddressInfo =
      (options !== undefined && options !== null && options.additionalAddressInfo !== undefined && options.additionalAddressInfo !== null) ?
        options.additionalAddressInfo + '' :
       (config.viewModel === true) ? null : undefined;
    this.additionalAddressInfo1 =
      (options !== undefined && options !== null && options.additionalAddressInfo1 !== undefined && options.additionalAddressInfo1 !== null) ?
        options.additionalAddressInfo1 + '' :
       (config.viewModel === true) ? null : undefined;
    this.additionalAddressInfo2 =
      (options !== undefined && options !== null && options.additionalAddressInfo2 !== undefined && options.additionalAddressInfo2 !== null) ?
        options.additionalAddressInfo2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.addressLongitude =
      (options !== undefined && options !== null && options.addressLongitude !== undefined && options.addressLongitude !== null) ?
        Number(options.addressLongitude) :
       (config.viewModel === true) ? null : undefined;
    this.addressLatitude =
      (options !== undefined && options !== null && options.addressLatitude !== undefined && options.addressLatitude !== null) ?
        Number(options.addressLatitude) :
       (config.viewModel === true) ? null : undefined;
    this.postOfficeBox =
      (options !== undefined && options !== null && options.postOfficeBox !== undefined && options.postOfficeBox !== null) ?
        options.postOfficeBox + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IOptionTypeOptionDetailOptionDetailAnonymDto
 */
export interface IOptionTypeOptionDetailOptionDetailAnonymDto {
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  optionDetailId: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  value: string;
  /**
   * Only for internal use!
   * - minLength: 0
   * - maxLength: 255
   */
  previousValue?: string;
}


/**
 * OptionTypeOptionDetailOptionDetailAnonymDto
 *  see IOptionTypeOptionDetailOptionDetailAnonymDto
 */
export class OptionTypeOptionDetailOptionDetailAnonymDto {
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly optionDetailId: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly value: string;
  /**
   * Only for internal use!
   * - minLength: 0
   * - maxLength: 255
   */
  readonly previousValue?: string;

  constructor(options: {
    optionDetailId: string;
    value: string;
    previousValue?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.optionDetailId =
      (options !== undefined && options !== null && options.optionDetailId !== undefined && options.optionDetailId !== null) ?
        options.optionDetailId + '' :
       null;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
    this.previousValue =
      (options !== undefined && options !== null && options.previousValue !== undefined && options.previousValue !== null) ?
        options.previousValue + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IOptionTypeDto
 */
export interface IOptionTypeDto {
  /**
   * Enum, one of:
   * - EXIST
   * - AUTO
   * - ADD
   * - REMOVE
   * - DELETE
   * - UPDATE
   * - ALTERNATE
   * - GET
   * - SET
   * - CANCEL
   * - SCHEDALTERNATE
   */
  action?: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  optionGroupCode: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  optionCode: string;
  /**
   * - minItems: 0
   */
  optionDetail?: IOptionTypeOptionDetailOptionDetailAnonymDto[];
  validFrom?: string;
  validUntil?: string;
}


/**
 * OptionTypeDto
 *  see IOptionTypeDto
 */
export class OptionTypeDto {
  /**
   * Enum, one of:
   * - EXIST
   * - AUTO
   * - ADD
   * - REMOVE
   * - DELETE
   * - UPDATE
   * - ALTERNATE
   * - GET
   * - SET
   * - CANCEL
   * - SCHEDALTERNATE
   */
  readonly action?: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly optionGroupCode: string;
  /**
   * Type for string elements used in particular subscription related types.
   * - maxLength: 45
   */
  readonly optionCode: string;
  /**
   * - minItems: 0
   */
  readonly optionDetail?: OptionTypeOptionDetailOptionDetailAnonymDto[];
  readonly validFrom?: string;
  readonly validUntil?: string;

  constructor(options: {
    action?: string;
    optionGroupCode: string;
    optionCode: string;
    optionDetail?: OptionTypeOptionDetailOptionDetailAnonymDto[];
    validFrom?: string;
    validUntil?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.action =
      (options !== undefined && options !== null && options.action !== undefined && options.action !== null) ?
        options.action + '' :
       (config.viewModel === true) ? null : undefined;
    this.optionGroupCode =
      (options !== undefined && options !== null && options.optionGroupCode !== undefined && options.optionGroupCode !== null) ?
        options.optionGroupCode + '' :
       null;
    this.optionCode =
      (options !== undefined && options !== null && options.optionCode !== undefined && options.optionCode !== null) ?
        options.optionCode + '' :
       null;
    this.optionDetail = new Array<OptionTypeOptionDetailOptionDetailAnonymDto>();
    if (options !== undefined && options !== null && options.optionDetail !== undefined && options.optionDetail !== null) {
      for (const item of options.optionDetail) {
        this.optionDetail.push(new OptionTypeOptionDetailOptionDetailAnonymDto(item, config));
      }
    }
    this.validFrom =
      (options !== undefined && options !== null && options.validFrom !== undefined && options.validFrom !== null) ?
        options.validFrom + '' :
       (config.viewModel === true) ? null : undefined;
    this.validUntil =
      (options !== undefined && options !== null && options.validUntil !== undefined && options.validUntil !== null) ?
        options.validUntil + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto
 */
export interface IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  option?: IOptionTypeDto[];
}


/**
 * ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto
 *  see IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto
 */
export class ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly option?: OptionTypeDto[];

  constructor(options: {
    option?: OptionTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.option = new Array<OptionTypeDto>();
    if (options !== undefined && options !== null && options.option !== undefined && options.option !== null) {
      for (const item of options.option) {
        this.option.push(new OptionTypeDto(item, config));
      }
    }
  }
}


/**
 * IResourceGetSim2ResponseCustomerCustomerMergedDto
 */
export interface IResourceGetSim2ResponseCustomerCustomerMergedDto {
  /**
   * The unique customer id.
   * In the database this is named the surrogate for the customer.
   */
  customerId?: number;
  /**
   * The unique tenant id the customer is assigned to.
   */
  tenant: number;
  privateCustomer: IPrivateCustomerTypeDto;
  /**
   * Country of customer residence.
   * Can be omitted for domestic customers.
   * - minLength: 1
   * - maxLength: 80
   */
  country?: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   * This can be use as alternative to country element, depends on client
   */
  countryCode?: string;
  /**
   * Optional contact phone number of customer.
   * - minLength: 1
   * - maxLength: 25
   */
  phone?: string;
  /**
   * Optional contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  email?: string;
  /**
   * The locale of the customer, e.g.
   * de for German language.
   */
  locale: string;
  /**
   * Describes the current customer life cycle state.
   * Enum, one of:
   * - INITIAL
   * - IN_USE
   * - FROZEN
   * - TERMINATED
   */
  state?: string;
  /**
   * Customer care password for customer.
   * - minLength: 0
   * - maxLength: 80
   */
  password?: string;
  /**
   * Flag indicating whether the customer has acknowledged general terms and conditions.
   */
  ackTermsAndConditions: boolean;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  ackTrafficData: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  ackMarketing: boolean;
  /**
   * Version of General Terms and Conditions accepted by the customer.
   * - minLength: 0
   * - maxLength: 80
   */
  versionTermsAndConditions?: string;
  /**
   * Point in time the customer has been created.
   * It will never change afterwards.
   */
  tsCreate?: string;
  /**
   * Point in time of last change of customer attributes such as firstName, lastName, properties etc, but not state changes.
   */
  tsLastChange?: string;
  /**
   * Point in time of last state change.
   */
  tsLastStateChange?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  customerValueKey?: string;
  properties?: IPropertiesTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  tariffCode: string;
  options?: IResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto;
}


/**
 * ResourceGetSim2ResponseCustomerCustomerMergedDto
 *  see IResourceGetSim2ResponseCustomerCustomerMergedDto
 */
export class ResourceGetSim2ResponseCustomerCustomerMergedDto {
  /**
   * The unique customer id.
   * In the database this is named the surrogate for the customer.
   */
  readonly customerId?: number;
  /**
   * The unique tenant id the customer is assigned to.
   */
  readonly tenant: number;
  readonly privateCustomer: PrivateCustomerTypeDto;
  /**
   * Country of customer residence.
   * Can be omitted for domestic customers.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly country?: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   * This can be use as alternative to country element, depends on client
   */
  readonly countryCode?: string;
  /**
   * Optional contact phone number of customer.
   * - minLength: 1
   * - maxLength: 25
   */
  readonly phone?: string;
  /**
   * Optional contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email?: string;
  /**
   * The locale of the customer, e.g.
   * de for German language.
   */
  readonly locale: string;
  /**
   * Describes the current customer life cycle state.
   * Enum, one of:
   * - INITIAL
   * - IN_USE
   * - FROZEN
   * - TERMINATED
   */
  readonly state?: string;
  /**
   * Customer care password for customer.
   * - minLength: 0
   * - maxLength: 80
   */
  readonly password?: string;
  /**
   * Flag indicating whether the customer has acknowledged general terms and conditions.
   */
  readonly ackTermsAndConditions: boolean;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  readonly ackTrafficData: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  readonly ackMarketing: boolean;
  /**
   * Version of General Terms and Conditions accepted by the customer.
   * - minLength: 0
   * - maxLength: 80
   */
  readonly versionTermsAndConditions?: string;
  /**
   * Point in time the customer has been created.
   * It will never change afterwards.
   */
  readonly tsCreate?: string;
  /**
   * Point in time of last change of customer attributes such as firstName, lastName, properties etc, but not state changes.
   */
  readonly tsLastChange?: string;
  /**
   * Point in time of last state change.
   */
  readonly tsLastStateChange?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  readonly customerValueKey?: string;
  readonly properties?: PropertiesTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  readonly tariffCode: string;
  readonly options?: ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto;

  constructor(options: {
    customerId?: number;
    tenant: number;
    privateCustomer: PrivateCustomerTypeDto;
    country?: string;
    countryCode?: string;
    phone?: string;
    email?: string;
    locale: string;
    state?: string;
    password?: string;
    ackTermsAndConditions: boolean;
    ackTrafficData: boolean;
    ackMarketing: boolean;
    versionTermsAndConditions?: string;
    tsCreate?: string;
    tsLastChange?: string;
    tsLastStateChange?: string;
    customerValueKey?: string;
    properties?: PropertiesTypeDto;
    tariffCode: string;
    options?: ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.customerId =
      (options !== undefined && options !== null && options.customerId !== undefined && options.customerId !== null) ?
        Number(options.customerId) :
       (config.viewModel === true) ? null : undefined;
    this.tenant =
      (options !== undefined && options !== null && options.tenant !== undefined && options.tenant !== null) ?
        Number(options.tenant) :
       null;
    this.privateCustomer =
      (options !== undefined && options !== null && options.privateCustomer !== undefined && options.privateCustomer !== null) ?
        new PrivateCustomerTypeDto(options.privateCustomer, config) :
        null;
    this.country =
      (options !== undefined && options !== null && options.country !== undefined && options.country !== null) ?
        options.country + '' :
       (config.viewModel === true) ? null : undefined;
    this.countryCode =
      (options !== undefined && options !== null && options.countryCode !== undefined && options.countryCode !== null) ?
        options.countryCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.phone =
      (options !== undefined && options !== null && options.phone !== undefined && options.phone !== null) ?
        options.phone + '' :
       (config.viewModel === true) ? null : undefined;
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       (config.viewModel === true) ? null : undefined;
    this.locale =
      (options !== undefined && options !== null && options.locale !== undefined && options.locale !== null) ?
        options.locale + '' :
       null;
    this.state =
      (options !== undefined && options !== null && options.state !== undefined && options.state !== null) ?
        options.state + '' :
       (config.viewModel === true) ? null : undefined;
    this.password =
      (options !== undefined && options !== null && options.password !== undefined && options.password !== null) ?
        options.password + '' :
       (config.viewModel === true) ? null : undefined;
    this.ackTermsAndConditions = (options !== undefined && options !== null && options.ackTermsAndConditions !== undefined && options.ackTermsAndConditions !== null) ?
        !!options.ackTermsAndConditions :
       null;
    this.ackTrafficData = (options !== undefined && options !== null && options.ackTrafficData !== undefined && options.ackTrafficData !== null) ?
        !!options.ackTrafficData :
       null;
    this.ackMarketing = (options !== undefined && options !== null && options.ackMarketing !== undefined && options.ackMarketing !== null) ?
        !!options.ackMarketing :
       null;
    this.versionTermsAndConditions =
      (options !== undefined && options !== null && options.versionTermsAndConditions !== undefined && options.versionTermsAndConditions !== null) ?
        options.versionTermsAndConditions + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsCreate =
      (options !== undefined && options !== null && options.tsCreate !== undefined && options.tsCreate !== null) ?
        options.tsCreate + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsLastChange =
      (options !== undefined && options !== null && options.tsLastChange !== undefined && options.tsLastChange !== null) ?
        options.tsLastChange + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsLastStateChange =
      (options !== undefined && options !== null && options.tsLastStateChange !== undefined && options.tsLastStateChange !== null) ?
        options.tsLastStateChange + '' :
       (config.viewModel === true) ? null : undefined;
    this.customerValueKey =
      (options !== undefined && options !== null && options.customerValueKey !== undefined && options.customerValueKey !== null) ?
        options.customerValueKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        new PropertiesTypeDto(options.properties, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
    this.tariffCode =
      (options !== undefined && options !== null && options.tariffCode !== undefined && options.tariffCode !== null) ?
        options.tariffCode + '' :
       null;
    this.options =
      (options !== undefined && options !== null && options.options !== undefined && options.options !== null) ?
        new ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto(options.options, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerOptionsOptionsAnonymDto( null, config) : undefined );
  }
}


/**
 * IUserModelPermissionDto
 */
export interface IUserModelPermissionDto {
  permission: string;
  value: boolean;
}


/**
 * UserModelPermissionDto
 *  see IUserModelPermissionDto
 */
export class UserModelPermissionDto {
  readonly permission: string;
  readonly value: boolean;

  constructor(options: {
    permission: string;
    value: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.permission =
      (options !== undefined && options !== null && options.permission !== undefined && options.permission !== null) ?
        options.permission + '' :
       null;
    this.value = (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        !!options.value :
       null;
  }
}


/**
 * IUserModelDto
 */
export interface IUserModelDto {
  sub: string;
  resource?: IResourceGetSim2ResponseResourceResourceAnonymDto;
  /**
   * status of identification process, only available if result/code=0
   * Enum, one of:
   * - UNDEFINED
   * - INITIAL
   * - REG
   * - READY_FOR_ACTIVATION
   * - IDENT_ORDERED
   * - COMPLETE
   * - TERMINATED
   */
  status?: string;
  /**
   * Status details collected from history information
   * - minLength: 0
   * - maxLength: 80
   */
  subStatus?: string;
  customer?: IResourceGetSim2ResponseCustomerCustomerMergedDto;
  perms: IUserModelPermissionDto[];
  roleName: string;
}


/**
 * UserModelDto
 *  see IUserModelDto
 */
export class UserModelDto {
  readonly sub: string;
  readonly resource?: ResourceGetSim2ResponseResourceResourceAnonymDto;
  /**
   * status of identification process, only available if result/code=0
   * Enum, one of:
   * - UNDEFINED
   * - INITIAL
   * - REG
   * - READY_FOR_ACTIVATION
   * - IDENT_ORDERED
   * - COMPLETE
   * - TERMINATED
   */
  readonly status?: string;
  /**
   * Status details collected from history information
   * - minLength: 0
   * - maxLength: 80
   */
  readonly subStatus?: string;
  readonly customer?: ResourceGetSim2ResponseCustomerCustomerMergedDto;
  readonly perms: UserModelPermissionDto[];
  readonly roleName: string;

  constructor(options: {
    sub: string;
    resource?: ResourceGetSim2ResponseResourceResourceAnonymDto;
    status?: string;
    subStatus?: string;
    customer?: ResourceGetSim2ResponseCustomerCustomerMergedDto;
    perms: UserModelPermissionDto[];
    roleName: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sub =
      (options !== undefined && options !== null && options.sub !== undefined && options.sub !== null) ?
        options.sub + '' :
       null;
    this.resource =
      (options !== undefined && options !== null && options.resource !== undefined && options.resource !== null) ?
        new ResourceGetSim2ResponseResourceResourceAnonymDto(options.resource, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseResourceResourceAnonymDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseResourceResourceAnonymDto( null, config) : undefined );
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       (config.viewModel === true) ? null : undefined;
    this.subStatus =
      (options !== undefined && options !== null && options.subStatus !== undefined && options.subStatus !== null) ?
        options.subStatus + '' :
       (config.viewModel === true) ? null : undefined;
    this.customer =
      (options !== undefined && options !== null && options.customer !== undefined && options.customer !== null) ?
        new ResourceGetSim2ResponseCustomerCustomerMergedDto(options.customer, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerCustomerMergedDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerCustomerMergedDto( null, config) : undefined );
    this.perms = new Array<UserModelPermissionDto>();
    if (options !== undefined && options !== null && options.perms !== undefined && options.perms !== null) {
      for (const item of options.perms) {
        this.perms.push(new UserModelPermissionDto(item, config));
      }
    }
    this.roleName =
      (options !== undefined && options !== null && options.roleName !== undefined && options.roleName !== null) ?
        options.roleName + '' :
       null;
  }
}


/**
 * ICatalogueGetTariffsRequestDto
 */
export interface ICatalogueGetTariffsRequestDto {
  sessionKey?: string;
  /**
   * Type for internal Ids (surrogates)
   */
  tenantId: string;
  includeHierarchy: boolean;
}


/**
 * CatalogueGetTariffsRequestDto
 *  see ICatalogueGetTariffsRequestDto
 */
export class CatalogueGetTariffsRequestDto {
  readonly sessionKey?: string;
  /**
   * Type for internal Ids (surrogates)
   */
  readonly tenantId: string;
  readonly includeHierarchy: boolean;

  constructor(options: {
    sessionKey?: string;
    tenantId: string;
    includeHierarchy: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.tenantId =
      (options !== undefined && options !== null && options.tenantId !== undefined && options.tenantId !== null) ?
        options.tenantId + '' :
       null;
    this.includeHierarchy = (options !== undefined && options !== null && options.includeHierarchy !== undefined && options.includeHierarchy !== null) ?
        !!options.includeHierarchy :
       null;
  }
}


/**
 * IErrorTypeDto
 */
export interface IErrorTypeDto {
  /**
   * Describes error doamin.
   * - minLength: 0
   * - maxLength: 40
   */
  domain: string;
  /**
   * Describes error reason in english language.
   * - minLength: 0
   * - maxLength: 255
   */
  text: string;
}


/**
 * ErrorTypeDto
 *  see IErrorTypeDto
 */
export class ErrorTypeDto {
  /**
   * Describes error doamin.
   * - minLength: 0
   * - maxLength: 40
   */
  readonly domain: string;
  /**
   * Describes error reason in english language.
   * - minLength: 0
   * - maxLength: 255
   */
  readonly text: string;

  constructor(options: {
    domain: string;
    text: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.domain =
      (options !== undefined && options !== null && options.domain !== undefined && options.domain !== null) ?
        options.domain + '' :
       null;
    this.text =
      (options !== undefined && options !== null && options.text !== undefined && options.text !== null) ?
        options.text + '' :
       null;
  }
}


/**
 * IDefaultReturnTypeDto
 */
export interface IDefaultReturnTypeDto {
  /**
   * Return code of a operation.
   * If the code == 0, the operation succeeded.
   * Otherwise the return code indicates a specific incident.
   */
  code: number;
  error?: IErrorTypeDto;
  /**
   * Optional transaction ID is returned as it has been provided on request.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
}


/**
 * DefaultReturnTypeDto
 *  see IDefaultReturnTypeDto
 */
export class DefaultReturnTypeDto {
  /**
   * Return code of a operation.
   * If the code == 0, the operation succeeded.
   * Otherwise the return code indicates a specific incident.
   */
  readonly code: number;
  readonly error?: ErrorTypeDto;
  /**
   * Optional transaction ID is returned as it has been provided on request.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;

  constructor(options: {
    code: number;
    error?: ErrorTypeDto;
    transactionRef?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        Number(options.code) :
       null;
    this.error =
      (options !== undefined && options !== null && options.error !== undefined && options.error !== null) ?
        new ErrorTypeDto(options.error, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ErrorTypeDto( null, config) : null ) : (config.hierarchy === true ? new ErrorTypeDto( null, config) : undefined );
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IOptionBaseTypeAllowedActionsAllowedActionsAnonymDto
 */
export interface IOptionBaseTypeAllowedActionsAllowedActionsAnonymDto {
  /**
   * - minItems: 1
   * - maxItems: 5
   */
  action?: string[];
}


/**
 * OptionBaseTypeAllowedActionsAllowedActionsAnonymDto
 *  see IOptionBaseTypeAllowedActionsAllowedActionsAnonymDto
 */
export class OptionBaseTypeAllowedActionsAllowedActionsAnonymDto {
  /**
   * - minItems: 1
   * - maxItems: 5
   */
  readonly action?: string[];

  constructor(options: {
    action?: string[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.action = new Array<string>();
    if (options !== undefined && options !== null && options.action !== undefined && options.action !== null) {
      for (const item of options.action) {
        this.action.push(item);
      }
    }
  }
}


/**
 * IOptionDetailTypeDto
 */
export interface IOptionDetailTypeDto {
  /**
   * unique mvneco ID
   */
  id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 45
   */
  code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  dic_tooltip?: string;
  /**
   * start of validity
   */
  valid_from: string;
  /**
   * end of validity, usually open end
   */
  valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  sort: number;
  /**
   * This goup is mandatory for tariff
   */
  isMandatory: boolean;
  /**
   * Enum, one of:
   * - BOOL
   * - STRING
   * - INT
   * - DECIMAL
   * - DATE
   */
  dataType: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  pattern?: string;
}


/**
 * OptionDetailTypeDto
 *  see IOptionDetailTypeDto
 */
export class OptionDetailTypeDto {
  /**
   * unique mvneco ID
   */
  readonly id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 45
   */
  readonly code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  readonly dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dic_tooltip?: string;
  /**
   * start of validity
   */
  readonly valid_from: string;
  /**
   * end of validity, usually open end
   */
  readonly valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  readonly sort: number;
  /**
   * This goup is mandatory for tariff
   */
  readonly isMandatory: boolean;
  /**
   * Enum, one of:
   * - BOOL
   * - STRING
   * - INT
   * - DECIMAL
   * - DATE
   */
  readonly dataType: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly pattern?: string;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    description?: string;
    dic_name?: string;
    dic_tooltip?: string;
    valid_from: string;
    valid_until?: string;
    sort: number;
    isMandatory: boolean;
    dataType: string;
    pattern?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_name =
      (options !== undefined && options !== null && options.dic_name !== undefined && options.dic_name !== null) ?
        options.dic_name + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_tooltip =
      (options !== undefined && options !== null && options.dic_tooltip !== undefined && options.dic_tooltip !== null) ?
        options.dic_tooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.valid_from =
      (options !== undefined && options !== null && options.valid_from !== undefined && options.valid_from !== null) ?
        options.valid_from + '' :
       null;
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.isMandatory = (options !== undefined && options !== null && options.isMandatory !== undefined && options.isMandatory !== null) ?
        !!options.isMandatory :
       null;
    this.dataType =
      (options !== undefined && options !== null && options.dataType !== undefined && options.dataType !== null) ?
        options.dataType + '' :
       null;
    this.pattern =
      (options !== undefined && options !== null && options.pattern !== undefined && options.pattern !== null) ?
        options.pattern + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IOptionCatalogueTypeTariffOptionDetailsAnonymDto
 */
export interface IOptionCatalogueTypeTariffOptionDetailsAnonymDto {
  /**
   * - minItems: 1
   */
  tariffOption_detail?: IOptionDetailTypeDto[];
}


/**
 * OptionCatalogueTypeTariffOptionDetailsAnonymDto
 *  see IOptionCatalogueTypeTariffOptionDetailsAnonymDto
 */
export class OptionCatalogueTypeTariffOptionDetailsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly tariffOption_detail?: OptionDetailTypeDto[];

  constructor(options: {
    tariffOption_detail?: OptionDetailTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tariffOption_detail = new Array<OptionDetailTypeDto>();
    if (options !== undefined && options !== null && options.tariffOption_detail !== undefined && options.tariffOption_detail !== null) {
      for (const item of options.tariffOption_detail) {
        this.tariffOption_detail.push(new OptionDetailTypeDto(item, config));
      }
    }
  }
}


/**
 * IOptionCatalogueTypeDto
 */
export interface IOptionCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  dic_tooltip?: string;
  /**
   * start of validity
   */
  valid_from: string;
  /**
   * end of validity, usually open end
   */
  valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  sort: number;
  /**
   * Enum, one of:
   * - ANY_TIME
   * - INITIAL
   * - REBOOKABLE
   * - NOT_CANCELLABLE
   * - HIDDEN
   */
  bookingType: string;
  /**
   * type of reneval/duration, values in the period elements
   * Enum, one of:
   * - UNLIMITED
   * - FIXED_DURATION
   * - CONSUMPTION
   * - REPETITION
   */
  contractType: string;
  allowedActions?: IOptionBaseTypeAllowedActionsAllowedActionsAnonymDto;
  properties?: IPropertiesTypeDto;
  price?: IItemPriceTypeDto;
  contractPeriod?: IPeriodTypeDto;
  cancellationPeriod?: IPeriodTypeDto;
  pausedTimeout?: IPeriodTypeDto;
  minimumDuration?: IPeriodTypeDto;
  tariffOption_details: IOptionCatalogueTypeTariffOptionDetailsAnonymDto;
}


/**
 * OptionCatalogueTypeDto
 *  see IOptionCatalogueTypeDto
 */
export class OptionCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  readonly id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  readonly code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  readonly dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dic_tooltip?: string;
  /**
   * start of validity
   */
  readonly valid_from: string;
  /**
   * end of validity, usually open end
   */
  readonly valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  readonly sort: number;
  /**
   * Enum, one of:
   * - ANY_TIME
   * - INITIAL
   * - REBOOKABLE
   * - NOT_CANCELLABLE
   * - HIDDEN
   */
  readonly bookingType: string;
  /**
   * type of reneval/duration, values in the period elements
   * Enum, one of:
   * - UNLIMITED
   * - FIXED_DURATION
   * - CONSUMPTION
   * - REPETITION
   */
  readonly contractType: string;
  readonly allowedActions?: OptionBaseTypeAllowedActionsAllowedActionsAnonymDto;
  readonly properties?: PropertiesTypeDto;
  readonly price?: ItemPriceTypeDto;
  readonly contractPeriod?: PeriodTypeDto;
  readonly cancellationPeriod?: PeriodTypeDto;
  readonly pausedTimeout?: PeriodTypeDto;
  readonly minimumDuration?: PeriodTypeDto;
  readonly tariffOption_details: OptionCatalogueTypeTariffOptionDetailsAnonymDto;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    description?: string;
    dic_name?: string;
    dic_tooltip?: string;
    valid_from: string;
    valid_until?: string;
    sort: number;
    bookingType: string;
    contractType: string;
    allowedActions?: OptionBaseTypeAllowedActionsAllowedActionsAnonymDto;
    properties?: PropertiesTypeDto;
    price?: ItemPriceTypeDto;
    contractPeriod?: PeriodTypeDto;
    cancellationPeriod?: PeriodTypeDto;
    pausedTimeout?: PeriodTypeDto;
    minimumDuration?: PeriodTypeDto;
    tariffOption_details: OptionCatalogueTypeTariffOptionDetailsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_name =
      (options !== undefined && options !== null && options.dic_name !== undefined && options.dic_name !== null) ?
        options.dic_name + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_tooltip =
      (options !== undefined && options !== null && options.dic_tooltip !== undefined && options.dic_tooltip !== null) ?
        options.dic_tooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.valid_from =
      (options !== undefined && options !== null && options.valid_from !== undefined && options.valid_from !== null) ?
        options.valid_from + '' :
       null;
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.bookingType =
      (options !== undefined && options !== null && options.bookingType !== undefined && options.bookingType !== null) ?
        options.bookingType + '' :
       null;
    this.contractType =
      (options !== undefined && options !== null && options.contractType !== undefined && options.contractType !== null) ?
        options.contractType + '' :
       null;
    this.allowedActions =
      (options !== undefined && options !== null && options.allowedActions !== undefined && options.allowedActions !== null) ?
        new OptionBaseTypeAllowedActionsAllowedActionsAnonymDto(options.allowedActions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new OptionBaseTypeAllowedActionsAllowedActionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new OptionBaseTypeAllowedActionsAllowedActionsAnonymDto( null, config) : undefined );
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        new PropertiesTypeDto(options.properties, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
    this.price =
      (options !== undefined && options !== null && options.price !== undefined && options.price !== null) ?
        new ItemPriceTypeDto(options.price, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ItemPriceTypeDto( null, config) : null ) : (config.hierarchy === true ? new ItemPriceTypeDto( null, config) : undefined );
    this.contractPeriod =
      (options !== undefined && options !== null && options.contractPeriod !== undefined && options.contractPeriod !== null) ?
        new PeriodTypeDto(options.contractPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.cancellationPeriod =
      (options !== undefined && options !== null && options.cancellationPeriod !== undefined && options.cancellationPeriod !== null) ?
        new PeriodTypeDto(options.cancellationPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.pausedTimeout =
      (options !== undefined && options !== null && options.pausedTimeout !== undefined && options.pausedTimeout !== null) ?
        new PeriodTypeDto(options.pausedTimeout, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.minimumDuration =
      (options !== undefined && options !== null && options.minimumDuration !== undefined && options.minimumDuration !== null) ?
        new PeriodTypeDto(options.minimumDuration, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.tariffOption_details =
      (options !== undefined && options !== null && options.tariffOption_details !== undefined && options.tariffOption_details !== null) ?
        new OptionCatalogueTypeTariffOptionDetailsAnonymDto(options.tariffOption_details, config) :
        null;
  }
}


/**
 * IOptionGroupCatalogueTypeTariffOptionsAnonymDto
 */
export interface IOptionGroupCatalogueTypeTariffOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  tariffOption?: IOptionCatalogueTypeDto[];
}


/**
 * OptionGroupCatalogueTypeTariffOptionsAnonymDto
 *  see IOptionGroupCatalogueTypeTariffOptionsAnonymDto
 */
export class OptionGroupCatalogueTypeTariffOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly tariffOption?: OptionCatalogueTypeDto[];

  constructor(options: {
    tariffOption?: OptionCatalogueTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tariffOption = new Array<OptionCatalogueTypeDto>();
    if (options !== undefined && options !== null && options.tariffOption !== undefined && options.tariffOption !== null) {
      for (const item of options.tariffOption) {
        this.tariffOption.push(new OptionCatalogueTypeDto(item, config));
      }
    }
  }
}


/**
 * IOptionGroupCatalogueTypeDto
 */
export interface IOptionGroupCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  dic_tooltip?: string;
  /**
   * start of validity
   */
  valid_from: string;
  /**
   * end of validity, usually open end
   */
  valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  sort: number;
  /**
   * Should not be shown to user or customer, only for m2m communication
   */
  isVisible: boolean;
  /**
   * This goup is mandatory for tariff
   */
  isMandatory: boolean;
  /**
   * This group is mandatory for activation
   */
  isDefault: boolean;
  /**
   * The options w/in this group behave as ...
   * Enum, one of:
   * - XOR
   * - OR
   * - AND
   */
  items: string;
  /**
   * This group will require another group.
   * This group will only referenced by ID, regardless of hierarchy flag
   */
  requiredOptionGroup?: string;
  tariffOptions: IOptionGroupCatalogueTypeTariffOptionsAnonymDto;
}


/**
 * OptionGroupCatalogueTypeDto
 *  see IOptionGroupCatalogueTypeDto
 */
export class OptionGroupCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  readonly id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  readonly code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  readonly dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dic_tooltip?: string;
  /**
   * start of validity
   */
  readonly valid_from: string;
  /**
   * end of validity, usually open end
   */
  readonly valid_until?: string;
  /**
   * To display in sorted order.
   * Lowest value will always be the default value
   */
  readonly sort: number;
  /**
   * Should not be shown to user or customer, only for m2m communication
   */
  readonly isVisible: boolean;
  /**
   * This goup is mandatory for tariff
   */
  readonly isMandatory: boolean;
  /**
   * This group is mandatory for activation
   */
  readonly isDefault: boolean;
  /**
   * The options w/in this group behave as ...
   * Enum, one of:
   * - XOR
   * - OR
   * - AND
   */
  readonly items: string;
  /**
   * This group will require another group.
   * This group will only referenced by ID, regardless of hierarchy flag
   */
  readonly requiredOptionGroup?: string;
  readonly tariffOptions: OptionGroupCatalogueTypeTariffOptionsAnonymDto;

  constructor(options: {
    id: number;
    name: string;
    code: string;
    description?: string;
    dic_name?: string;
    dic_tooltip?: string;
    valid_from: string;
    valid_until?: string;
    sort: number;
    isVisible: boolean;
    isMandatory: boolean;
    isDefault: boolean;
    items: string;
    requiredOptionGroup?: string;
    tariffOptions: OptionGroupCatalogueTypeTariffOptionsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_name =
      (options !== undefined && options !== null && options.dic_name !== undefined && options.dic_name !== null) ?
        options.dic_name + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_tooltip =
      (options !== undefined && options !== null && options.dic_tooltip !== undefined && options.dic_tooltip !== null) ?
        options.dic_tooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.valid_from =
      (options !== undefined && options !== null && options.valid_from !== undefined && options.valid_from !== null) ?
        options.valid_from + '' :
       null;
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.isVisible = (options !== undefined && options !== null && options.isVisible !== undefined && options.isVisible !== null) ?
        !!options.isVisible :
       null;
    this.isMandatory = (options !== undefined && options !== null && options.isMandatory !== undefined && options.isMandatory !== null) ?
        !!options.isMandatory :
       null;
    this.isDefault = (options !== undefined && options !== null && options.isDefault !== undefined && options.isDefault !== null) ?
        !!options.isDefault :
       null;
    this.items =
      (options !== undefined && options !== null && options.items !== undefined && options.items !== null) ?
        options.items + '' :
       null;
    this.requiredOptionGroup =
      (options !== undefined && options !== null && options.requiredOptionGroup !== undefined && options.requiredOptionGroup !== null) ?
        options.requiredOptionGroup + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariffOptions =
      (options !== undefined && options !== null && options.tariffOptions !== undefined && options.tariffOptions !== null) ?
        new OptionGroupCatalogueTypeTariffOptionsAnonymDto(options.tariffOptions, config) :
        null;
  }
}


/**
 * ITariffCatalogueTypeTariffOptionGroupsAnonymDto
 */
export interface ITariffCatalogueTypeTariffOptionGroupsAnonymDto {
  /**
   * - minItems: 1
   */
  tariffOptionGroup?: IOptionGroupCatalogueTypeDto[];
}


/**
 * TariffCatalogueTypeTariffOptionGroupsAnonymDto
 *  see ITariffCatalogueTypeTariffOptionGroupsAnonymDto
 */
export class TariffCatalogueTypeTariffOptionGroupsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly tariffOptionGroup?: OptionGroupCatalogueTypeDto[];

  constructor(options: {
    tariffOptionGroup?: OptionGroupCatalogueTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tariffOptionGroup = new Array<OptionGroupCatalogueTypeDto>();
    if (options !== undefined && options !== null && options.tariffOptionGroup !== undefined && options.tariffOptionGroup !== null) {
      for (const item of options.tariffOptionGroup) {
        this.tariffOptionGroup.push(new OptionGroupCatalogueTypeDto(item, config));
      }
    }
  }
}


/**
 * ITariffCatalogueTypeDto
 */
export interface ITariffCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * Optional.
   * It shows if the tariff is pre- or postpaid.
   * Enum, one of:
   * - PREPAID
   * - POSTPAID
   */
  prePostPaidFlag?: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  dic_tooltip?: string;
  /**
   * start of validity
   */
  valid_from: string;
  /**
   * end of validity, usually open end
   */
  valid_until?: string;
  contractPeriod?: IPeriodTypeDto;
  cancellationPeriod?: IPeriodTypeDto;
  prolongationPeriod?: IPeriodTypeDto;
  tariffOptionGroups: ITariffCatalogueTypeTariffOptionGroupsAnonymDto;
}


/**
 * TariffCatalogueTypeDto
 *  see ITariffCatalogueTypeDto
 */
export class TariffCatalogueTypeDto {
  /**
   * unique mvneco ID
   */
  readonly id: number;
  /**
   * Name to be displayed, not unique over different tenants
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Optional.
   * It shows if the tariff is pre- or postpaid.
   * Enum, one of:
   * - PREPAID
   * - POSTPAID
   */
  readonly prePostPaidFlag?: string;
  /**
   * unique reference
   * - minLength: 0
   * - maxLength: 16
   */
  readonly code: string;
  /**
   * description, e.g.
   * to show it in a tooltip
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * if client has set locale, name in foreign locale
   * - minLength: 0
   * - maxLength: 45
   */
  readonly dic_name?: string;
  /**
   * if client has set locale, tooltip in foreign locale
   * - minLength: 0
   * - maxLength: 255
   */
  readonly dic_tooltip?: string;
  /**
   * start of validity
   */
  readonly valid_from: string;
  /**
   * end of validity, usually open end
   */
  readonly valid_until?: string;
  readonly contractPeriod?: PeriodTypeDto;
  readonly cancellationPeriod?: PeriodTypeDto;
  readonly prolongationPeriod?: PeriodTypeDto;
  readonly tariffOptionGroups: TariffCatalogueTypeTariffOptionGroupsAnonymDto;

  constructor(options: {
    id: number;
    name: string;
    prePostPaidFlag?: string;
    code: string;
    description?: string;
    dic_name?: string;
    dic_tooltip?: string;
    valid_from: string;
    valid_until?: string;
    contractPeriod?: PeriodTypeDto;
    cancellationPeriod?: PeriodTypeDto;
    prolongationPeriod?: PeriodTypeDto;
    tariffOptionGroups: TariffCatalogueTypeTariffOptionGroupsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.prePostPaidFlag =
      (options !== undefined && options !== null && options.prePostPaidFlag !== undefined && options.prePostPaidFlag !== null) ?
        options.prePostPaidFlag + '' :
       (config.viewModel === true) ? null : undefined;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_name =
      (options !== undefined && options !== null && options.dic_name !== undefined && options.dic_name !== null) ?
        options.dic_name + '' :
       (config.viewModel === true) ? null : undefined;
    this.dic_tooltip =
      (options !== undefined && options !== null && options.dic_tooltip !== undefined && options.dic_tooltip !== null) ?
        options.dic_tooltip + '' :
       (config.viewModel === true) ? null : undefined;
    this.valid_from =
      (options !== undefined && options !== null && options.valid_from !== undefined && options.valid_from !== null) ?
        options.valid_from + '' :
       null;
    this.valid_until =
      (options !== undefined && options !== null && options.valid_until !== undefined && options.valid_until !== null) ?
        options.valid_until + '' :
       (config.viewModel === true) ? null : undefined;
    this.contractPeriod =
      (options !== undefined && options !== null && options.contractPeriod !== undefined && options.contractPeriod !== null) ?
        new PeriodTypeDto(options.contractPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.cancellationPeriod =
      (options !== undefined && options !== null && options.cancellationPeriod !== undefined && options.cancellationPeriod !== null) ?
        new PeriodTypeDto(options.cancellationPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.prolongationPeriod =
      (options !== undefined && options !== null && options.prolongationPeriod !== undefined && options.prolongationPeriod !== null) ?
        new PeriodTypeDto(options.prolongationPeriod, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.tariffOptionGroups =
      (options !== undefined && options !== null && options.tariffOptionGroups !== undefined && options.tariffOptionGroups !== null) ?
        new TariffCatalogueTypeTariffOptionGroupsAnonymDto(options.tariffOptionGroups, config) :
        null;
  }
}


/**
 * ICatalogueGetTariffsResponseTariffsTariffsAnonymDto
 */
export interface ICatalogueGetTariffsResponseTariffsTariffsAnonymDto {
  /**
   * - minItems: 1
   */
  tariff?: ITariffCatalogueTypeDto[];
}


/**
 * CatalogueGetTariffsResponseTariffsTariffsAnonymDto
 *  see ICatalogueGetTariffsResponseTariffsTariffsAnonymDto
 */
export class CatalogueGetTariffsResponseTariffsTariffsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly tariff?: TariffCatalogueTypeDto[];

  constructor(options: {
    tariff?: TariffCatalogueTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tariff = new Array<TariffCatalogueTypeDto>();
    if (options !== undefined && options !== null && options.tariff !== undefined && options.tariff !== null) {
      for (const item of options.tariff) {
        this.tariff.push(new TariffCatalogueTypeDto(item, config));
      }
    }
  }
}


/**
 * ICatalogueGetTariffsResponseDto
 */
export interface ICatalogueGetTariffsResponseDto {
  result: IDefaultReturnTypeDto;
  tariffs?: ICatalogueGetTariffsResponseTariffsTariffsAnonymDto;
}


/**
 * CatalogueGetTariffsResponseDto
 *  see ICatalogueGetTariffsResponseDto
 */
export class CatalogueGetTariffsResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly tariffs?: CatalogueGetTariffsResponseTariffsTariffsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    tariffs?: CatalogueGetTariffsResponseTariffsTariffsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.tariffs =
      (options !== undefined && options !== null && options.tariffs !== undefined && options.tariffs !== null) ?
        new CatalogueGetTariffsResponseTariffsTariffsAnonymDto(options.tariffs, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CatalogueGetTariffsResponseTariffsTariffsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CatalogueGetTariffsResponseTariffsTariffsAnonymDto( null, config) : undefined );
  }
}


/**
 * ICustomerRegistration2RequestProcessRefProcessRefAnonymDto
 */
export interface ICustomerRegistration2RequestProcessRefProcessRefAnonymDto {
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  puk2?: string;
}


/**
 * CustomerRegistration2RequestProcessRefProcessRefAnonymDto
 *  see ICustomerRegistration2RequestProcessRefProcessRefAnonymDto
 */
export class CustomerRegistration2RequestProcessRefProcessRefAnonymDto {
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2?: string;

  constructor(options: {
    sim: string;
    msisdn?: string;
    puk2?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICustomerRegistration2RequestCustomerCustomerMergedDto
 */
export interface ICustomerRegistration2RequestCustomerCustomerMergedDto {
  /**
   * Optional title allows to provision title for natural person, such as Dr., Prof.
   * ...
   * - minLength: 2
   * - maxLength: 20
   */
  title?: string;
  /**
   * Optional salutation helps to identify customer type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - UNATTRIBUTED
   * - OTHER
   */
  salutation?: string;
  /**
   * First name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  firstName: string;
  /**
   * Last name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  lastName: string;
  /**
   * Date of birth of customer person or founding date for companies.
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  dateOfBirth: string;
  /**
   * Contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  email?: string;
  /**
   * Optional contact phone number of customer.
   * - minLength: 1
   * - maxLength: 25
   */
  phone?: string;
  /**
   * The locale of the customer, e.g.
   * de for German language.
   */
  locale: string;
  /**
   * Flag indicating whether the customer has acknowledged general terms and conditions.
   */
  ackTermsAndConditions: boolean;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  ackTrafficData?: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  ackMarketing: boolean;
  properties?: IPropertiesTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  password?: string;
}


/**
 * CustomerRegistration2RequestCustomerCustomerMergedDto
 *  see ICustomerRegistration2RequestCustomerCustomerMergedDto
 */
export class CustomerRegistration2RequestCustomerCustomerMergedDto {
  /**
   * Optional title allows to provision title for natural person, such as Dr., Prof.
   * ...
   * - minLength: 2
   * - maxLength: 20
   */
  readonly title?: string;
  /**
   * Optional salutation helps to identify customer type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - UNATTRIBUTED
   * - OTHER
   */
  readonly salutation?: string;
  /**
   * First name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly firstName: string;
  /**
   * Last name of customer person.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly lastName: string;
  /**
   * Date of birth of customer person or founding date for companies.
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly dateOfBirth: string;
  /**
   * Contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email?: string;
  /**
   * Optional contact phone number of customer.
   * - minLength: 1
   * - maxLength: 25
   */
  readonly phone?: string;
  /**
   * The locale of the customer, e.g.
   * de for German language.
   */
  readonly locale: string;
  /**
   * Flag indicating whether the customer has acknowledged general terms and conditions.
   */
  readonly ackTermsAndConditions: boolean;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  readonly ackTrafficData?: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  readonly ackMarketing: boolean;
  readonly properties?: PropertiesTypeDto;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly password?: string;

  constructor(options: {
    title?: string;
    salutation?: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email?: string;
    phone?: string;
    locale: string;
    ackTermsAndConditions: boolean;
    ackTrafficData?: boolean;
    ackMarketing: boolean;
    properties?: PropertiesTypeDto;
    password?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.title =
      (options !== undefined && options !== null && options.title !== undefined && options.title !== null) ?
        options.title + '' :
       (config.viewModel === true) ? null : undefined;
    this.salutation =
      (options !== undefined && options !== null && options.salutation !== undefined && options.salutation !== null) ?
        options.salutation + '' :
       (config.viewModel === true) ? null : undefined;
    this.firstName =
      (options !== undefined && options !== null && options.firstName !== undefined && options.firstName !== null) ?
        options.firstName + '' :
       null;
    this.lastName =
      (options !== undefined && options !== null && options.lastName !== undefined && options.lastName !== null) ?
        options.lastName + '' :
       null;
    this.dateOfBirth =
      (options !== undefined && options !== null && options.dateOfBirth !== undefined && options.dateOfBirth !== null) ?
        options.dateOfBirth + '' :
       null;
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       (config.viewModel === true) ? null : undefined;
    this.phone =
      (options !== undefined && options !== null && options.phone !== undefined && options.phone !== null) ?
        options.phone + '' :
       (config.viewModel === true) ? null : undefined;
    this.locale =
      (options !== undefined && options !== null && options.locale !== undefined && options.locale !== null) ?
        options.locale + '' :
       null;
    this.ackTermsAndConditions = (options !== undefined && options !== null && options.ackTermsAndConditions !== undefined && options.ackTermsAndConditions !== null) ?
        !!options.ackTermsAndConditions :
       null;
    this.ackTrafficData = (options !== undefined && options !== null && options.ackTrafficData !== undefined && options.ackTrafficData !== null) ?
        !!options.ackTrafficData :
       (config.viewModel === true) ? null : undefined;
    this.ackMarketing = (options !== undefined && options !== null && options.ackMarketing !== undefined && options.ackMarketing !== null) ?
        !!options.ackMarketing :
       null;
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        new PropertiesTypeDto(options.properties, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
    this.password =
      (options !== undefined && options !== null && options.password !== undefined && options.password !== null) ?
        options.password + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdDocumentAddressTypeDto
 */
export interface IIdDocumentAddressTypeDto {
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  street?: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 6
   */
  streetNumber?: string;
  /**
   * Zip code of customer residence.
   * - minLength: 3
   * - maxLength: 6
   */
  zipCode?: string;
  /**
   * City of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  city?: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   */
  countryCode?: string;
  /**
   * City of birth, not used
   * - minLength: 0
   * - maxLength: 255
   */
  birthPlace?: string;
  /**
   * Name of birth, if different to current lastName
   * - minLength: 1
   * - maxLength: 80
   */
  birthName?: string;
  /**
   * City of birth info
   * - minLength: 0
   * - maxLength: 255
   */
  birthPlaceNoPlaceInfo?: string;
  /**
   * nationality
   * - minLength: 0
   * - maxLength: 255
   */
  nationality?: string;
  /**
   * placeOfResidenceFreeTextPlace
   * - minLength: 0
   * - maxLength: 255
   */
  placeOfResidenceFreeTextPlace?: string;
  /**
   * residencePermitI
   * - minLength: 0
   * - maxLength: 255
   */
  residencePermitI?: string;
}


/**
 * IdDocumentAddressTypeDto
 *  see IIdDocumentAddressTypeDto
 */
export class IdDocumentAddressTypeDto {
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly street?: string;
  /**
   * Street of customer residence.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly streetNumber?: string;
  /**
   * Zip code of customer residence.
   * - minLength: 3
   * - maxLength: 6
   */
  readonly zipCode?: string;
  /**
   * City of customer residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city?: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   */
  readonly countryCode?: string;
  /**
   * City of birth, not used
   * - minLength: 0
   * - maxLength: 255
   */
  readonly birthPlace?: string;
  /**
   * Name of birth, if different to current lastName
   * - minLength: 1
   * - maxLength: 80
   */
  readonly birthName?: string;
  /**
   * City of birth info
   * - minLength: 0
   * - maxLength: 255
   */
  readonly birthPlaceNoPlaceInfo?: string;
  /**
   * nationality
   * - minLength: 0
   * - maxLength: 255
   */
  readonly nationality?: string;
  /**
   * placeOfResidenceFreeTextPlace
   * - minLength: 0
   * - maxLength: 255
   */
  readonly placeOfResidenceFreeTextPlace?: string;
  /**
   * residencePermitI
   * - minLength: 0
   * - maxLength: 255
   */
  readonly residencePermitI?: string;

  constructor(options: {
    street?: string;
    streetNumber?: string;
    zipCode?: string;
    city?: string;
    countryCode?: string;
    birthPlace?: string;
    birthName?: string;
    birthPlaceNoPlaceInfo?: string;
    nationality?: string;
    placeOfResidenceFreeTextPlace?: string;
    residencePermitI?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.street =
      (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        options.street + '' :
       (config.viewModel === true) ? null : undefined;
    this.streetNumber =
      (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        options.streetNumber + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       (config.viewModel === true) ? null : undefined;
    this.countryCode =
      (options !== undefined && options !== null && options.countryCode !== undefined && options.countryCode !== null) ?
        options.countryCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.birthPlace =
      (options !== undefined && options !== null && options.birthPlace !== undefined && options.birthPlace !== null) ?
        options.birthPlace + '' :
       (config.viewModel === true) ? null : undefined;
    this.birthName =
      (options !== undefined && options !== null && options.birthName !== undefined && options.birthName !== null) ?
        options.birthName + '' :
       (config.viewModel === true) ? null : undefined;
    this.birthPlaceNoPlaceInfo =
      (options !== undefined && options !== null && options.birthPlaceNoPlaceInfo !== undefined && options.birthPlaceNoPlaceInfo !== null) ?
        options.birthPlaceNoPlaceInfo + '' :
       (config.viewModel === true) ? null : undefined;
    this.nationality =
      (options !== undefined && options !== null && options.nationality !== undefined && options.nationality !== null) ?
        options.nationality + '' :
       (config.viewModel === true) ? null : undefined;
    this.placeOfResidenceFreeTextPlace =
      (options !== undefined && options !== null && options.placeOfResidenceFreeTextPlace !== undefined && options.placeOfResidenceFreeTextPlace !== null) ?
        options.placeOfResidenceFreeTextPlace + '' :
       (config.viewModel === true) ? null : undefined;
    this.residencePermitI =
      (options !== undefined && options !== null && options.residencePermitI !== undefined && options.residencePermitI !== null) ?
        options.residencePermitI + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IContactAddressTypeDto
 */
export interface IContactAddressTypeDto {
  /**
   * Street of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 80
   */
  street: string;
  /**
   * Street of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 6
   */
  streetNumber?: string;
  /**
   * Zip code of customer residence in Germany.
   * - minLength: 4
   * - maxLength: 6
   */
  zipCode: string;
  /**
   * City of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 80
   */
  city: string;
}


/**
 * ContactAddressTypeDto
 *  see IContactAddressTypeDto
 */
export class ContactAddressTypeDto {
  /**
   * Street of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly street: string;
  /**
   * Street of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly streetNumber?: string;
  /**
   * Zip code of customer residence in Germany.
   * - minLength: 4
   * - maxLength: 6
   */
  readonly zipCode: string;
  /**
   * City of customer residence in Germany.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city: string;

  constructor(options: {
    street: string;
    streetNumber?: string;
    zipCode: string;
    city: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.street =
      (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        options.street + '' :
       null;
    this.streetNumber =
      (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        options.streetNumber + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       null;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       null;
  }
}


/**
 * IIdDocumentTypeDto
 */
export interface IIdDocumentTypeDto {
  /**
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  documentType: string;
  /**
   * For Passport this number includes the check digit
   * - maxLength: 45
   */
  documentNumber?: string;
  eidRestrictedIdentifier?: string;
  /**
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  dateOfIssue?: string;
  /**
   * This is mandatory for final provisioing of ident data, but not to indicate type of document during registration
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  dateOfExpiry: string;
  /**
   * ISO 3166-1 as alpha-2 or alpha-3 (must be agreed with partner)
   */
  countryOfIssue: string;
  /**
   * City of competent authority
   * - maxLength: 200
   */
  locationOfIssue?: string;
  /**
   * The organisation that issued the ID document
   * - maxLength: 200
   */
  issuingAuthority?: string;
}


/**
 * IdDocumentTypeDto
 *  see IIdDocumentTypeDto
 */
export class IdDocumentTypeDto {
  /**
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  readonly documentType: string;
  /**
   * For Passport this number includes the check digit
   * - maxLength: 45
   */
  readonly documentNumber?: string;
  readonly eidRestrictedIdentifier?: string;
  /**
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly dateOfIssue?: string;
  /**
   * This is mandatory for final provisioing of ident data, but not to indicate type of document during registration
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly dateOfExpiry: string;
  /**
   * ISO 3166-1 as alpha-2 or alpha-3 (must be agreed with partner)
   */
  readonly countryOfIssue: string;
  /**
   * City of competent authority
   * - maxLength: 200
   */
  readonly locationOfIssue?: string;
  /**
   * The organisation that issued the ID document
   * - maxLength: 200
   */
  readonly issuingAuthority?: string;

  constructor(options: {
    documentType: string;
    documentNumber?: string;
    eidRestrictedIdentifier?: string;
    dateOfIssue?: string;
    dateOfExpiry: string;
    countryOfIssue: string;
    locationOfIssue?: string;
    issuingAuthority?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       null;
    this.documentNumber =
      (options !== undefined && options !== null && options.documentNumber !== undefined && options.documentNumber !== null) ?
        options.documentNumber + '' :
       (config.viewModel === true) ? null : undefined;
    this.eidRestrictedIdentifier =
      (options !== undefined && options !== null && options.eidRestrictedIdentifier !== undefined && options.eidRestrictedIdentifier !== null) ?
        options.eidRestrictedIdentifier + '' :
       (config.viewModel === true) ? null : undefined;
    this.dateOfIssue =
      (options !== undefined && options !== null && options.dateOfIssue !== undefined && options.dateOfIssue !== null) ?
        options.dateOfIssue + '' :
       (config.viewModel === true) ? null : undefined;
    this.dateOfExpiry =
      (options !== undefined && options !== null && options.dateOfExpiry !== undefined && options.dateOfExpiry !== null) ?
        options.dateOfExpiry + '' :
       null;
    this.countryOfIssue =
      (options !== undefined && options !== null && options.countryOfIssue !== undefined && options.countryOfIssue !== null) ?
        options.countryOfIssue + '' :
       null;
    this.locationOfIssue =
      (options !== undefined && options !== null && options.locationOfIssue !== undefined && options.locationOfIssue !== null) ?
        options.locationOfIssue + '' :
       (config.viewModel === true) ? null : undefined;
    this.issuingAuthority =
      (options !== undefined && options !== null && options.issuingAuthority !== undefined && options.issuingAuthority !== null) ?
        options.issuingAuthority + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto
 */
export interface ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto {
  /**
   * Enum, one of:
   * - CHANNEL
   * - DATATYPE
   * - PRODUCTTYPE
   * - PURPOSETYPE
   */
  featureType: string;
  /**
   * business wise some combinations of type and feature are not allowed
   * Enum, one of:
   * - SMS
   * - SEN
   * - EMAIL
   * - PHONE
   * - LETTER
   * - MOBILENUMBER
   * - EMAIL_ADDRESS
   * - USER_DATA
   * - DEDICATED_USER_DATA
   * - TRAFFIC_DATA
   * - CONTACT_DATA
   * - ALL_PRODUCTS
   * - SIMILAR_PRODUCTS
   * - PARTNER_PRODUCTS
   * - DESIGN_PRODUCTS
   * - OFFER
   * - DIRECT_MARKETING
   * - MARKET_RESEARCH
   * - USAGE_PROFILE
   * - DESIGN_SERVICES
   * - INDIVIDUAL_OFFER
   */
  feature: string;
}


/**
 * CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto
 *  see ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto
 */
export class CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto {
  /**
   * Enum, one of:
   * - CHANNEL
   * - DATATYPE
   * - PRODUCTTYPE
   * - PURPOSETYPE
   */
  readonly featureType: string;
  /**
   * business wise some combinations of type and feature are not allowed
   * Enum, one of:
   * - SMS
   * - SEN
   * - EMAIL
   * - PHONE
   * - LETTER
   * - MOBILENUMBER
   * - EMAIL_ADDRESS
   * - USER_DATA
   * - DEDICATED_USER_DATA
   * - TRAFFIC_DATA
   * - CONTACT_DATA
   * - ALL_PRODUCTS
   * - SIMILAR_PRODUCTS
   * - PARTNER_PRODUCTS
   * - DESIGN_PRODUCTS
   * - OFFER
   * - DIRECT_MARKETING
   * - MARKET_RESEARCH
   * - USAGE_PROFILE
   * - DESIGN_SERVICES
   * - INDIVIDUAL_OFFER
   */
  readonly feature: string;

  constructor(options: {
    featureType: string;
    feature: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.featureType =
      (options !== undefined && options !== null && options.featureType !== undefined && options.featureType !== null) ?
        options.featureType + '' :
       null;
    this.feature =
      (options !== undefined && options !== null && options.feature !== undefined && options.feature !== null) ?
        options.feature + '' :
       null;
  }
}


/**
 * ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto
 */
export interface ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto {
  /**
   * - minItems: 1
   */
  customerConsentFeature?: ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto[];
}


/**
 * CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto
 *  see ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto
 */
export class CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto {
  /**
   * - minItems: 1
   */
  readonly customerConsentFeature?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto[];

  constructor(options: {
    customerConsentFeature?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.customerConsentFeature = new Array<CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto>();
    if (options !== undefined && options !== null && options.customerConsentFeature !== undefined && options.customerConsentFeature !== null) {
      for (const item of options.customerConsentFeature) {
        this.customerConsentFeature.push(new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeatureCustomerConsentFeatureAnonymDto(item, config));
      }
    }
  }
}


/**
 * ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto
 */
export interface ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto {
  /**
   * The consent id ( UUID )
   * - minLength: 0
   * - maxLength: 80
   */
  consentId: string;
  /**
   * The consent name (unique), for reference purposes
   * - minLength: 0
   * - maxLength: 80
   */
  consentName: string;
  /**
   * The consent version, e.g., 1.0.1.
   * This is a string label for display purposes only.
   * - minLength: 0
   * - maxLength: 45
   */
  consentVersion: string;
  /**
   * True/false if consent was granted/refused
   */
  consentGranted: boolean;
  consentSetDateTime: string;
  customerConsentFeatures?: ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto;
}


/**
 * CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto
 *  see ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto
 */
export class CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto {
  /**
   * The consent id ( UUID )
   * - minLength: 0
   * - maxLength: 80
   */
  readonly consentId: string;
  /**
   * The consent name (unique), for reference purposes
   * - minLength: 0
   * - maxLength: 80
   */
  readonly consentName: string;
  /**
   * The consent version, e.g., 1.0.1.
   * This is a string label for display purposes only.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly consentVersion: string;
  /**
   * True/false if consent was granted/refused
   */
  readonly consentGranted: boolean;
  readonly consentSetDateTime: string;
  readonly customerConsentFeatures?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto;

  constructor(options: {
    consentId: string;
    consentName: string;
    consentVersion: string;
    consentGranted: boolean;
    consentSetDateTime: string;
    customerConsentFeatures?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.consentId =
      (options !== undefined && options !== null && options.consentId !== undefined && options.consentId !== null) ?
        options.consentId + '' :
       null;
    this.consentName =
      (options !== undefined && options !== null && options.consentName !== undefined && options.consentName !== null) ?
        options.consentName + '' :
       null;
    this.consentVersion =
      (options !== undefined && options !== null && options.consentVersion !== undefined && options.consentVersion !== null) ?
        options.consentVersion + '' :
       null;
    this.consentGranted = (options !== undefined && options !== null && options.consentGranted !== undefined && options.consentGranted !== null) ?
        !!options.consentGranted :
       null;
    this.consentSetDateTime =
      (options !== undefined && options !== null && options.consentSetDateTime !== undefined && options.consentSetDateTime !== null) ?
        options.consentSetDateTime + '' :
       null;
    this.customerConsentFeatures =
      (options !== undefined && options !== null && options.customerConsentFeatures !== undefined && options.customerConsentFeatures !== null) ?
        new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto(options.customerConsentFeatures, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentFeaturesCustomerConsentFeaturesAnonymDto( null, config) : undefined );
  }
}


/**
 * ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto
 */
export interface ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto {
  /**
   * - minItems: 1
   */
  customerConsentItem?: ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto[];
}


/**
 * CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto
 *  see ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto
 */
export class CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly customerConsentItem?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto[];

  constructor(options: {
    customerConsentItem?: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.customerConsentItem = new Array<CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto>();
    if (options !== undefined && options !== null && options.customerConsentItem !== undefined && options.customerConsentItem !== null) {
      for (const item of options.customerConsentItem) {
        this.customerConsentItem.push(new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemCustomerConsentItemAnonymDto(item, config));
      }
    }
  }
}


/**
 * ICustomerConsentSettingTypeDto
 */
export interface ICustomerConsentSettingTypeDto {
  id: number;
  /**
   * From customerGetConsentResponse
   */
  created: string;
  customerConsentItems: ICustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto;
}


/**
 * CustomerConsentSettingTypeDto
 *  see ICustomerConsentSettingTypeDto
 */
export class CustomerConsentSettingTypeDto {
  readonly id: number;
  /**
   * From customerGetConsentResponse
   */
  readonly created: string;
  readonly customerConsentItems: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto;

  constructor(options: {
    id: number;
    created: string;
    customerConsentItems: CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.created =
      (options !== undefined && options !== null && options.created !== undefined && options.created !== null) ?
        options.created + '' :
       null;
    this.customerConsentItems =
      (options !== undefined && options !== null && options.customerConsentItems !== undefined && options.customerConsentItems !== null) ?
        new CustomerConsentSettingTypeCustomerConsentItemsCustomerConsentItemsAnonymDto(options.customerConsentItems, config) :
        null;
  }
}


/**
 * IBankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto
 */
export interface IBankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto {
  /**
   * Optional salutation helps to identify account holder type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - COMPANY
   * - ASSOCIATION
   * - ADMINISTRATION
   */
  salutation?: string;
  /**
   * Street of account holder residence.
   * - minLength: 1
   * - maxLength: 80
   */
  street: string;
  /**
   * Street number of account holder residence.
   * - minLength: 1
   * - maxLength: 6
   */
  streetNumber: string;
  /**
   * Zip code of account holder residence.
   * - minLength: 4
   * - maxLength: 6
   */
  zipCode: string;
  /**
   * City of account holder residence.
   * - minLength: 1
   * - maxLength: 80
   */
  city: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   */
  countryCode: string;
  /**
   * Contact email address of account holder.
   * - minLength: 3
   * - maxLength: 80
   */
  email: string;
}


/**
 * BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto
 *  see IBankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto
 */
export class BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto {
  /**
   * Optional salutation helps to identify account holder type (natural person or juristic person) and gender.
   * Enum, one of:
   * - MALE
   * - FEMALE
   * - COMPANY
   * - ASSOCIATION
   * - ADMINISTRATION
   */
  readonly salutation?: string;
  /**
   * Street of account holder residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly street: string;
  /**
   * Street number of account holder residence.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly streetNumber: string;
  /**
   * Zip code of account holder residence.
   * - minLength: 4
   * - maxLength: 6
   */
  readonly zipCode: string;
  /**
   * City of account holder residence.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city: string;
  /**
   * ISO 3166 ALPHA-2 plus XK für Kosovo.
   */
  readonly countryCode: string;
  /**
   * Contact email address of account holder.
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email: string;

  constructor(options: {
    salutation?: string;
    street: string;
    streetNumber: string;
    zipCode: string;
    city: string;
    countryCode: string;
    email: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.salutation =
      (options !== undefined && options !== null && options.salutation !== undefined && options.salutation !== null) ?
        options.salutation + '' :
       (config.viewModel === true) ? null : undefined;
    this.street =
      (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        options.street + '' :
       null;
    this.streetNumber =
      (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        options.streetNumber + '' :
       null;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       null;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       null;
    this.countryCode =
      (options !== undefined && options !== null && options.countryCode !== undefined && options.countryCode !== null) ?
        options.countryCode + '' :
       null;
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       null;
  }
}


/**
 * IBankAccountDataTypeDto
 */
export interface IBankAccountDataTypeDto {
  /**
   * - maxLength: 34
   */
  bankIBAN: string;
  /**
   * - minLength: 8
   * - maxLength: 11
   */
  bankBIC?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  bankName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  bankAccountOwner: string;
  alternativeAccountHolder: boolean;
  alternativeAccountHolderDetails?: IBankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto;
  /**
   * Enum, one of:
   * - TRUE
   * - FALSE
   */
  enableSepaMandat?: string;
}


/**
 * BankAccountDataTypeDto
 *  see IBankAccountDataTypeDto
 */
export class BankAccountDataTypeDto {
  /**
   * - maxLength: 34
   */
  readonly bankIBAN: string;
  /**
   * - minLength: 8
   * - maxLength: 11
   */
  readonly bankBIC?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly bankName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly bankAccountOwner: string;
  readonly alternativeAccountHolder: boolean;
  readonly alternativeAccountHolderDetails?: BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto;
  /**
   * Enum, one of:
   * - TRUE
   * - FALSE
   */
  readonly enableSepaMandat?: string;

  constructor(options: {
    bankIBAN: string;
    bankBIC?: string;
    bankName?: string;
    bankAccountOwner: string;
    alternativeAccountHolder: boolean;
    alternativeAccountHolderDetails?: BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto;
    enableSepaMandat?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.bankIBAN =
      (options !== undefined && options !== null && options.bankIBAN !== undefined && options.bankIBAN !== null) ?
        options.bankIBAN + '' :
       null;
    this.bankBIC =
      (options !== undefined && options !== null && options.bankBIC !== undefined && options.bankBIC !== null) ?
        options.bankBIC + '' :
       (config.viewModel === true) ? null : undefined;
    this.bankName =
      (options !== undefined && options !== null && options.bankName !== undefined && options.bankName !== null) ?
        options.bankName + '' :
       (config.viewModel === true) ? null : undefined;
    this.bankAccountOwner =
      (options !== undefined && options !== null && options.bankAccountOwner !== undefined && options.bankAccountOwner !== null) ?
        options.bankAccountOwner + '' :
       null;
    this.alternativeAccountHolder = (options !== undefined && options !== null && options.alternativeAccountHolder !== undefined && options.alternativeAccountHolder !== null) ?
        !!options.alternativeAccountHolder :
       null;
    this.alternativeAccountHolderDetails =
      (options !== undefined && options !== null && options.alternativeAccountHolderDetails !== undefined && options.alternativeAccountHolderDetails !== null) ?
        new BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto(options.alternativeAccountHolderDetails, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new BankAccountDataTypeAlternativeAccountHolderDetailsAlternativeAccountHolderDetailsAnonymDto( null, config) : undefined );
    this.enableSepaMandat =
      (options !== undefined && options !== null && options.enableSepaMandat !== undefined && options.enableSepaMandat !== null) ?
        options.enableSepaMandat + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICustomerRegistration2RequestOptionsOptionsAnonymDto
 */
export interface ICustomerRegistration2RequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  option?: IOptionTypeDto[];
}


/**
 * CustomerRegistration2RequestOptionsOptionsAnonymDto
 *  see ICustomerRegistration2RequestOptionsOptionsAnonymDto
 */
export class CustomerRegistration2RequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly option?: OptionTypeDto[];

  constructor(options: {
    option?: OptionTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.option = new Array<OptionTypeDto>();
    if (options !== undefined && options !== null && options.option !== undefined && options.option !== null) {
      for (const item of options.option) {
        this.option.push(new OptionTypeDto(item, config));
      }
    }
  }
}


/**
 * ICustomerRegistration2RequestDto
 */
export interface ICustomerRegistration2RequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  processRef: ICustomerRegistration2RequestProcessRefProcessRefAnonymDto;
  /**
   * The unique tenant id the customer is assigned to.
   */
  tenant: number;
  customer?: ICustomerRegistration2RequestCustomerCustomerMergedDto;
  idDocument?: IIdDocumentAddressTypeDto;
  contactAddress?: IContactAddressTypeDto;
  identificationDocument?: IIdDocumentTypeDto;
  customerConsentSetting?: ICustomerConsentSettingTypeDto;
  bankAccountData?: IBankAccountDataTypeDto;
  /**
   * Allows update of specific customer attributes.
   * For use in Filial-App only.
   */
  updateCustomerAddress?: boolean;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  tariffCode: string;
  options?: ICustomerRegistration2RequestOptionsOptionsAnonymDto;
  /**
   * A flag inidcating whether defaults shall be supplied on subscription activation.
   */
  autoSupplyDefaults?: boolean;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  ip?: string;
  skipAddressCheck?: boolean;
  /**
   * Version of the calling client application
   * - minLength: 0
   * - maxLength: 255
   */
  clientVersion?: string;
}


/**
 * CustomerRegistration2RequestDto
 *  see ICustomerRegistration2RequestDto
 */
export class CustomerRegistration2RequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  readonly processRef: CustomerRegistration2RequestProcessRefProcessRefAnonymDto;
  /**
   * The unique tenant id the customer is assigned to.
   */
  readonly tenant: number;
  readonly customer?: CustomerRegistration2RequestCustomerCustomerMergedDto;
  readonly idDocument?: IdDocumentAddressTypeDto;
  readonly contactAddress?: ContactAddressTypeDto;
  readonly identificationDocument?: IdDocumentTypeDto;
  readonly customerConsentSetting?: CustomerConsentSettingTypeDto;
  readonly bankAccountData?: BankAccountDataTypeDto;
  /**
   * Allows update of specific customer attributes.
   * For use in Filial-App only.
   */
  readonly updateCustomerAddress?: boolean;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  readonly tariffCode: string;
  readonly options?: CustomerRegistration2RequestOptionsOptionsAnonymDto;
  /**
   * A flag inidcating whether defaults shall be supplied on subscription activation.
   */
  readonly autoSupplyDefaults?: boolean;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  readonly ip?: string;
  readonly skipAddressCheck?: boolean;
  /**
   * Version of the calling client application
   * - minLength: 0
   * - maxLength: 255
   */
  readonly clientVersion?: string;

  constructor(options: {
    sessionKey?: string;
    processRef: CustomerRegistration2RequestProcessRefProcessRefAnonymDto;
    tenant: number;
    customer?: CustomerRegistration2RequestCustomerCustomerMergedDto;
    idDocument?: IdDocumentAddressTypeDto;
    contactAddress?: ContactAddressTypeDto;
    identificationDocument?: IdDocumentTypeDto;
    customerConsentSetting?: CustomerConsentSettingTypeDto;
    bankAccountData?: BankAccountDataTypeDto;
    updateCustomerAddress?: boolean;
    tariffCode: string;
    options?: CustomerRegistration2RequestOptionsOptionsAnonymDto;
    autoSupplyDefaults?: boolean;
    ip?: string;
    skipAddressCheck?: boolean;
    clientVersion?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.processRef =
      (options !== undefined && options !== null && options.processRef !== undefined && options.processRef !== null) ?
        new CustomerRegistration2RequestProcessRefProcessRefAnonymDto(options.processRef, config) :
        null;
    this.tenant =
      (options !== undefined && options !== null && options.tenant !== undefined && options.tenant !== null) ?
        Number(options.tenant) :
       null;
    this.customer =
      (options !== undefined && options !== null && options.customer !== undefined && options.customer !== null) ?
        new CustomerRegistration2RequestCustomerCustomerMergedDto(options.customer, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CustomerRegistration2RequestCustomerCustomerMergedDto( null, config) : null ) : (config.hierarchy === true ? new CustomerRegistration2RequestCustomerCustomerMergedDto( null, config) : undefined );
    this.idDocument =
      (options !== undefined && options !== null && options.idDocument !== undefined && options.idDocument !== null) ?
        new IdDocumentAddressTypeDto(options.idDocument, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdDocumentAddressTypeDto( null, config) : null ) : (config.hierarchy === true ? new IdDocumentAddressTypeDto( null, config) : undefined );
    this.contactAddress =
      (options !== undefined && options !== null && options.contactAddress !== undefined && options.contactAddress !== null) ?
        new ContactAddressTypeDto(options.contactAddress, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ContactAddressTypeDto( null, config) : null ) : (config.hierarchy === true ? new ContactAddressTypeDto( null, config) : undefined );
    this.identificationDocument =
      (options !== undefined && options !== null && options.identificationDocument !== undefined && options.identificationDocument !== null) ?
        new IdDocumentTypeDto(options.identificationDocument, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdDocumentTypeDto( null, config) : null ) : (config.hierarchy === true ? new IdDocumentTypeDto( null, config) : undefined );
    this.customerConsentSetting =
      (options !== undefined && options !== null && options.customerConsentSetting !== undefined && options.customerConsentSetting !== null) ?
        new CustomerConsentSettingTypeDto(options.customerConsentSetting, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CustomerConsentSettingTypeDto( null, config) : null ) : (config.hierarchy === true ? new CustomerConsentSettingTypeDto( null, config) : undefined );
    this.bankAccountData =
      (options !== undefined && options !== null && options.bankAccountData !== undefined && options.bankAccountData !== null) ?
        new BankAccountDataTypeDto(options.bankAccountData, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new BankAccountDataTypeDto( null, config) : null ) : (config.hierarchy === true ? new BankAccountDataTypeDto( null, config) : undefined );
    this.updateCustomerAddress = (options !== undefined && options !== null && options.updateCustomerAddress !== undefined && options.updateCustomerAddress !== null) ?
        !!options.updateCustomerAddress :
       (config.viewModel === true) ? null : undefined;
    this.tariffCode =
      (options !== undefined && options !== null && options.tariffCode !== undefined && options.tariffCode !== null) ?
        options.tariffCode + '' :
       null;
    this.options =
      (options !== undefined && options !== null && options.options !== undefined && options.options !== null) ?
        new CustomerRegistration2RequestOptionsOptionsAnonymDto(options.options, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CustomerRegistration2RequestOptionsOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new CustomerRegistration2RequestOptionsOptionsAnonymDto( null, config) : undefined );
    this.autoSupplyDefaults = (options !== undefined && options !== null && options.autoSupplyDefaults !== undefined && options.autoSupplyDefaults !== null) ?
        !!options.autoSupplyDefaults :
       (config.viewModel === true) ? null : undefined;
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
    this.skipAddressCheck = (options !== undefined && options !== null && options.skipAddressCheck !== undefined && options.skipAddressCheck !== null) ?
        !!options.skipAddressCheck :
       (config.viewModel === true) ? null : undefined;
    this.clientVersion =
      (options !== undefined && options !== null && options.clientVersion !== undefined && options.clientVersion !== null) ?
        options.clientVersion + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto
 */
export interface IAddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto {
  street?: boolean;
  streetNumber?: boolean;
  zipCode?: boolean;
  city?: boolean;
}


/**
 * AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto
 *  see IAddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto
 */
export class AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto {
  readonly street?: boolean;
  readonly streetNumber?: boolean;
  readonly zipCode?: boolean;
  readonly city?: boolean;

  constructor(options: {
    street?: boolean;
    streetNumber?: boolean;
    zipCode?: boolean;
    city?: boolean;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.street = (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        !!options.street :
       (config.viewModel === true) ? null : undefined;
    this.streetNumber = (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        !!options.streetNumber :
       (config.viewModel === true) ? null : undefined;
    this.zipCode = (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        !!options.zipCode :
       (config.viewModel === true) ? null : undefined;
    this.city = (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        !!options.city :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAddressCheckResultAddressProposalAddressProposalAnonymDto
 */
export interface IAddressCheckResultAddressProposalAddressProposalAnonymDto {
  /**
   * Type for street names as frequently used in addresses.
   * Street names in the German context comprises 1 to 80 selected characters.
   * - minLength: 1
   * - maxLength: 80
   */
  street?: string;
  /**
   * Type for street numbers as frequently used in addresses.
   * Street numbers in the German context comprises 1 to 6 selected characters.
   * - minLength: 1
   * - maxLength: 6
   */
  streetNumber?: string;
  /**
   * Type for ZIP codes (PLZ in German) as frequently used in addresses.
   * ZIP codes in this context (German PLZ) comprises 4 to 6 digits.
   * This should cover most neighbor countries.
   * - minLength: 4
   * - maxLength: 6
   */
  zipCode?: string;
  /**
   * Type for city names as frequently used in addresses.
   * City names in the German context comprises 1 to 80 selected characters.
   * - minLength: 1
   * - maxLength: 80
   */
  city?: string;
}


/**
 * AddressCheckResultAddressProposalAddressProposalAnonymDto
 *  see IAddressCheckResultAddressProposalAddressProposalAnonymDto
 */
export class AddressCheckResultAddressProposalAddressProposalAnonymDto {
  /**
   * Type for street names as frequently used in addresses.
   * Street names in the German context comprises 1 to 80 selected characters.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly street?: string;
  /**
   * Type for street numbers as frequently used in addresses.
   * Street numbers in the German context comprises 1 to 6 selected characters.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly streetNumber?: string;
  /**
   * Type for ZIP codes (PLZ in German) as frequently used in addresses.
   * ZIP codes in this context (German PLZ) comprises 4 to 6 digits.
   * This should cover most neighbor countries.
   * - minLength: 4
   * - maxLength: 6
   */
  readonly zipCode?: string;
  /**
   * Type for city names as frequently used in addresses.
   * City names in the German context comprises 1 to 80 selected characters.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city?: string;

  constructor(options: {
    street?: string;
    streetNumber?: string;
    zipCode?: string;
    city?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.street =
      (options !== undefined && options !== null && options.street !== undefined && options.street !== null) ?
        options.street + '' :
       (config.viewModel === true) ? null : undefined;
    this.streetNumber =
      (options !== undefined && options !== null && options.streetNumber !== undefined && options.streetNumber !== null) ?
        options.streetNumber + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAddressCheckResultDto
 */
export interface IAddressCheckResultDto {
  resultInfo?: string;
  /**
   * Enum, one of:
   * - CONTACT
   * - ID_DOCUMENT
   */
  addressType: string;
  elementsToBeChecked?: IAddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto;
  /**
   * - minItems: 0
   */
  addressProposal?: IAddressCheckResultAddressProposalAddressProposalAnonymDto[];
}


/**
 * AddressCheckResultDto
 *  see IAddressCheckResultDto
 */
export class AddressCheckResultDto {
  readonly resultInfo?: string;
  /**
   * Enum, one of:
   * - CONTACT
   * - ID_DOCUMENT
   */
  readonly addressType: string;
  readonly elementsToBeChecked?: AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto;
  /**
   * - minItems: 0
   */
  readonly addressProposal?: AddressCheckResultAddressProposalAddressProposalAnonymDto[];

  constructor(options: {
    resultInfo?: string;
    addressType: string;
    elementsToBeChecked?: AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto;
    addressProposal?: AddressCheckResultAddressProposalAddressProposalAnonymDto[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.resultInfo =
      (options !== undefined && options !== null && options.resultInfo !== undefined && options.resultInfo !== null) ?
        options.resultInfo + '' :
       (config.viewModel === true) ? null : undefined;
    this.addressType =
      (options !== undefined && options !== null && options.addressType !== undefined && options.addressType !== null) ?
        options.addressType + '' :
       null;
    this.elementsToBeChecked =
      (options !== undefined && options !== null && options.elementsToBeChecked !== undefined && options.elementsToBeChecked !== null) ?
        new AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto(options.elementsToBeChecked, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto( null, config) : null ) : (config.hierarchy === true ? new AddressCheckResultElementsToBeCheckedElementsToBeCheckedAnonymDto( null, config) : undefined );
    this.addressProposal = new Array<AddressCheckResultAddressProposalAddressProposalAnonymDto>();
    if (options !== undefined && options !== null && options.addressProposal !== undefined && options.addressProposal !== null) {
      for (const item of options.addressProposal) {
        this.addressProposal.push(new AddressCheckResultAddressProposalAddressProposalAnonymDto(item, config));
      }
    }
  }
}


/**
 * ICustomerRegistration2ResponseDto
 */
export interface ICustomerRegistration2ResponseDto {
  result: IDefaultReturnTypeDto;
  addressCheckResult?: IAddressCheckResultDto;
  eeccAvailable?: boolean;
}


/**
 * CustomerRegistration2ResponseDto
 *  see ICustomerRegistration2ResponseDto
 */
export class CustomerRegistration2ResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly addressCheckResult?: AddressCheckResultDto;
  readonly eeccAvailable?: boolean;

  constructor(options: {
    result: DefaultReturnTypeDto;
    addressCheckResult?: AddressCheckResultDto;
    eeccAvailable?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.addressCheckResult =
      (options !== undefined && options !== null && options.addressCheckResult !== undefined && options.addressCheckResult !== null) ?
        new AddressCheckResultDto(options.addressCheckResult, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AddressCheckResultDto( null, config) : null ) : (config.hierarchy === true ? new AddressCheckResultDto( null, config) : undefined );
    this.eeccAvailable = (options !== undefined && options !== null && options.eeccAvailable !== undefined && options.eeccAvailable !== null) ?
        !!options.eeccAvailable :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentCreateOrderRequestDto
 */
export interface IIdentCreateOrderRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  puk2?: string;
  /**
   * unique ID (code) for that provider, e.g.
   * from previous call to IdentGetVideoOperatorRequest
   * - minLength: 0
   * - maxLength: 16
   */
  identificationServiceProvider: string;
  /**
   * Contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  email: string;
  /**
   * 2 character language code in upper or lower case
   */
  desiredLanguage?: string;
}


/**
 * IdentCreateOrderRequestDto
 *  see IIdentCreateOrderRequestDto
 */
export class IdentCreateOrderRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  readonly sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2?: string;
  /**
   * unique ID (code) for that provider, e.g.
   * from previous call to IdentGetVideoOperatorRequest
   * - minLength: 0
   * - maxLength: 16
   */
  readonly identificationServiceProvider: string;
  /**
   * Contact email address of customer.
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email: string;
  /**
   * 2 character language code in upper or lower case
   */
  readonly desiredLanguage?: string;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
    sim: string;
    msisdn?: string;
    puk2?: string;
    identificationServiceProvider: string;
    email: string;
    desiredLanguage?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.identificationServiceProvider =
      (options !== undefined && options !== null && options.identificationServiceProvider !== undefined && options.identificationServiceProvider !== null) ?
        options.identificationServiceProvider + '' :
       null;
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       null;
    this.desiredLanguage =
      (options !== undefined && options !== null && options.desiredLanguage !== undefined && options.desiredLanguage !== null) ?
        options.desiredLanguage + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentCreateOrderResponseDto
 */
export interface IIdentCreateOrderResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * only valid if result.code=0.
   * Is complete URL including token to start session or download voucher.
   * For some Offline-POS this element might be skipped
   */
  redirectURL?: string;
  /**
   * only valid if result.code=0
   */
  transactionId?: string;
  /**
   * only valid if result.code=0.
   */
  externalRef?: string;
  /**
   * only valid if result.code=0.
   * For some service providers this element might be skipped.
   */
  shortCode?: string;
}


/**
 * IdentCreateOrderResponseDto
 *  see IIdentCreateOrderResponseDto
 */
export class IdentCreateOrderResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * only valid if result.code=0.
   * Is complete URL including token to start session or download voucher.
   * For some Offline-POS this element might be skipped
   */
  readonly redirectURL?: string;
  /**
   * only valid if result.code=0
   */
  readonly transactionId?: string;
  /**
   * only valid if result.code=0.
   */
  readonly externalRef?: string;
  /**
   * only valid if result.code=0.
   * For some service providers this element might be skipped.
   */
  readonly shortCode?: string;

  constructor(options: {
    result: DefaultReturnTypeDto;
    redirectURL?: string;
    transactionId?: string;
    externalRef?: string;
    shortCode?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.redirectURL =
      (options !== undefined && options !== null && options.redirectURL !== undefined && options.redirectURL !== null) ?
        options.redirectURL + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionId =
      (options !== undefined && options !== null && options.transactionId !== undefined && options.transactionId !== null) ?
        options.transactionId + '' :
       (config.viewModel === true) ? null : undefined;
    this.externalRef =
      (options !== undefined && options !== null && options.externalRef !== undefined && options.externalRef !== null) ?
        options.externalRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.shortCode =
      (options !== undefined && options !== null && options.shortCode !== undefined && options.shortCode !== null) ?
        options.shortCode + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentGetCountryRequestDto
 */
export interface IIdentGetCountryRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  documentType: string;
  /**
   * Whether the response shall only contain EU countries.
   */
  onlyEU?: boolean;
}


/**
 * IdentGetCountryRequestDto
 *  see IIdentGetCountryRequestDto
 */
export class IdentGetCountryRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  readonly documentType: string;
  /**
   * Whether the response shall only contain EU countries.
   */
  readonly onlyEU?: boolean;

  constructor(options: {
    transactionRef?: string;
    documentType: string;
    onlyEU?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       null;
    this.onlyEU = (options !== undefined && options !== null && options.onlyEU !== undefined && options.onlyEU !== null) ?
        !!options.onlyEU :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentificationCountryTypeDto
 */
export interface IIdentificationCountryTypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  countryCode2: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  stdName: string;
  memberOfEU?: boolean;
  localeIN?: string;
}


/**
 * IdentificationCountryTypeDto
 *  see IIdentificationCountryTypeDto
 */
export class IdentificationCountryTypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  readonly countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  readonly countryCode2: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly stdName: string;
  readonly memberOfEU?: boolean;
  readonly localeIN?: string;

  constructor(options: {
    countryCode3: string;
    countryCode2: string;
    name: string;
    stdName: string;
    memberOfEU?: boolean;
    localeIN?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.countryCode3 =
      (options !== undefined && options !== null && options.countryCode3 !== undefined && options.countryCode3 !== null) ?
        options.countryCode3 + '' :
       null;
    this.countryCode2 =
      (options !== undefined && options !== null && options.countryCode2 !== undefined && options.countryCode2 !== null) ?
        options.countryCode2 + '' :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.stdName =
      (options !== undefined && options !== null && options.stdName !== undefined && options.stdName !== null) ?
        options.stdName + '' :
       null;
    this.memberOfEU = (options !== undefined && options !== null && options.memberOfEU !== undefined && options.memberOfEU !== null) ?
        !!options.memberOfEU :
       (config.viewModel === true) ? null : undefined;
    this.localeIN =
      (options !== undefined && options !== null && options.localeIN !== undefined && options.localeIN !== null) ?
        options.localeIN + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto
 */
export interface IIdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto {
  /**
   * - minItems: 1
   */
  identificationCountry?: IIdentificationCountryTypeDto[];
}


/**
 * IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto
 *  see IIdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto
 */
export class IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto {
  /**
   * - minItems: 1
   */
  readonly identificationCountry?: IdentificationCountryTypeDto[];

  constructor(options: {
    identificationCountry?: IdentificationCountryTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.identificationCountry = new Array<IdentificationCountryTypeDto>();
    if (options !== undefined && options !== null && options.identificationCountry !== undefined && options.identificationCountry !== null) {
      for (const item of options.identificationCountry) {
        this.identificationCountry.push(new IdentificationCountryTypeDto(item, config));
      }
    }
  }
}


/**
 * IIdentGetCountryResponseDto
 */
export interface IIdentGetCountryResponseDto {
  result: IDefaultReturnTypeDto;
  identificationCountries?: IIdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto;
}


/**
 * IdentGetCountryResponseDto
 *  see IIdentGetCountryResponseDto
 */
export class IdentGetCountryResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly identificationCountries?: IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    identificationCountries?: IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.identificationCountries =
      (options !== undefined && options !== null && options.identificationCountries !== undefined && options.identificationCountries !== null) ?
        new IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto(options.identificationCountries, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto( null, config) : null ) : (config.hierarchy === true ? new IdentGetCountryResponseIdentificationCountriesIdentificationCountriesAnonymDto( null, config) : undefined );
  }
}


/**
 * IIdentGetServiceProviderRequestDto
 */
export interface IIdentGetServiceProviderRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Identification document type (ENUM value) as returned by IdentGetCountryResponse
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  documentType?: string;
  /**
   * Country code as returned by IdentGetCountryResponse
   */
  countryCode2?: string;
}


/**
 * IdentGetServiceProviderRequestDto
 *  see IIdentGetServiceProviderRequestDto
 */
export class IdentGetServiceProviderRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Identification document type (ENUM value) as returned by IdentGetCountryResponse
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  readonly documentType?: string;
  /**
   * Country code as returned by IdentGetCountryResponse
   */
  readonly countryCode2?: string;

  constructor(options: {
    transactionRef?: string;
    documentType?: string;
    countryCode2?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       (config.viewModel === true) ? null : undefined;
    this.countryCode2 =
      (options !== undefined && options !== null && options.countryCode2 !== undefined && options.countryCode2 !== null) ?
        options.countryCode2 + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAvailableLanguagesLanguageLanguageAnonymDto
 */
export interface IAvailableLanguagesLanguageLanguageAnonymDto {
  /**
   * 2-character code lower case ISO 639-1, e.g.
   * 'fr'
   */
  code: string;
  /**
   * 3-character code lower case ISO 639-2/T, e.g.
   * 'fra'
   */
  code3: string;
  /**
   * Name of the language in german, e.g.
   * 'Französisch'
   */
  name: string;
  /**
   * Name of the language in the other language, e.g.
   * 'français'
   */
  nativeName: string;
  /**
   * sort order for the GUI, lowest value shall appear on top/first
   */
  sort?: number;
}


/**
 * AvailableLanguagesLanguageLanguageAnonymDto
 *  see IAvailableLanguagesLanguageLanguageAnonymDto
 */
export class AvailableLanguagesLanguageLanguageAnonymDto {
  /**
   * 2-character code lower case ISO 639-1, e.g.
   * 'fr'
   */
  readonly code: string;
  /**
   * 3-character code lower case ISO 639-2/T, e.g.
   * 'fra'
   */
  readonly code3: string;
  /**
   * Name of the language in german, e.g.
   * 'Französisch'
   */
  readonly name: string;
  /**
   * Name of the language in the other language, e.g.
   * 'français'
   */
  readonly nativeName: string;
  /**
   * sort order for the GUI, lowest value shall appear on top/first
   */
  readonly sort?: number;

  constructor(options: {
    code: string;
    code3: string;
    name: string;
    nativeName: string;
    sort?: number;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.code3 =
      (options !== undefined && options !== null && options.code3 !== undefined && options.code3 !== null) ?
        options.code3 + '' :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.nativeName =
      (options !== undefined && options !== null && options.nativeName !== undefined && options.nativeName !== null) ?
        options.nativeName + '' :
       null;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAvailableLanguagesDto
 */
export interface IAvailableLanguagesDto {
  /**
   * - minItems: 1
   */
  language?: IAvailableLanguagesLanguageLanguageAnonymDto[];
}


/**
 * AvailableLanguagesDto
 *  see IAvailableLanguagesDto
 */
export class AvailableLanguagesDto {
  /**
   * - minItems: 1
   */
  readonly language?: AvailableLanguagesLanguageLanguageAnonymDto[];

  constructor(options: {
    language?: AvailableLanguagesLanguageLanguageAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.language = new Array<AvailableLanguagesLanguageLanguageAnonymDto>();
    if (options !== undefined && options !== null && options.language !== undefined && options.language !== null) {
      for (const item of options.language) {
        this.language.push(new AvailableLanguagesLanguageLanguageAnonymDto(item, config));
      }
    }
  }
}


/**
 * IIdentificationServiceProviderDetailsTypeDto
 */
export interface IIdentificationServiceProviderDetailsTypeDto {
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  fullName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * Type for identification methods.
   * Enum, one of:
   * - VIDEO
   * - POS
   */
  identificationMethod?: string;
  supportedLanguages?: IAvailableLanguagesDto;
}


/**
 * IdentificationServiceProviderDetailsTypeDto
 *  see IIdentificationServiceProviderDetailsTypeDto
 */
export class IdentificationServiceProviderDetailsTypeDto {
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly fullName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * Type for identification methods.
   * Enum, one of:
   * - VIDEO
   * - POS
   */
  readonly identificationMethod?: string;
  readonly supportedLanguages?: AvailableLanguagesDto;

  constructor(options: {
    name: string;
    fullName?: string;
    description?: string;
    identificationMethod?: string;
    supportedLanguages?: AvailableLanguagesDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.fullName =
      (options !== undefined && options !== null && options.fullName !== undefined && options.fullName !== null) ?
        options.fullName + '' :
       (config.viewModel === true) ? null : undefined;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.identificationMethod =
      (options !== undefined && options !== null && options.identificationMethod !== undefined && options.identificationMethod !== null) ?
        options.identificationMethod + '' :
       (config.viewModel === true) ? null : undefined;
    this.supportedLanguages =
      (options !== undefined && options !== null && options.supportedLanguages !== undefined && options.supportedLanguages !== null) ?
        new AvailableLanguagesDto(options.supportedLanguages, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : null ) : (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : undefined );
  }
}


/**
 * IIdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto
 */
export interface IIdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto {
  /**
   * - minItems: 1
   */
  detailedIdentificationServiceProvider?: IIdentificationServiceProviderDetailsTypeDto[];
}


/**
 * IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto
 *  see IIdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto
 */
export class IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto {
  /**
   * - minItems: 1
   */
  readonly detailedIdentificationServiceProvider?: IdentificationServiceProviderDetailsTypeDto[];

  constructor(options: {
    detailedIdentificationServiceProvider?: IdentificationServiceProviderDetailsTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.detailedIdentificationServiceProvider = new Array<IdentificationServiceProviderDetailsTypeDto>();
    if (options !== undefined && options !== null && options.detailedIdentificationServiceProvider !== undefined && options.detailedIdentificationServiceProvider !== null) {
      for (const item of options.detailedIdentificationServiceProvider) {
        this.detailedIdentificationServiceProvider.push(new IdentificationServiceProviderDetailsTypeDto(item, config));
      }
    }
  }
}


/**
 * IIdentGetServiceProviderResponseDto
 */
export interface IIdentGetServiceProviderResponseDto {
  result: IDefaultReturnTypeDto;
  identificationServiceProviders?: IIdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto;
  availableLanguages?: IAvailableLanguagesDto;
}


/**
 * IdentGetServiceProviderResponseDto
 *  see IIdentGetServiceProviderResponseDto
 */
export class IdentGetServiceProviderResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly identificationServiceProviders?: IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto;
  readonly availableLanguages?: AvailableLanguagesDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    identificationServiceProviders?: IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto;
    availableLanguages?: AvailableLanguagesDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.identificationServiceProviders =
      (options !== undefined && options !== null && options.identificationServiceProviders !== undefined && options.identificationServiceProviders !== null) ?
        new IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto(options.identificationServiceProviders, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto( null, config) : null ) : (config.hierarchy === true ? new IdentGetServiceProviderResponseIdentificationServiceProvidersIdentificationServiceProvidersAnonymDto( null, config) : undefined );
    this.availableLanguages =
      (options !== undefined && options !== null && options.availableLanguages !== undefined && options.availableLanguages !== null) ?
        new AvailableLanguagesDto(options.availableLanguages, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : null ) : (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : undefined );
  }
}


/**
 * IIdentGetVideoOperatorRequestDto
 */
export interface IIdentGetVideoOperatorRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * 2 character language code in upper or lower case
   */
  desiredLanguage?: string;
}


/**
 * IdentGetVideoOperatorRequestDto
 *  see IIdentGetVideoOperatorRequestDto
 */
export class IdentGetVideoOperatorRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  readonly sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * 2 character language code in upper or lower case
   */
  readonly desiredLanguage?: string;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
    desiredLanguage?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.desiredLanguage =
      (options !== undefined && options !== null && options.desiredLanguage !== undefined && options.desiredLanguage !== null) ?
        options.desiredLanguage + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IVideoIdentificationOperatorTypeDto
 */
export interface IVideoIdentificationOperatorTypeDto {
  /**
   * - minLength: 1
   * - maxLength: 80
   */
  name: string;
  /**
   * unique ID (code) for that operator
   * - minLength: 0
   * - maxLength: 16
   */
  code: string;
  /**
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  /**
   * This is a link to explanation web site for that operator
   * - minLength: 1
   * - maxLength: 400
   */
  descriptionLink?: string;
  /**
   * Estimated time to wait in seconds.
   * Note that this calculation does not consider the different languages
   */
  estimatedWaitingTime: number;
  availableLanguages?: IAvailableLanguagesDto;
}


/**
 * VideoIdentificationOperatorTypeDto
 *  see IVideoIdentificationOperatorTypeDto
 */
export class VideoIdentificationOperatorTypeDto {
  /**
   * - minLength: 1
   * - maxLength: 80
   */
  readonly name: string;
  /**
   * unique ID (code) for that operator
   * - minLength: 0
   * - maxLength: 16
   */
  readonly code: string;
  /**
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  /**
   * This is a link to explanation web site for that operator
   * - minLength: 1
   * - maxLength: 400
   */
  readonly descriptionLink?: string;
  /**
   * Estimated time to wait in seconds.
   * Note that this calculation does not consider the different languages
   */
  readonly estimatedWaitingTime: number;
  readonly availableLanguages?: AvailableLanguagesDto;

  constructor(options: {
    name: string;
    code: string;
    description?: string;
    descriptionLink?: string;
    estimatedWaitingTime: number;
    availableLanguages?: AvailableLanguagesDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.descriptionLink =
      (options !== undefined && options !== null && options.descriptionLink !== undefined && options.descriptionLink !== null) ?
        options.descriptionLink + '' :
       (config.viewModel === true) ? null : undefined;
    this.estimatedWaitingTime =
      (options !== undefined && options !== null && options.estimatedWaitingTime !== undefined && options.estimatedWaitingTime !== null) ?
        Number(options.estimatedWaitingTime) :
       null;
    this.availableLanguages =
      (options !== undefined && options !== null && options.availableLanguages !== undefined && options.availableLanguages !== null) ?
        new AvailableLanguagesDto(options.availableLanguages, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : null ) : (config.hierarchy === true ? new AvailableLanguagesDto( null, config) : undefined );
  }
}


/**
 * IIdentGetVideoOperatorResponseDto
 */
export interface IIdentGetVideoOperatorResponseDto {
  result: IDefaultReturnTypeDto;
  videoOperator?: IVideoIdentificationOperatorTypeDto;
}


/**
 * IdentGetVideoOperatorResponseDto
 *  see IIdentGetVideoOperatorResponseDto
 */
export class IdentGetVideoOperatorResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly videoOperator?: VideoIdentificationOperatorTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    videoOperator?: VideoIdentificationOperatorTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.videoOperator =
      (options !== undefined && options !== null && options.videoOperator !== undefined && options.videoOperator !== null) ?
        new VideoIdentificationOperatorTypeDto(options.videoOperator, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new VideoIdentificationOperatorTypeDto( null, config) : null ) : (config.hierarchy === true ? new VideoIdentificationOperatorTypeDto( null, config) : undefined );
  }
}


/**
 * IIdentListDocumentTypeRequestDto
 */
export interface IIdentListDocumentTypeRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Code/name of requesting partner
   * - minLength: 1
   * - maxLength: 80
   */
  identityPartnerCode: string;
}


/**
 * IdentListDocumentTypeRequestDto
 *  see IIdentListDocumentTypeRequestDto
 */
export class IdentListDocumentTypeRequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Code/name of requesting partner
   * - minLength: 1
   * - maxLength: 80
   */
  readonly identityPartnerCode: string;

  constructor(options: {
    transactionRef?: string;
    identityPartnerCode: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.identityPartnerCode =
      (options !== undefined && options !== null && options.identityPartnerCode !== undefined && options.identityPartnerCode !== null) ?
        options.identityPartnerCode + '' :
       null;
  }
}


/**
 * IIdentificationDocumentTypeDto
 */
export interface IIdentificationDocumentTypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  countryCode2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  stdName?: string;
  sort: number;
  /**
   * Country is part of EU
   */
  EU: boolean;
  /**
   * Passport allowed?
   */
  passport?: boolean;
  /**
   * Identity card allowed?
   */
  idCard?: boolean;
  idCardContainsAddress?: boolean;
  /**
   * Permission required?
   */
  residentPermissionRequired?: boolean;
  permanentResidentPermission?: boolean;
}


/**
 * IdentificationDocumentTypeDto
 *  see IIdentificationDocumentTypeDto
 */
export class IdentificationDocumentTypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  readonly countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  readonly countryCode2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly stdName?: string;
  readonly sort: number;
  /**
   * Country is part of EU
   */
  readonly EU: boolean;
  /**
   * Passport allowed?
   */
  readonly passport?: boolean;
  /**
   * Identity card allowed?
   */
  readonly idCard?: boolean;
  readonly idCardContainsAddress?: boolean;
  /**
   * Permission required?
   */
  readonly residentPermissionRequired?: boolean;
  readonly permanentResidentPermission?: boolean;

  constructor(options: {
    countryCode3: string;
    countryCode2?: string;
    name?: string;
    stdName?: string;
    sort: number;
    EU: boolean;
    passport?: boolean;
    idCard?: boolean;
    idCardContainsAddress?: boolean;
    residentPermissionRequired?: boolean;
    permanentResidentPermission?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.countryCode3 =
      (options !== undefined && options !== null && options.countryCode3 !== undefined && options.countryCode3 !== null) ?
        options.countryCode3 + '' :
       null;
    this.countryCode2 =
      (options !== undefined && options !== null && options.countryCode2 !== undefined && options.countryCode2 !== null) ?
        options.countryCode2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       (config.viewModel === true) ? null : undefined;
    this.stdName =
      (options !== undefined && options !== null && options.stdName !== undefined && options.stdName !== null) ?
        options.stdName + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.EU = (options !== undefined && options !== null && options.EU !== undefined && options.EU !== null) ?
        !!options.EU :
       null;
    this.passport = (options !== undefined && options !== null && options.passport !== undefined && options.passport !== null) ?
        !!options.passport :
       (config.viewModel === true) ? null : undefined;
    this.idCard = (options !== undefined && options !== null && options.idCard !== undefined && options.idCard !== null) ?
        !!options.idCard :
       (config.viewModel === true) ? null : undefined;
    this.idCardContainsAddress = (options !== undefined && options !== null && options.idCardContainsAddress !== undefined && options.idCardContainsAddress !== null) ?
        !!options.idCardContainsAddress :
       (config.viewModel === true) ? null : undefined;
    this.residentPermissionRequired = (options !== undefined && options !== null && options.residentPermissionRequired !== undefined && options.residentPermissionRequired !== null) ?
        !!options.residentPermissionRequired :
       (config.viewModel === true) ? null : undefined;
    this.permanentResidentPermission = (options !== undefined && options !== null && options.permanentResidentPermission !== undefined && options.permanentResidentPermission !== null) ?
        !!options.permanentResidentPermission :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export interface IIdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  identificationDocument?: IIdentificationDocumentTypeDto[];
}


/**
 * IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 *  see IIdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export class IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly identificationDocument?: IdentificationDocumentTypeDto[];

  constructor(options: {
    identificationDocument?: IdentificationDocumentTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.identificationDocument = new Array<IdentificationDocumentTypeDto>();
    if (options !== undefined && options !== null && options.identificationDocument !== undefined && options.identificationDocument !== null) {
      for (const item of options.identificationDocument) {
        this.identificationDocument.push(new IdentificationDocumentTypeDto(item, config));
      }
    }
  }
}


/**
 * IIdentListDocumentTypeResponseDto
 */
export interface IIdentListDocumentTypeResponseDto {
  result: IDefaultReturnTypeDto;
  identificationDocuments?: IIdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
}


/**
 * IdentListDocumentTypeResponseDto
 *  see IIdentListDocumentTypeResponseDto
 */
export class IdentListDocumentTypeResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly identificationDocuments?: IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    identificationDocuments?: IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.identificationDocuments =
      (options !== undefined && options !== null && options.identificationDocuments !== undefined && options.identificationDocuments !== null) ?
        new IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto(options.identificationDocuments, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new IdentListDocumentTypeResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : undefined );
  }
}


/**
 * IIdentListDocumentType2RequestDto
 */
export interface IIdentListDocumentType2RequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
}


/**
 * IdentListDocumentType2RequestDto
 *  see IIdentListDocumentType2RequestDto
 */
export class IdentListDocumentType2RequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;

  constructor(options: {
    transactionRef?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentificationDocument2TypeDto
 */
export interface IIdentificationDocument2TypeDto {
  /**
   * The name of the Identification document type as presented within the frontend to customers, e.g.
   * 'ausländischer Reisepass'
   * - minLength: 1
   * - maxLength: 80
   */
  name: string;
  /**
   * The technical ENUM used within Ecobill services for this identification document type
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  documentType: string;
  /**
   * An optional category used for display grouping in frontends
   * - minLength: 0
   * - maxLength: 80
   */
  category?: string;
}


/**
 * IdentificationDocument2TypeDto
 *  see IIdentificationDocument2TypeDto
 */
export class IdentificationDocument2TypeDto {
  /**
   * The name of the Identification document type as presented within the frontend to customers, e.g.
   * 'ausländischer Reisepass'
   * - minLength: 1
   * - maxLength: 80
   */
  readonly name: string;
  /**
   * The technical ENUM used within Ecobill services for this identification document type
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  readonly documentType: string;
  /**
   * An optional category used for display grouping in frontends
   * - minLength: 0
   * - maxLength: 80
   */
  readonly category?: string;

  constructor(options: {
    name: string;
    documentType: string;
    category?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       null;
    this.category =
      (options !== undefined && options !== null && options.category !== undefined && options.category !== null) ?
        options.category + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export interface IIdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  identificationDocument?: IIdentificationDocument2TypeDto[];
}


/**
 * IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 *  see IIdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export class IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly identificationDocument?: IdentificationDocument2TypeDto[];

  constructor(options: {
    identificationDocument?: IdentificationDocument2TypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.identificationDocument = new Array<IdentificationDocument2TypeDto>();
    if (options !== undefined && options !== null && options.identificationDocument !== undefined && options.identificationDocument !== null) {
      for (const item of options.identificationDocument) {
        this.identificationDocument.push(new IdentificationDocument2TypeDto(item, config));
      }
    }
  }
}


/**
 * IIdentListDocumentType2ResponseDto
 */
export interface IIdentListDocumentType2ResponseDto {
  result: IDefaultReturnTypeDto;
  identificationDocuments?: IIdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
}


/**
 * IdentListDocumentType2ResponseDto
 *  see IIdentListDocumentType2ResponseDto
 */
export class IdentListDocumentType2ResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly identificationDocuments?: IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    identificationDocuments?: IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.identificationDocuments =
      (options !== undefined && options !== null && options.identificationDocuments !== undefined && options.identificationDocuments !== null) ?
        new IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto(options.identificationDocuments, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new IdentListDocumentType2ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : undefined );
  }
}


/**
 * IIdentListDocumentType3RequestDto
 */
export interface IIdentListDocumentType3RequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Code/name of requesting partner
   * - minLength: 1
   * - maxLength: 80
   */
  identityPartnerCode: string;
}


/**
 * IdentListDocumentType3RequestDto
 *  see IIdentListDocumentType3RequestDto
 */
export class IdentListDocumentType3RequestDto {
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Code/name of requesting partner
   * - minLength: 1
   * - maxLength: 80
   */
  readonly identityPartnerCode: string;

  constructor(options: {
    transactionRef?: string;
    identityPartnerCode: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.identityPartnerCode =
      (options !== undefined && options !== null && options.identityPartnerCode !== undefined && options.identityPartnerCode !== null) ?
        options.identityPartnerCode + '' :
       null;
  }
}


/**
 * IIdentificationDocumentsTypeDocumentDocumentAnonymDto
 */
export interface IIdentificationDocumentsTypeDocumentDocumentAnonymDto {
  /**
   * The name of the Identification document type as presented within the frontend to customers, e.g.
   * 'ausländischer Reisepass'
   * - minLength: 1
   * - maxLength: 80
   */
  name: string;
  /**
   * The technical ENUM used within Ecobill services for this identification document type
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  documentType: string;
  /**
   * An optional category used for display grouping in frontends
   * - minLength: 0
   * - maxLength: 80
   */
  category?: string;
}


/**
 * IdentificationDocumentsTypeDocumentDocumentAnonymDto
 *  see IIdentificationDocumentsTypeDocumentDocumentAnonymDto
 */
export class IdentificationDocumentsTypeDocumentDocumentAnonymDto {
  /**
   * The name of the Identification document type as presented within the frontend to customers, e.g.
   * 'ausländischer Reisepass'
   * - minLength: 1
   * - maxLength: 80
   */
  readonly name: string;
  /**
   * The technical ENUM used within Ecobill services for this identification document type
   * Enum, one of:
   * - GERMAN_ID_CARD
   * - TEMPORARY_ID_CARD
   * - REPLACEMENT_ID_CARD
   * - GERMAN_PASSPORT
   * - TEMPORARY_PASSPORT
   * - FOREIGN_ID_CARD
   * - FOREIGN_PASSPORT
   * - VISA
   * - BLUE_CARD_EU
   * - UNLIMITED_SETTLEMENT_PERMIT
   * - REGISTRATION_FOR_ASYLUM
   * - TEMPORARY_RESIDENCE_PERMIT
   * - SUSPENSION_OF_DEPORTATION
   * - RESIDENCE_PERMIT
   * - PERMANENT_EC_RESIDENCE
   * - OFFICIAL_PASSPORT
   * - TEMPORARY_OFFICIAL_PASSPORT
   * - DIPLOMATIC_PASSPORT
   * - TEMPORARY_DIPLOMATIC_PASSPORT
   * - TP
   * - ID
   * - AR
   * - AS
   * - AF
   */
  readonly documentType: string;
  /**
   * An optional category used for display grouping in frontends
   * - minLength: 0
   * - maxLength: 80
   */
  readonly category?: string;

  constructor(options: {
    name: string;
    documentType: string;
    category?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       null;
    this.category =
      (options !== undefined && options !== null && options.category !== undefined && options.category !== null) ?
        options.category + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentificationDocumentsTypeDto
 */
export interface IIdentificationDocumentsTypeDto {
  /**
   * - minItems: 1
   */
  document?: IIdentificationDocumentsTypeDocumentDocumentAnonymDto[];
}


/**
 * IdentificationDocumentsTypeDto
 *  see IIdentificationDocumentsTypeDto
 */
export class IdentificationDocumentsTypeDto {
  /**
   * - minItems: 1
   */
  readonly document?: IdentificationDocumentsTypeDocumentDocumentAnonymDto[];

  constructor(options: {
    document?: IdentificationDocumentsTypeDocumentDocumentAnonymDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.document = new Array<IdentificationDocumentsTypeDocumentDocumentAnonymDto>();
    if (options !== undefined && options !== null && options.document !== undefined && options.document !== null) {
      for (const item of options.document) {
        this.document.push(new IdentificationDocumentsTypeDocumentDocumentAnonymDto(item, config));
      }
    }
  }
}


/**
 * IIdentificationDocument3TypeDto
 */
export interface IIdentificationDocument3TypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  countryCode2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  stdName?: string;
  sort: number;
  /**
   * Country is part of EU
   */
  EU: boolean;
  documents?: IIdentificationDocumentsTypeDto;
}


/**
 * IdentificationDocument3TypeDto
 *  see IIdentificationDocument3TypeDto
 */
export class IdentificationDocument3TypeDto {
  /**
   * ISO 3166-1 Alpha 3
   */
  readonly countryCode3: string;
  /**
   * ISO 3166-1 Alpha 2
   */
  readonly countryCode2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly stdName?: string;
  readonly sort: number;
  /**
   * Country is part of EU
   */
  readonly EU: boolean;
  readonly documents?: IdentificationDocumentsTypeDto;

  constructor(options: {
    countryCode3: string;
    countryCode2?: string;
    name?: string;
    stdName?: string;
    sort: number;
    EU: boolean;
    documents?: IdentificationDocumentsTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.countryCode3 =
      (options !== undefined && options !== null && options.countryCode3 !== undefined && options.countryCode3 !== null) ?
        options.countryCode3 + '' :
       null;
    this.countryCode2 =
      (options !== undefined && options !== null && options.countryCode2 !== undefined && options.countryCode2 !== null) ?
        options.countryCode2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       (config.viewModel === true) ? null : undefined;
    this.stdName =
      (options !== undefined && options !== null && options.stdName !== undefined && options.stdName !== null) ?
        options.stdName + '' :
       (config.viewModel === true) ? null : undefined;
    this.sort =
      (options !== undefined && options !== null && options.sort !== undefined && options.sort !== null) ?
        Number(options.sort) :
       null;
    this.EU = (options !== undefined && options !== null && options.EU !== undefined && options.EU !== null) ?
        !!options.EU :
       null;
    this.documents =
      (options !== undefined && options !== null && options.documents !== undefined && options.documents !== null) ?
        new IdentificationDocumentsTypeDto(options.documents, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentificationDocumentsTypeDto( null, config) : null ) : (config.hierarchy === true ? new IdentificationDocumentsTypeDto( null, config) : undefined );
  }
}


/**
 * IIdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export interface IIdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  identificationDocument?: IIdentificationDocument3TypeDto[];
}


/**
 * IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 *  see IIdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto
 */
export class IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly identificationDocument?: IdentificationDocument3TypeDto[];

  constructor(options: {
    identificationDocument?: IdentificationDocument3TypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.identificationDocument = new Array<IdentificationDocument3TypeDto>();
    if (options !== undefined && options !== null && options.identificationDocument !== undefined && options.identificationDocument !== null) {
      for (const item of options.identificationDocument) {
        this.identificationDocument.push(new IdentificationDocument3TypeDto(item, config));
      }
    }
  }
}


/**
 * IIdentListDocumentType3ResponseDto
 */
export interface IIdentListDocumentType3ResponseDto {
  result: IDefaultReturnTypeDto;
  identificationDocuments?: IIdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
}


/**
 * IdentListDocumentType3ResponseDto
 *  see IIdentListDocumentType3ResponseDto
 */
export class IdentListDocumentType3ResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly identificationDocuments?: IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    identificationDocuments?: IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.identificationDocuments =
      (options !== undefined && options !== null && options.identificationDocuments !== undefined && options.identificationDocuments !== null) ?
        new IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto(options.identificationDocuments, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new IdentListDocumentType3ResponseIdentificationDocumentsIdentificationDocumentsAnonymDto( null, config) : undefined );
  }
}


/**
 * IIdentificationConfirmRequestProcessRefProcessRefAnonymDto
 */
export interface IIdentificationConfirmRequestProcessRefProcessRefAnonymDto {
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  puk2?: string;
}


/**
 * IdentificationConfirmRequestProcessRefProcessRefAnonymDto
 *  see IIdentificationConfirmRequestProcessRefProcessRefAnonymDto
 */
export class IdentificationConfirmRequestProcessRefProcessRefAnonymDto {
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2?: string;

  constructor(options: {
    sim: string;
    msisdn?: string;
    puk2?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIdentificationConfirmRequestDto
 */
export interface IIdentificationConfirmRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  processRef: IIdentificationConfirmRequestProcessRefProcessRefAnonymDto;
}


/**
 * IdentificationConfirmRequestDto
 *  see IIdentificationConfirmRequestDto
 */
export class IdentificationConfirmRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  readonly processRef: IdentificationConfirmRequestProcessRefProcessRefAnonymDto;

  constructor(options: {
    sessionKey?: string;
    processRef: IdentificationConfirmRequestProcessRefProcessRefAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.processRef =
      (options !== undefined && options !== null && options.processRef !== undefined && options.processRef !== null) ?
        new IdentificationConfirmRequestProcessRefProcessRefAnonymDto(options.processRef, config) :
        null;
  }
}


/**
 * IIdentificationConfirmResponseDto
 */
export interface IIdentificationConfirmResponseDto {
  result: IDefaultReturnTypeDto;
}


/**
 * IdentificationConfirmResponseDto
 *  see IIdentificationConfirmResponseDto
 */
export class IdentificationConfirmResponseDto {
  readonly result: DefaultReturnTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
  }
}


/**
 * IMnpListServiceProviderRequestDto
 */
export interface IMnpListServiceProviderRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
}


/**
 * MnpListServiceProviderRequestDto
 *  see IMnpListServiceProviderRequestDto
 */
export class MnpListServiceProviderRequestDto {
  /**
   * The session key obtained on login.
   * It need to be provided as a mandatory parameter for all subsequent calls in the session.
   */
  readonly sessionKey?: string;
  /**
   * An optional transaction Reference may be provided by the interface user for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IMnpServiceProviderTypeDto
 */
export interface IMnpServiceProviderTypeDto {
  serviceProviderID: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  providerKey?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  companyName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  networks?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  admd?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  commonName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  surname?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  organization?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  orgunit1?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  prmd?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  country?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  orgunit2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  givenName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  shortKey?: string;
}


/**
 * MnpServiceProviderTypeDto
 *  see IMnpServiceProviderTypeDto
 */
export class MnpServiceProviderTypeDto {
  readonly serviceProviderID: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly providerKey?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly name?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly companyName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly networks?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly admd?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly commonName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly surname?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly organization?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly orgunit1?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly prmd?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly country?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly orgunit2?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly givenName?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly shortKey?: string;

  constructor(options: {
    serviceProviderID: number;
    providerKey?: string;
    name?: string;
    companyName?: string;
    networks?: string;
    admd?: string;
    commonName?: string;
    surname?: string;
    organization?: string;
    orgunit1?: string;
    prmd?: string;
    country?: string;
    orgunit2?: string;
    givenName?: string;
    shortKey?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.serviceProviderID =
      (options !== undefined && options !== null && options.serviceProviderID !== undefined && options.serviceProviderID !== null) ?
        Number(options.serviceProviderID) :
       null;
    this.providerKey =
      (options !== undefined && options !== null && options.providerKey !== undefined && options.providerKey !== null) ?
        options.providerKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       (config.viewModel === true) ? null : undefined;
    this.companyName =
      (options !== undefined && options !== null && options.companyName !== undefined && options.companyName !== null) ?
        options.companyName + '' :
       (config.viewModel === true) ? null : undefined;
    this.networks =
      (options !== undefined && options !== null && options.networks !== undefined && options.networks !== null) ?
        options.networks + '' :
       (config.viewModel === true) ? null : undefined;
    this.admd =
      (options !== undefined && options !== null && options.admd !== undefined && options.admd !== null) ?
        options.admd + '' :
       (config.viewModel === true) ? null : undefined;
    this.commonName =
      (options !== undefined && options !== null && options.commonName !== undefined && options.commonName !== null) ?
        options.commonName + '' :
       (config.viewModel === true) ? null : undefined;
    this.surname =
      (options !== undefined && options !== null && options.surname !== undefined && options.surname !== null) ?
        options.surname + '' :
       (config.viewModel === true) ? null : undefined;
    this.organization =
      (options !== undefined && options !== null && options.organization !== undefined && options.organization !== null) ?
        options.organization + '' :
       (config.viewModel === true) ? null : undefined;
    this.orgunit1 =
      (options !== undefined && options !== null && options.orgunit1 !== undefined && options.orgunit1 !== null) ?
        options.orgunit1 + '' :
       (config.viewModel === true) ? null : undefined;
    this.prmd =
      (options !== undefined && options !== null && options.prmd !== undefined && options.prmd !== null) ?
        options.prmd + '' :
       (config.viewModel === true) ? null : undefined;
    this.country =
      (options !== undefined && options !== null && options.country !== undefined && options.country !== null) ?
        options.country + '' :
       (config.viewModel === true) ? null : undefined;
    this.orgunit2 =
      (options !== undefined && options !== null && options.orgunit2 !== undefined && options.orgunit2 !== null) ?
        options.orgunit2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.givenName =
      (options !== undefined && options !== null && options.givenName !== undefined && options.givenName !== null) ?
        options.givenName + '' :
       (config.viewModel === true) ? null : undefined;
    this.shortKey =
      (options !== undefined && options !== null && options.shortKey !== undefined && options.shortKey !== null) ?
        options.shortKey + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IMnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto
 */
export interface IMnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto {
  /**
   * - minItems: 1
   */
  mnpServiceProvider?: IMnpServiceProviderTypeDto[];
}


/**
 * MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto
 *  see IMnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto
 */
export class MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto {
  /**
   * - minItems: 1
   */
  readonly mnpServiceProvider?: MnpServiceProviderTypeDto[];

  constructor(options: {
    mnpServiceProvider?: MnpServiceProviderTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.mnpServiceProvider = new Array<MnpServiceProviderTypeDto>();
    if (options !== undefined && options !== null && options.mnpServiceProvider !== undefined && options.mnpServiceProvider !== null) {
      for (const item of options.mnpServiceProvider) {
        this.mnpServiceProvider.push(new MnpServiceProviderTypeDto(item, config));
      }
    }
  }
}


/**
 * IMnpListServiceProviderResponseDto
 */
export interface IMnpListServiceProviderResponseDto {
  result: IDefaultReturnTypeDto;
  mnpServiceProviderList?: IMnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto;
}


/**
 * MnpListServiceProviderResponseDto
 *  see IMnpListServiceProviderResponseDto
 */
export class MnpListServiceProviderResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly mnpServiceProviderList?: MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    mnpServiceProviderList?: MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.mnpServiceProviderList =
      (options !== undefined && options !== null && options.mnpServiceProviderList !== undefined && options.mnpServiceProviderList !== null) ?
        new MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto(options.mnpServiceProviderList, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto( null, config) : null ) : (config.hierarchy === true ? new MnpListServiceProviderResponseMnpServiceProviderListMnpServiceProviderListAnonymDto( null, config) : undefined );
  }
}


/**
 * IEcobillReferenceTypeDto
 */
export interface IEcobillReferenceTypeDto {
  ecobillRef: string;
  /**
   * Enum, one of:
   * - SUBSCRIPTION
   * - CUSTOMER
   * - SALESPARTNER
   * - SIM
   * - EQUIPMENT
   */
  ecobillEntity: string;
}


/**
 * EcobillReferenceTypeDto
 *  see IEcobillReferenceTypeDto
 */
export class EcobillReferenceTypeDto {
  readonly ecobillRef: string;
  /**
   * Enum, one of:
   * - SUBSCRIPTION
   * - CUSTOMER
   * - SALESPARTNER
   * - SIM
   * - EQUIPMENT
   */
  readonly ecobillEntity: string;

  constructor(options: {
    ecobillRef: string;
    ecobillEntity: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.ecobillRef =
      (options !== undefined && options !== null && options.ecobillRef !== undefined && options.ecobillRef !== null) ?
        options.ecobillRef + '' :
       null;
    this.ecobillEntity =
      (options !== undefined && options !== null && options.ecobillEntity !== undefined && options.ecobillEntity !== null) ?
        options.ecobillEntity + '' :
       null;
  }
}


/**
 * IContactHistoryRequestTypeDto
 */
export interface IContactHistoryRequestTypeDto {
  ecobillRef: IEcobillReferenceTypeDto;
  /**
   * The type of contact, e.g.
   * CUSTOMER_INBOUND_PHONE.
   */
  contactType: object;
  /**
   * Optional subject for contact history rtnsrd.
   * - minLength: 0
   * - maxLength: 120
   */
  subject: string;
  /**
   * Details for the contact history rtnsrd.
   * - minLength: 0
   * - maxLength: 4096
   */
  details: string;
  /**
   * Optional internal comment, e.g.
   * reference information to promotion when contactType == CUSTOMER_OUTBOUND_PROMOTION.
   * - minLength: 0
   * - maxLength: 80
   */
  additionalInfo?: string;
}


/**
 * ContactHistoryRequestTypeDto
 *  see IContactHistoryRequestTypeDto
 */
export class ContactHistoryRequestTypeDto {
  readonly ecobillRef: EcobillReferenceTypeDto;
  /**
   * The type of contact, e.g.
   * CUSTOMER_INBOUND_PHONE.
   */
  readonly contactType: object;
  /**
   * Optional subject for contact history rtnsrd.
   * - minLength: 0
   * - maxLength: 120
   */
  readonly subject: string;
  /**
   * Details for the contact history rtnsrd.
   * - minLength: 0
   * - maxLength: 4096
   */
  readonly details: string;
  /**
   * Optional internal comment, e.g.
   * reference information to promotion when contactType == CUSTOMER_OUTBOUND_PROMOTION.
   * - minLength: 0
   * - maxLength: 80
   */
  readonly additionalInfo?: string;

  constructor(options: {
    ecobillRef: EcobillReferenceTypeDto;
    contactType: object;
    subject: string;
    details: string;
    additionalInfo?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.ecobillRef =
      (options !== undefined && options !== null && options.ecobillRef !== undefined && options.ecobillRef !== null) ?
        new EcobillReferenceTypeDto(options.ecobillRef, config) :
        null;
    this.contactType =
      (options !== undefined && options !== null && options.contactType !== undefined && options.contactType !== null) ?
        new Object(options.contactType) :
        null;
    this.subject =
      (options !== undefined && options !== null && options.subject !== undefined && options.subject !== null) ?
        options.subject + '' :
       null;
    this.details =
      (options !== undefined && options !== null && options.details !== undefined && options.details !== null) ?
        options.details + '' :
       null;
    this.additionalInfo =
      (options !== undefined && options !== null && options.additionalInfo !== undefined && options.additionalInfo !== null) ?
        options.additionalInfo + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IResetProcessRequestDto
 */
export interface IResetProcessRequestDto {
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Depending on context PUK2 might be required
   * - minLength: 0
   * - maxLength: 20
   */
  puk2?: string;
  /**
   * Must be explicit set to true to sent email to cusotmer.
   * There are different templates depending on action.
   */
  sendCustomerEmail?: boolean;
  /**
   * Reset will allow to restart the identification process, Terminate blocks any further activity
   * Enum, one of:
   * - RESET
   * - TERMINATE
   */
  action: string;
  /**
   * IPv4 or IPv6 of device
   * - minLength: 7
   * - maxLength: 64
   */
  ip?: string;
  contractHistory?: IContactHistoryRequestTypeDto;
}


/**
 * ResetProcessRequestDto
 *  see IResetProcessRequestDto
 */
export class ResetProcessRequestDto {
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Depending on context PUK2 might be required
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2?: string;
  /**
   * Must be explicit set to true to sent email to cusotmer.
   * There are different templates depending on action.
   */
  readonly sendCustomerEmail?: boolean;
  /**
   * Reset will allow to restart the identification process, Terminate blocks any further activity
   * Enum, one of:
   * - RESET
   * - TERMINATE
   */
  readonly action: string;
  /**
   * IPv4 or IPv6 of device
   * - minLength: 7
   * - maxLength: 64
   */
  readonly ip?: string;
  readonly contractHistory?: ContactHistoryRequestTypeDto;

  constructor(options: {
    sessionKey?: string;
    sim: string;
    puk2?: string;
    sendCustomerEmail?: boolean;
    action: string;
    ip?: string;
    contractHistory?: ContactHistoryRequestTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.sendCustomerEmail = (options !== undefined && options !== null && options.sendCustomerEmail !== undefined && options.sendCustomerEmail !== null) ?
        !!options.sendCustomerEmail :
       (config.viewModel === true) ? null : undefined;
    this.action =
      (options !== undefined && options !== null && options.action !== undefined && options.action !== null) ?
        options.action + '' :
       null;
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
    this.contractHistory =
      (options !== undefined && options !== null && options.contractHistory !== undefined && options.contractHistory !== null) ?
        new ContactHistoryRequestTypeDto(options.contractHistory, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ContactHistoryRequestTypeDto( null, config) : null ) : (config.hierarchy === true ? new ContactHistoryRequestTypeDto( null, config) : undefined );
  }
}


/**
 * IResetProcessResponseDto
 */
export interface IResetProcessResponseDto {
  result: IDefaultReturnTypeDto;
}


/**
 * ResetProcessResponseDto
 *  see IResetProcessResponseDto
 */
export class ResetProcessResponseDto {
  readonly result: DefaultReturnTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
  }
}


/**
 * IResourceGetSim2RequestDto
 */
export interface IResourceGetSim2RequestDto {
  sessionKey?: string;
  /**
   * Type for external references such as customerRef.
   * External reference typed elements must be unique in the particular entity.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  puk2?: string;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  ip?: string;
}


/**
 * ResourceGetSim2RequestDto
 *  see IResourceGetSim2RequestDto
 */
export class ResourceGetSim2RequestDto {
  readonly sessionKey?: string;
  /**
   * Type for external references such as customerRef.
   * External reference typed elements must be unique in the particular entity.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Type for MSISDN (phone number for mobile subscription) data in international format.
   * For example 491778956862.
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Type for SIM PUK information.
   * - minLength: 0
   * - maxLength: 20
   */
  readonly puk2?: string;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  readonly ip?: string;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
    sim: string;
    msisdn?: string;
    puk2?: string;
    ip?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.puk2 =
      (options !== undefined && options !== null && options.puk2 !== undefined && options.puk2 !== null) ?
        options.puk2 + '' :
       (config.viewModel === true) ? null : undefined;
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IResourceGetSim2ResponseDto
 */
export interface IResourceGetSim2ResponseDto {
  result: IDefaultReturnTypeDto;
  resource?: IResourceGetSim2ResponseResourceResourceAnonymDto;
  /**
   * status of identification process, only available if result/code=0
   * Enum, one of:
   * - UNDEFINED
   * - INITIAL
   * - REG
   * - READY_FOR_ACTIVATION
   * - IDENT_ORDERED
   * - COMPLETE
   * - TERMINATED
   */
  status?: string;
  /**
   * Status details collected from history information
   * - minLength: 0
   * - maxLength: 80
   */
  subStatus?: string;
  /**
   * Whether the fraud-indicator flag has been set
   */
  fraudIndicator?: boolean;
  customer?: IResourceGetSim2ResponseCustomerCustomerMergedDto;
}


/**
 * ResourceGetSim2ResponseDto
 *  see IResourceGetSim2ResponseDto
 */
export class ResourceGetSim2ResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly resource?: ResourceGetSim2ResponseResourceResourceAnonymDto;
  /**
   * status of identification process, only available if result/code=0
   * Enum, one of:
   * - UNDEFINED
   * - INITIAL
   * - REG
   * - READY_FOR_ACTIVATION
   * - IDENT_ORDERED
   * - COMPLETE
   * - TERMINATED
   */
  readonly status?: string;
  /**
   * Status details collected from history information
   * - minLength: 0
   * - maxLength: 80
   */
  readonly subStatus?: string;
  /**
   * Whether the fraud-indicator flag has been set
   */
  readonly fraudIndicator?: boolean;
  readonly customer?: ResourceGetSim2ResponseCustomerCustomerMergedDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    resource?: ResourceGetSim2ResponseResourceResourceAnonymDto;
    status?: string;
    subStatus?: string;
    fraudIndicator?: boolean;
    customer?: ResourceGetSim2ResponseCustomerCustomerMergedDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.resource =
      (options !== undefined && options !== null && options.resource !== undefined && options.resource !== null) ?
        new ResourceGetSim2ResponseResourceResourceAnonymDto(options.resource, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseResourceResourceAnonymDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseResourceResourceAnonymDto( null, config) : undefined );
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       (config.viewModel === true) ? null : undefined;
    this.subStatus =
      (options !== undefined && options !== null && options.subStatus !== undefined && options.subStatus !== null) ?
        options.subStatus + '' :
       (config.viewModel === true) ? null : undefined;
    this.fraudIndicator = (options !== undefined && options !== null && options.fraudIndicator !== undefined && options.fraudIndicator !== null) ?
        !!options.fraudIndicator :
       (config.viewModel === true) ? null : undefined;
    this.customer =
      (options !== undefined && options !== null && options.customer !== undefined && options.customer !== null) ?
        new ResourceGetSim2ResponseCustomerCustomerMergedDto(options.customer, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerCustomerMergedDto( null, config) : null ) : (config.hierarchy === true ? new ResourceGetSim2ResponseCustomerCustomerMergedDto( null, config) : undefined );
  }
}


/**
 * ISubscriptionReferenceTypeExternalRefExternalRefAnonymDto
 */
export interface ISubscriptionReferenceTypeExternalRefExternalRefAnonymDto {
  /**
   * Tenant scope for the subscription.
   */
  tenant: number;
  /**
   * Subscription reference from client system for the subscription to look up.
   * No wildcards supported.
   * - minLength: 0
   * - maxLength: 45
   */
  subscriptionRef: string;
}


/**
 * SubscriptionReferenceTypeExternalRefExternalRefAnonymDto
 *  see ISubscriptionReferenceTypeExternalRefExternalRefAnonymDto
 */
export class SubscriptionReferenceTypeExternalRefExternalRefAnonymDto {
  /**
   * Tenant scope for the subscription.
   */
  readonly tenant: number;
  /**
   * Subscription reference from client system for the subscription to look up.
   * No wildcards supported.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly subscriptionRef: string;

  constructor(options: {
    tenant: number;
    subscriptionRef: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tenant =
      (options !== undefined && options !== null && options.tenant !== undefined && options.tenant !== null) ?
        Number(options.tenant) :
       null;
    this.subscriptionRef =
      (options !== undefined && options !== null && options.subscriptionRef !== undefined && options.subscriptionRef !== null) ?
        options.subscriptionRef + '' :
       null;
  }
}


/**
 * ISubscriptionReferenceTypeDto
 */
export interface ISubscriptionReferenceTypeDto {
  /**
   * Subscription ID for the subscription to look up.
   * No wildcards supported.
   */
  id?: number;
  externalRef?: ISubscriptionReferenceTypeExternalRefExternalRefAnonymDto;
}


/**
 * SubscriptionReferenceTypeDto
 *  see ISubscriptionReferenceTypeDto
 */
export class SubscriptionReferenceTypeDto {
  /**
   * Subscription ID for the subscription to look up.
   * No wildcards supported.
   */
  readonly id?: number;
  readonly externalRef?: SubscriptionReferenceTypeExternalRefExternalRefAnonymDto;

  constructor(options: {
    id?: number;
    externalRef?: SubscriptionReferenceTypeExternalRefExternalRefAnonymDto;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       (config.viewModel === true) ? null : undefined;
    this.externalRef =
      (options !== undefined && options !== null && options.externalRef !== undefined && options.externalRef !== null) ?
        new SubscriptionReferenceTypeExternalRefExternalRefAnonymDto(options.externalRef, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionReferenceTypeExternalRefExternalRefAnonymDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionReferenceTypeExternalRefExternalRefAnonymDto( null, config) : undefined );
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto
 */
export interface ISubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  option?: IOptionTypeDto[];
}


/**
 * SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto
 *  see ISubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto
 */
export class SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly option?: OptionTypeDto[];

  constructor(options: {
    option?: OptionTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.option = new Array<OptionTypeDto>();
    if (options !== undefined && options !== null && options.option !== undefined && options.option !== null) {
      for (const item of options.option) {
        this.option.push(new OptionTypeDto(item, config));
      }
    }
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3RequestDto
 */
export interface ISubscriptionGetCompactTariffOptions3RequestDto {
  sessionKey?: string;
  /**
   * Type for external references such as customerRef.
   * External reference typed elements must be unique in the particular entity.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  subscriptionReference?: ISubscriptionReferenceTypeDto;
  /**
   * Unique mvneco tariff code.
   * Provide this element for non existing subscription
   * - minLength: 0
   * - maxLength: 16
   */
  tariffCode?: string;
  /**
   * Unique mvneco ID.
   * Use this element as alternative to subscriptionReference for existing subscription.
   * Must be in state 'IN_USE' or 'PAIRED'
   * - minLength: 12
   * - maxLength: 13
   */
  msisdn?: string;
  /**
   * Unique mvneco ID.
   * Use this element as alternative to subscriptionReference for existing subscription.
   * Must be in state 'IN_USE', 'PAIRED' or 'INITIAL'
   * - minLength: 12
   * - maxLength: 20
   */
  sim?: string;
  options?: ISubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto;
}


/**
 * SubscriptionGetCompactTariffOptions3RequestDto
 *  see ISubscriptionGetCompactTariffOptions3RequestDto
 */
export class SubscriptionGetCompactTariffOptions3RequestDto {
  readonly sessionKey?: string;
  /**
   * Type for external references such as customerRef.
   * External reference typed elements must be unique in the particular entity.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  readonly subscriptionReference?: SubscriptionReferenceTypeDto;
  /**
   * Unique mvneco tariff code.
   * Provide this element for non existing subscription
   * - minLength: 0
   * - maxLength: 16
   */
  readonly tariffCode?: string;
  /**
   * Unique mvneco ID.
   * Use this element as alternative to subscriptionReference for existing subscription.
   * Must be in state 'IN_USE' or 'PAIRED'
   * - minLength: 12
   * - maxLength: 13
   */
  readonly msisdn?: string;
  /**
   * Unique mvneco ID.
   * Use this element as alternative to subscriptionReference for existing subscription.
   * Must be in state 'IN_USE', 'PAIRED' or 'INITIAL'
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim?: string;
  readonly options?: SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
    subscriptionReference?: SubscriptionReferenceTypeDto;
    tariffCode?: string;
    msisdn?: string;
    sim?: string;
    options?: SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.subscriptionReference =
      (options !== undefined && options !== null && options.subscriptionReference !== undefined && options.subscriptionReference !== null) ?
        new SubscriptionReferenceTypeDto(options.subscriptionReference, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionReferenceTypeDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionReferenceTypeDto( null, config) : undefined );
    this.tariffCode =
      (options !== undefined && options !== null && options.tariffCode !== undefined && options.tariffCode !== null) ?
        options.tariffCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.msisdn =
      (options !== undefined && options !== null && options.msisdn !== undefined && options.msisdn !== null) ?
        options.msisdn + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       (config.viewModel === true) ? null : undefined;
    this.options =
      (options !== undefined && options !== null && options.options !== undefined && options.options !== null) ?
        new SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto(options.options, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3RequestOptionsOptionsAnonymDto( null, config) : undefined );
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto
 */
export interface ISubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto {
  id: number;
  tenantId: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  code: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  description: string;
  period?: IPeriodTypeDto;
}


/**
 * SubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto
 *  see ISubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto
 */
export class SubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto {
  readonly id: number;
  readonly tenantId: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 45
   */
  readonly name: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 16
   */
  readonly code: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description: string;
  readonly period?: PeriodTypeDto;

  constructor(options: {
    id: number;
    tenantId: number;
    name: string;
    code: string;
    description: string;
    period?: PeriodTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.tenantId =
      (options !== undefined && options !== null && options.tenantId !== undefined && options.tenantId !== null) ?
        Number(options.tenantId) :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        options.code + '' :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       null;
    this.period =
      (options !== undefined && options !== null && options.period !== undefined && options.period !== null) ?
        new PeriodTypeDto(options.period, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto
 */
export interface ISubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto {
  id: number;
  tariffId: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  productRef: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  name: string;
  infotext?: string;
  promotiontext?: string;
  tariff: ISubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto;
}


/**
 * SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto
 *  see ISubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto
 */
export class SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto {
  readonly id: number;
  readonly tariffId: number;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly productRef: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly name: string;
  readonly infotext?: string;
  readonly promotiontext?: string;
  readonly tariff: SubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto;

  constructor(options: {
    id: number;
    tariffId: number;
    productRef: string;
    name: string;
    infotext?: string;
    promotiontext?: string;
    tariff: SubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.tariffId =
      (options !== undefined && options !== null && options.tariffId !== undefined && options.tariffId !== null) ?
        Number(options.tariffId) :
       null;
    this.productRef =
      (options !== undefined && options !== null && options.productRef !== undefined && options.productRef !== null) ?
        options.productRef + '' :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.infotext =
      (options !== undefined && options !== null && options.infotext !== undefined && options.infotext !== null) ?
        options.infotext + '' :
       (config.viewModel === true) ? null : undefined;
    this.promotiontext =
      (options !== undefined && options !== null && options.promotiontext !== undefined && options.promotiontext !== null) ?
        options.promotiontext + '' :
       (config.viewModel === true) ? null : undefined;
    this.tariff =
      (options !== undefined && options !== null && options.tariff !== undefined && options.tariff !== null) ?
        new SubscriptionGetCompactTariffOptions3ResponseProductBundleTariffTariffAnonymDto(options.tariff, config) :
        null;
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto
 */
export interface ISubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto {
  /**
   * - minItems: 0
   */
  compactTariffOption?: ICompactTariffOption3TypeDto[];
}


/**
 * SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto
 *  see ISubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto
 */
export class SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto {
  /**
   * - minItems: 0
   */
  readonly compactTariffOption?: CompactTariffOption3TypeDto[];

  constructor(options: {
    compactTariffOption?: CompactTariffOption3TypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.compactTariffOption = new Array<CompactTariffOption3TypeDto>();
    if (options !== undefined && options !== null && options.compactTariffOption !== undefined && options.compactTariffOption !== null) {
      for (const item of options.compactTariffOption) {
        this.compactTariffOption.push(new CompactTariffOption3TypeDto(item, config));
      }
    }
  }
}


/**
 * IUsageDto
 */
export interface IUsageDto {
  ledgerType?: string;
  value?: string;
}


/**
 * UsageDto
 *  see IUsageDto
 */
export class UsageDto {
  readonly ledgerType?: string;
  readonly value?: string;

  constructor(options: {
    ledgerType?: string;
    value?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.ledgerType =
      (options !== undefined && options !== null && options.ledgerType !== undefined && options.ledgerType !== null) ?
        options.ledgerType + '' :
       (config.viewModel === true) ? null : undefined;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IBalanceSummaryDto
 */
export interface IBalanceSummaryDto {
  /**
   * - minItems: 0
   */
  usage?: IUsageDto[];
}


/**
 * BalanceSummaryDto
 *  see IBalanceSummaryDto
 */
export class BalanceSummaryDto {
  /**
   * - minItems: 0
   */
  readonly usage?: UsageDto[];

  constructor(options: {
    usage?: UsageDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.usage = new Array<UsageDto>();
    if (options !== undefined && options !== null && options.usage !== undefined && options.usage !== null) {
      for (const item of options.usage) {
        this.usage.push(new UsageDto(item, config));
      }
    }
  }
}


/**
 * ILockStatusTypeDto
 */
export interface ILockStatusTypeDto {
  /**
   * LockKey is the entity/level which is affected by the lock.
   * If SUBSCRIPTION no new process/action can be started.
   * Enum, one of:
   * - SUBSCRIPTION
   * - SIM
   * - OPTIONGROUP
   * - OPTION
   */
  lockKey: string;
  lastStatusChange: string;
  /**
   * internal name of process which set the lock
   * - minLength: 1
   * - maxLength: 80
   */
  lockingProcess: string;
}


/**
 * LockStatusTypeDto
 *  see ILockStatusTypeDto
 */
export class LockStatusTypeDto {
  /**
   * LockKey is the entity/level which is affected by the lock.
   * If SUBSCRIPTION no new process/action can be started.
   * Enum, one of:
   * - SUBSCRIPTION
   * - SIM
   * - OPTIONGROUP
   * - OPTION
   */
  readonly lockKey: string;
  readonly lastStatusChange: string;
  /**
   * internal name of process which set the lock
   * - minLength: 1
   * - maxLength: 80
   */
  readonly lockingProcess: string;

  constructor(options: {
    lockKey: string;
    lastStatusChange: string;
    lockingProcess: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.lockKey =
      (options !== undefined && options !== null && options.lockKey !== undefined && options.lockKey !== null) ?
        options.lockKey + '' :
       null;
    this.lastStatusChange =
      (options !== undefined && options !== null && options.lastStatusChange !== undefined && options.lastStatusChange !== null) ?
        options.lastStatusChange + '' :
       null;
    this.lockingProcess =
      (options !== undefined && options !== null && options.lockingProcess !== undefined && options.lockingProcess !== null) ?
        options.lockingProcess + '' :
       null;
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto
 */
export interface ISubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto {
  /**
   * - minItems: 1
   */
  lockStatus?: ILockStatusTypeDto[];
}


/**
 * SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto
 *  see ISubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto
 */
export class SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto {
  /**
   * - minItems: 1
   */
  readonly lockStatus?: LockStatusTypeDto[];

  constructor(options: {
    lockStatus?: LockStatusTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.lockStatus = new Array<LockStatusTypeDto>();
    if (options !== undefined && options !== null && options.lockStatus !== undefined && options.lockStatus !== null) {
      for (const item of options.lockStatus) {
        this.lockStatus.push(new LockStatusTypeDto(item, config));
      }
    }
  }
}


/**
 * ISubscriptionGetCompactTariffOptions3ResponseDto
 */
export interface ISubscriptionGetCompactTariffOptions3ResponseDto {
  result: IDefaultReturnTypeDto;
  productBundle?: ISubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto;
  compactTariffOptions?: ISubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto;
  currentBalance?: IBalanceSummaryDto;
  lockStatus?: ISubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto;
}


/**
 * SubscriptionGetCompactTariffOptions3ResponseDto
 *  see ISubscriptionGetCompactTariffOptions3ResponseDto
 */
export class SubscriptionGetCompactTariffOptions3ResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly productBundle?: SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto;
  readonly compactTariffOptions?: SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto;
  readonly currentBalance?: BalanceSummaryDto;
  readonly lockStatus?: SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    productBundle?: SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto;
    compactTariffOptions?: SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto;
    currentBalance?: BalanceSummaryDto;
    lockStatus?: SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.productBundle =
      (options !== undefined && options !== null && options.productBundle !== undefined && options.productBundle !== null) ?
        new SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto(options.productBundle, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseProductBundleProductBundleAnonymDto( null, config) : undefined );
    this.compactTariffOptions =
      (options !== undefined && options !== null && options.compactTariffOptions !== undefined && options.compactTariffOptions !== null) ?
        new SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto(options.compactTariffOptions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseCompactTariffOptionsCompactTariffOptionsAnonymDto( null, config) : undefined );
    this.currentBalance =
      (options !== undefined && options !== null && options.currentBalance !== undefined && options.currentBalance !== null) ?
        new BalanceSummaryDto(options.currentBalance, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new BalanceSummaryDto( null, config) : null ) : (config.hierarchy === true ? new BalanceSummaryDto( null, config) : undefined );
    this.lockStatus =
      (options !== undefined && options !== null && options.lockStatus !== undefined && options.lockStatus !== null) ?
        new SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto(options.lockStatus, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto( null, config) : null ) : (config.hierarchy === true ? new SubscriptionGetCompactTariffOptions3ResponseLockStatusLockStatusAnonymDto( null, config) : undefined );
  }
}


/**
 * ITenantListTenantRequestDto
 */
export interface ITenantListTenantRequestDto {
  /**
   * The session key obtained on login.
   * It need to be 							provided as a mandatory parameter for all subsequent calls in the 							session.
   */
  sessionKey?: string;
  /**
   * An optional transaction Reference may be 							provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
}


/**
 * TenantListTenantRequestDto
 *  see ITenantListTenantRequestDto
 */
export class TenantListTenantRequestDto {
  /**
   * The session key obtained on login.
   * It need to be 							provided as a mandatory parameter for all subsequent calls in the 							session.
   */
  readonly sessionKey?: string;
  /**
   * An optional transaction Reference may be 							provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;

  constructor(options: {
    sessionKey?: string;
    transactionRef?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ITenantDataTypeDto
 */
export interface ITenantDataTypeDto {
  /**
   * The unique tenant id.
   * In the database this is named the surrogate for the tenant.
   */
  id: number;
  /**
   * Optional external reference, e.g.
   * client tenant number.
   * Shall be unique.
   * - minLength: 0
   * - maxLength: 45
   */
  tenantRef?: string;
  /**
   * The unique short name of the tenant as it appears in lists and other elements with little space.
   * For example: mvneco
   * - minLength: 2
   * - maxLength: 20
   */
  shortName: string;
  /**
   * The unique full name of the tenant as it appears in form elements with more space.
   * For example: mvneco GmbH, Düsseldorf.
   * - minLength: 2
   * - maxLength: 80
   */
  longName: string;
  /**
   * Optional informational comment on the tenant.
   * - minLength: 0
   * - maxLength: 80
   */
  comment?: string;
  /**
   * The maximum number of subscriptions per customer for this tenant.
   */
  maxSubscriptionsPerCustomer: number;
  /**
   * A flag inidicating whether customers assigned to this tenant may form customer trees (customer hierarchy).
   */
  customerTreeAllowed: boolean;
  /**
   * If customerTreeAllowed == true: The maximum number of childs per customer node.
   * No limit if omitted.
   */
  customerTreeMaxChilds?: number;
  /**
   * If customerTreeAllowed == true: The maximum tree depth.
   * No limit if omitted..
   */
  customerTreeMaxDepth?: number;
  /**
   * Describes the current tenant life cycle state.
   * Enum, one of:
   * - INITIAL
   * - IN_USE
   * - PAUSED
   * - COOLING
   * - COLD
   * - TERMINATED
   */
  state: string;
  /**
   * Point in time the tenant has been created.
   * It will never change afterwards.
   */
  tsCreate: string;
  /**
   * Point in time of last change of tenant attributes such as shortName, longName, properties etc, but not state changes.
   */
  tsLastChange?: string;
  /**
   * Point in time of last state change.
   */
  tsLastStateChange?: string;
  properties?: IPropertiesTypeDto;
}


/**
 * TenantDataTypeDto
 *  see ITenantDataTypeDto
 */
export class TenantDataTypeDto {
  /**
   * The unique tenant id.
   * In the database this is named the surrogate for the tenant.
   */
  readonly id: number;
  /**
   * Optional external reference, e.g.
   * client tenant number.
   * Shall be unique.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly tenantRef?: string;
  /**
   * The unique short name of the tenant as it appears in lists and other elements with little space.
   * For example: mvneco
   * - minLength: 2
   * - maxLength: 20
   */
  readonly shortName: string;
  /**
   * The unique full name of the tenant as it appears in form elements with more space.
   * For example: mvneco GmbH, Düsseldorf.
   * - minLength: 2
   * - maxLength: 80
   */
  readonly longName: string;
  /**
   * Optional informational comment on the tenant.
   * - minLength: 0
   * - maxLength: 80
   */
  readonly comment?: string;
  /**
   * The maximum number of subscriptions per customer for this tenant.
   */
  readonly maxSubscriptionsPerCustomer: number;
  /**
   * A flag inidicating whether customers assigned to this tenant may form customer trees (customer hierarchy).
   */
  readonly customerTreeAllowed: boolean;
  /**
   * If customerTreeAllowed == true: The maximum number of childs per customer node.
   * No limit if omitted.
   */
  readonly customerTreeMaxChilds?: number;
  /**
   * If customerTreeAllowed == true: The maximum tree depth.
   * No limit if omitted..
   */
  readonly customerTreeMaxDepth?: number;
  /**
   * Describes the current tenant life cycle state.
   * Enum, one of:
   * - INITIAL
   * - IN_USE
   * - PAUSED
   * - COOLING
   * - COLD
   * - TERMINATED
   */
  readonly state: string;
  /**
   * Point in time the tenant has been created.
   * It will never change afterwards.
   */
  readonly tsCreate: string;
  /**
   * Point in time of last change of tenant attributes such as shortName, longName, properties etc, but not state changes.
   */
  readonly tsLastChange?: string;
  /**
   * Point in time of last state change.
   */
  readonly tsLastStateChange?: string;
  readonly properties?: PropertiesTypeDto;

  constructor(options: {
    id: number;
    tenantRef?: string;
    shortName: string;
    longName: string;
    comment?: string;
    maxSubscriptionsPerCustomer: number;
    customerTreeAllowed: boolean;
    customerTreeMaxChilds?: number;
    customerTreeMaxDepth?: number;
    state: string;
    tsCreate: string;
    tsLastChange?: string;
    tsLastStateChange?: string;
    properties?: PropertiesTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        Number(options.id) :
       null;
    this.tenantRef =
      (options !== undefined && options !== null && options.tenantRef !== undefined && options.tenantRef !== null) ?
        options.tenantRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.shortName =
      (options !== undefined && options !== null && options.shortName !== undefined && options.shortName !== null) ?
        options.shortName + '' :
       null;
    this.longName =
      (options !== undefined && options !== null && options.longName !== undefined && options.longName !== null) ?
        options.longName + '' :
       null;
    this.comment =
      (options !== undefined && options !== null && options.comment !== undefined && options.comment !== null) ?
        options.comment + '' :
       (config.viewModel === true) ? null : undefined;
    this.maxSubscriptionsPerCustomer =
      (options !== undefined && options !== null && options.maxSubscriptionsPerCustomer !== undefined && options.maxSubscriptionsPerCustomer !== null) ?
        Number(options.maxSubscriptionsPerCustomer) :
       null;
    this.customerTreeAllowed = (options !== undefined && options !== null && options.customerTreeAllowed !== undefined && options.customerTreeAllowed !== null) ?
        !!options.customerTreeAllowed :
       null;
    this.customerTreeMaxChilds =
      (options !== undefined && options !== null && options.customerTreeMaxChilds !== undefined && options.customerTreeMaxChilds !== null) ?
        Number(options.customerTreeMaxChilds) :
       (config.viewModel === true) ? null : undefined;
    this.customerTreeMaxDepth =
      (options !== undefined && options !== null && options.customerTreeMaxDepth !== undefined && options.customerTreeMaxDepth !== null) ?
        Number(options.customerTreeMaxDepth) :
       (config.viewModel === true) ? null : undefined;
    this.state =
      (options !== undefined && options !== null && options.state !== undefined && options.state !== null) ?
        options.state + '' :
       null;
    this.tsCreate =
      (options !== undefined && options !== null && options.tsCreate !== undefined && options.tsCreate !== null) ?
        options.tsCreate + '' :
       null;
    this.tsLastChange =
      (options !== undefined && options !== null && options.tsLastChange !== undefined && options.tsLastChange !== null) ?
        options.tsLastChange + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsLastStateChange =
      (options !== undefined && options !== null && options.tsLastStateChange !== undefined && options.tsLastStateChange !== null) ?
        options.tsLastStateChange + '' :
       (config.viewModel === true) ? null : undefined;
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        new PropertiesTypeDto(options.properties, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
  }
}


/**
 * ITenantsDataTypeDto
 */
export interface ITenantsDataTypeDto {
  /**
   * - minItems: 0
   */
  tenant?: ITenantDataTypeDto[];
}


/**
 * TenantsDataTypeDto
 *  see ITenantsDataTypeDto
 */
export class TenantsDataTypeDto {
  /**
   * - minItems: 0
   */
  readonly tenant?: TenantDataTypeDto[];

  constructor(options: {
    tenant?: TenantDataTypeDto[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.tenant = new Array<TenantDataTypeDto>();
    if (options !== undefined && options !== null && options.tenant !== undefined && options.tenant !== null) {
      for (const item of options.tenant) {
        this.tenant.push(new TenantDataTypeDto(item, config));
      }
    }
  }
}


/**
 * ITenantListTenantResponseDto
 */
export interface ITenantListTenantResponseDto {
  result: IDefaultReturnTypeDto;
  tenants?: ITenantsDataTypeDto;
}


/**
 * TenantListTenantResponseDto
 *  see ITenantListTenantResponseDto
 */
export class TenantListTenantResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly tenants?: TenantsDataTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    tenants?: TenantsDataTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.tenants =
      (options !== undefined && options !== null && options.tenants !== undefined && options.tenants !== null) ?
        new TenantsDataTypeDto(options.tenants, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new TenantsDataTypeDto( null, config) : null ) : (config.hierarchy === true ? new TenantsDataTypeDto( null, config) : undefined );
  }
}


/**
 * IValidateAddressByTelefonicaRequestDto
 */
export interface IValidateAddressByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  contactAddress: IContactAddressTypeDto;
}


/**
 * ValidateAddressByTelefonicaRequestDto
 *  see IValidateAddressByTelefonicaRequestDto
 */
export class ValidateAddressByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  readonly contactAddress: ContactAddressTypeDto;

  constructor(options: {
    sessionKey?: string;
    contactAddress: ContactAddressTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.contactAddress =
      (options !== undefined && options !== null && options.contactAddress !== undefined && options.contactAddress !== null) ?
        new ContactAddressTypeDto(options.contactAddress, config) :
        null;
  }
}


/**
 * IValidateAddressByTelefonicaResponseDto
 */
export interface IValidateAddressByTelefonicaResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * - minItems: 0
   */
  suggestion?: IContactAddressTypeDto[];
}


/**
 * ValidateAddressByTelefonicaResponseDto
 *  see IValidateAddressByTelefonicaResponseDto
 */
export class ValidateAddressByTelefonicaResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * - minItems: 0
   */
  readonly suggestion?: ContactAddressTypeDto[];

  constructor(options: {
    result: DefaultReturnTypeDto;
    suggestion?: ContactAddressTypeDto[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.suggestion = new Array<ContactAddressTypeDto>();
    if (options !== undefined && options !== null && options.suggestion !== undefined && options.suggestion !== null) {
      for (const item of options.suggestion) {
        this.suggestion.push(new ContactAddressTypeDto(item, config));
      }
    }
  }
}


/**
 * IGetCityByZipcodeRequestDto
 */
export interface IGetCityByZipcodeRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * ZIP code of residence.
   * - minLength: 1
   * - maxLength: 6
   */
  zipCode: string;
}


/**
 * GetCityByZipcodeRequestDto
 *  see IGetCityByZipcodeRequestDto
 */
export class GetCityByZipcodeRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * ZIP code of residence.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly zipCode: string;

  constructor(options: {
    sessionKey?: string;
    zipCode: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       null;
  }
}


/**
 * IGetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto
 */
export interface IGetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto {
  /**
   * - minItems: 1
   */
  city?: string[];
}


/**
 * GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto
 *  see IGetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto
 */
export class GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly city?: string[];

  constructor(options: {
    city?: string[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.city = new Array<string>();
    if (options !== undefined && options !== null && options.city !== undefined && options.city !== null) {
      for (const item of options.city) {
        this.city.push(item);
      }
    }
  }
}


/**
 * IGetCityByZipcodeResponseDto
 */
export interface IGetCityByZipcodeResponseDto {
  result: IDefaultReturnTypeDto;
  citySuggestions?: IGetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto;
}


/**
 * GetCityByZipcodeResponseDto
 *  see IGetCityByZipcodeResponseDto
 */
export class GetCityByZipcodeResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly citySuggestions?: GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    citySuggestions?: GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.citySuggestions =
      (options !== undefined && options !== null && options.citySuggestions !== undefined && options.citySuggestions !== null) ?
        new GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto(options.citySuggestions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new GetCityByZipcodeResponseCitySuggestionsCitySuggestionsAnonymDto( null, config) : undefined );
  }
}


/**
 * IGetStreetnameByCityRequestDto
 */
export interface IGetStreetnameByCityRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * ZIP code of residence.
   * - minLength: 1
   * - maxLength: 6
   */
  zipCode: string;
  /**
   * City of residance.
   * - minLength: 1
   * - maxLength: 80
   */
  city: string;
}


/**
 * GetStreetnameByCityRequestDto
 *  see IGetStreetnameByCityRequestDto
 */
export class GetStreetnameByCityRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * ZIP code of residence.
   * - minLength: 1
   * - maxLength: 6
   */
  readonly zipCode: string;
  /**
   * City of residance.
   * - minLength: 1
   * - maxLength: 80
   */
  readonly city: string;

  constructor(options: {
    sessionKey?: string;
    zipCode: string;
    city: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.zipCode =
      (options !== undefined && options !== null && options.zipCode !== undefined && options.zipCode !== null) ?
        options.zipCode + '' :
       null;
    this.city =
      (options !== undefined && options !== null && options.city !== undefined && options.city !== null) ?
        options.city + '' :
       null;
  }
}


/**
 * IGetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto
 */
export interface IGetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto {
  /**
   * - minItems: 1
   */
  street?: string[];
}


/**
 * GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto
 *  see IGetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto
 */
export class GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly street?: string[];

  constructor(options: {
    street?: string[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.street = new Array<string>();
    if (options !== undefined && options !== null && options.street !== undefined && options.street !== null) {
      for (const item of options.street) {
        this.street.push(item);
      }
    }
  }
}


/**
 * IGetStreetnameByCityResponseDto
 */
export interface IGetStreetnameByCityResponseDto {
  result: IDefaultReturnTypeDto;
  streeSuggestions?: IGetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto;
}


/**
 * GetStreetnameByCityResponseDto
 *  see IGetStreetnameByCityResponseDto
 */
export class GetStreetnameByCityResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly streeSuggestions?: GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    streeSuggestions?: GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.streeSuggestions =
      (options !== undefined && options !== null && options.streeSuggestions !== undefined && options.streeSuggestions !== null) ?
        new GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto(options.streeSuggestions, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new GetStreetnameByCityResponseStreeSuggestionsStreeSuggestionsAnonymDto( null, config) : undefined );
  }
}


/**
 * IValidateIbanByTelefonicaRequestDto
 */
export interface IValidateIbanByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  bankAccountData: IBankAccountDataTypeDto;
}


/**
 * ValidateIbanByTelefonicaRequestDto
 *  see IValidateIbanByTelefonicaRequestDto
 */
export class ValidateIbanByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  readonly bankAccountData: BankAccountDataTypeDto;

  constructor(options: {
    sessionKey?: string;
    sim: string;
    bankAccountData: BankAccountDataTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.bankAccountData =
      (options !== undefined && options !== null && options.bankAccountData !== undefined && options.bankAccountData !== null) ?
        new BankAccountDataTypeDto(options.bankAccountData, config) :
        null;
  }
}


/**
 * IValidateIbanByTelefonicaResponseDto
 */
export interface IValidateIbanByTelefonicaResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * - maxLength: 34
   */
  bankIBAN?: string;
  /**
   * - minLength: 8
   * - maxLength: 11
   */
  bankBIC?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  bankName?: string;
}


/**
 * ValidateIbanByTelefonicaResponseDto
 *  see IValidateIbanByTelefonicaResponseDto
 */
export class ValidateIbanByTelefonicaResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * - maxLength: 34
   */
  readonly bankIBAN?: string;
  /**
   * - minLength: 8
   * - maxLength: 11
   */
  readonly bankBIC?: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly bankName?: string;

  constructor(options: {
    result: DefaultReturnTypeDto;
    bankIBAN?: string;
    bankBIC?: string;
    bankName?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.bankIBAN =
      (options !== undefined && options !== null && options.bankIBAN !== undefined && options.bankIBAN !== null) ?
        options.bankIBAN + '' :
       (config.viewModel === true) ? null : undefined;
    this.bankBIC =
      (options !== undefined && options !== null && options.bankBIC !== undefined && options.bankBIC !== null) ?
        options.bankBIC + '' :
       (config.viewModel === true) ? null : undefined;
    this.bankName =
      (options !== undefined && options !== null && options.bankName !== undefined && options.bankName !== null) ?
        options.bankName + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICheckAvailabilityOfTpiIbanCheckRequestDto
 */
export interface ICheckAvailabilityOfTpiIbanCheckRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
}


/**
 * CheckAvailabilityOfTpiIbanCheckRequestDto
 *  see ICheckAvailabilityOfTpiIbanCheckRequestDto
 */
export class CheckAvailabilityOfTpiIbanCheckRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;

  constructor(options: {
    sessionKey?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * ICheckAvailabilityOfTpiIbanCheckResponseDto
 */
export interface ICheckAvailabilityOfTpiIbanCheckResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * Enum, one of:
   * - AVAILABLE
   * - UNAVAILABLE
   */
  status: string;
}


/**
 * CheckAvailabilityOfTpiIbanCheckResponseDto
 *  see ICheckAvailabilityOfTpiIbanCheckResponseDto
 */
export class CheckAvailabilityOfTpiIbanCheckResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * Enum, one of:
   * - AVAILABLE
   * - UNAVAILABLE
   */
  readonly status: string;

  constructor(options: {
    result: DefaultReturnTypeDto;
    status: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       null;
  }
}


/**
 * ICheckPasswordByTelefonicaRequestDto
 */
export interface ICheckPasswordByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  password: string;
}


/**
 * CheckPasswordByTelefonicaRequestDto
 *  see ICheckPasswordByTelefonicaRequestDto
 */
export class CheckPasswordByTelefonicaRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Type for long strings
   * - minLength: 0
   * - maxLength: 80
   */
  readonly password: string;

  constructor(options: {
    sessionKey?: string;
    sim: string;
    password: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.password =
      (options !== undefined && options !== null && options.password !== undefined && options.password !== null) ?
        options.password + '' :
       null;
  }
}


/**
 * ICheckPasswordByTelefonicaResponseDto
 */
export interface ICheckPasswordByTelefonicaResponseDto {
  result: IDefaultReturnTypeDto;
  isPasswordPawned?: boolean;
}


/**
 * CheckPasswordByTelefonicaResponseDto
 *  see ICheckPasswordByTelefonicaResponseDto
 */
export class CheckPasswordByTelefonicaResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly isPasswordPawned?: boolean;

  constructor(options: {
    result: DefaultReturnTypeDto;
    isPasswordPawned?: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.isPasswordPawned = (options !== undefined && options !== null && options.isPasswordPawned !== undefined && options.isPasswordPawned !== null) ?
        !!options.isPasswordPawned :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IGetPrecontractualDocumentRequestDto
 */
export interface IGetPrecontractualDocumentRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  getContent: boolean;
}


/**
 * GetPrecontractualDocumentRequestDto
 *  see IGetPrecontractualDocumentRequestDto
 */
export class GetPrecontractualDocumentRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  readonly getContent: boolean;

  constructor(options: {
    transactionRef?: string;
    sessionKey?: string;
    sim: string;
    getContent: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.getContent = (options !== undefined && options !== null && options.getContent !== undefined && options.getContent !== null) ?
        !!options.getContent :
       null;
  }
}


/**
 * IGetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto
 */
export interface IGetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto {
  /**
   * This element contains the original filename of the EECC document.
   * - minLength: 1
   * - maxLength: 1024
   */
  fileName: string;
  /**
   * This element contains the original file size of the EECC document content in bytes.
   */
  fileSize: string;
  /**
   * This element contains the document content base64 coded.
   * The maximum size of this element (to store 10MB of PDF) is 14260634.
   * - minLength: 0
   * - maxLength: 14260634
   */
  content: string;
}


/**
 * GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto
 *  see IGetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto
 */
export class GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto {
  /**
   * This element contains the original filename of the EECC document.
   * - minLength: 1
   * - maxLength: 1024
   */
  readonly fileName: string;
  /**
   * This element contains the original file size of the EECC document content in bytes.
   */
  readonly fileSize: string;
  /**
   * This element contains the document content base64 coded.
   * The maximum size of this element (to store 10MB of PDF) is 14260634.
   * - minLength: 0
   * - maxLength: 14260634
   */
  readonly content: string;

  constructor(options: {
    fileName: string;
    fileSize: string;
    content: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.fileName =
      (options !== undefined && options !== null && options.fileName !== undefined && options.fileName !== null) ?
        options.fileName + '' :
       null;
    this.fileSize =
      (options !== undefined && options !== null && options.fileSize !== undefined && options.fileSize !== null) ?
        options.fileSize + '' :
       null;
    this.content =
      (options !== undefined && options !== null && options.content !== undefined && options.content !== null) ?
        options.content + '' :
       null;
  }
}


/**
 * IGetPrecontractualDocumentResponseDto
 */
export interface IGetPrecontractualDocumentResponseDto {
  result: IDefaultReturnTypeDto;
  eeccAvailable: boolean;
  eeccDocument?: IGetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto;
}


/**
 * GetPrecontractualDocumentResponseDto
 *  see IGetPrecontractualDocumentResponseDto
 */
export class GetPrecontractualDocumentResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly eeccAvailable: boolean;
  readonly eeccDocument?: GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
    eeccAvailable: boolean;
    eeccDocument?: GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.eeccAvailable = (options !== undefined && options !== null && options.eeccAvailable !== undefined && options.eeccAvailable !== null) ?
        !!options.eeccAvailable :
       null;
    this.eeccDocument =
      (options !== undefined && options !== null && options.eeccDocument !== undefined && options.eeccDocument !== null) ?
        new GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto(options.eeccDocument, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto( null, config) : null ) : (config.hierarchy === true ? new GetPrecontractualDocumentResponseEeccDocumentEeccDocumentAnonymDto( null, config) : undefined );
  }
}


/**
 * IContinueRegistrationRequestDto
 */
export interface IContinueRegistrationRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  ackTrafficData?: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  ackMarketing?: boolean;
  customerConsentSetting?: ICustomerConsentSettingTypeDto;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  ip?: string;
}


/**
 * ContinueRegistrationRequestDto
 *  see IContinueRegistrationRequestDto
 */
export class ContinueRegistrationRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Flag indicating whether the customer has acknowledged traffic data terms.
   */
  readonly ackTrafficData?: boolean;
  /**
   * Flag indicating whether the customer has acknowledged to use personal data for marketing purposes towards third parties.
   */
  readonly ackMarketing?: boolean;
  readonly customerConsentSetting?: CustomerConsentSettingTypeDto;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  readonly ip?: string;

  constructor(options: {
    sessionKey?: string;
    sim: string;
    ackTrafficData?: boolean;
    ackMarketing?: boolean;
    customerConsentSetting?: CustomerConsentSettingTypeDto;
    ip?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.ackTrafficData = (options !== undefined && options !== null && options.ackTrafficData !== undefined && options.ackTrafficData !== null) ?
        !!options.ackTrafficData :
       (config.viewModel === true) ? null : undefined;
    this.ackMarketing = (options !== undefined && options !== null && options.ackMarketing !== undefined && options.ackMarketing !== null) ?
        !!options.ackMarketing :
       (config.viewModel === true) ? null : undefined;
    this.customerConsentSetting =
      (options !== undefined && options !== null && options.customerConsentSetting !== undefined && options.customerConsentSetting !== null) ?
        new CustomerConsentSettingTypeDto(options.customerConsentSetting, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new CustomerConsentSettingTypeDto( null, config) : null ) : (config.hierarchy === true ? new CustomerConsentSettingTypeDto( null, config) : undefined );
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IContinueRegistrationResponseDto
 */
export interface IContinueRegistrationResponseDto {
  result: IDefaultReturnTypeDto;
  paymentStatus: number;
}


/**
 * ContinueRegistrationResponseDto
 *  see IContinueRegistrationResponseDto
 */
export class ContinueRegistrationResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly paymentStatus: number;

  constructor(options: {
    result: DefaultReturnTypeDto;
    paymentStatus: number;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.paymentStatus =
      (options !== undefined && options !== null && options.paymentStatus !== undefined && options.paymentStatus !== null) ?
        Number(options.paymentStatus) :
       null;
  }
}


/**
 * IIsOrderCancellationAllowedRequestDto
 */
export interface IIsOrderCancellationAllowedRequestDto {
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  ip?: string;
}


/**
 * IsOrderCancellationAllowedRequestDto
 *  see IIsOrderCancellationAllowedRequestDto
 */
export class IsOrderCancellationAllowedRequestDto {
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * Mandatory for credit check, either in IPv4 or IPv6
   * - minLength: 7
   * - maxLength: 64
   */
  readonly ip?: string;

  constructor(options: {
    sessionKey?: string;
    sim: string;
    ip?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IIsOrderCancellationAllowedResponseDto
 */
export interface IIsOrderCancellationAllowedResponseDto {
  result: IDefaultReturnTypeDto;
  orderCancellationAllowed: boolean;
}


/**
 * IsOrderCancellationAllowedResponseDto
 *  see IIsOrderCancellationAllowedResponseDto
 */
export class IsOrderCancellationAllowedResponseDto {
  readonly result: DefaultReturnTypeDto;
  readonly orderCancellationAllowed: boolean;

  constructor(options: {
    result: DefaultReturnTypeDto;
    orderCancellationAllowed: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.orderCancellationAllowed = (options !== undefined && options !== null && options.orderCancellationAllowed !== undefined && options.orderCancellationAllowed !== null) ?
        !!options.orderCancellationAllowed :
       null;
  }
}


/**
 * IIpTypeDto
 */
export interface IIpTypeDto {
  /**
   * - maxLength: 64
   */
  ip?: string;
}


/**
 * IpTypeDto
 *  see IIpTypeDto
 */
export class IpTypeDto {
  /**
   * - maxLength: 64
   */
  readonly ip?: string;

  constructor(options: {
    ip?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        options.ip + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IGetJwtTokenRequestDto
 */
export interface IGetJwtTokenRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * The session key obtained on previous login.
   */
  sessionKey?: string;
  ip?: IIpTypeDto;
}


/**
 * GetJwtTokenRequestDto
 *  see IGetJwtTokenRequestDto
 */
export class GetJwtTokenRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * The session key obtained on previous login.
   */
  readonly sessionKey?: string;
  readonly ip?: IpTypeDto;

  constructor(options: {
    transactionRef?: string;
    sessionKey?: string;
    ip?: IpTypeDto;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.ip =
      (options !== undefined && options !== null && options.ip !== undefined && options.ip !== null) ?
        new IpTypeDto(options.ip, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new IpTypeDto( null, config) : null ) : (config.hierarchy === true ? new IpTypeDto( null, config) : undefined );
  }
}


/**
 * IGetJwtTokenResponseDto
 */
export interface IGetJwtTokenResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * base64 encoded, only returned in case of success.
   */
  jwtToken?: string;
}


/**
 * GetJwtTokenResponseDto
 *  see IGetJwtTokenResponseDto
 */
export class GetJwtTokenResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * base64 encoded, only returned in case of success.
   */
  readonly jwtToken?: string;

  constructor(options: {
    result: DefaultReturnTypeDto;
    jwtToken?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.jwtToken =
      (options !== undefined && options !== null && options.jwtToken !== undefined && options.jwtToken !== null) ?
        options.jwtToken + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IVoucherGetInfoRequestDto
 */
export interface IVoucherGetInfoRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * The voucher serial number
   */
  voucherSerial?: string;
  /**
   * The voucher code
   */
  voucherCode?: string;
}


/**
 * VoucherGetInfoRequestDto
 *  see IVoucherGetInfoRequestDto
 */
export class VoucherGetInfoRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * The voucher serial number
   */
  readonly voucherSerial?: string;
  /**
   * The voucher code
   */
  readonly voucherCode?: string;

  constructor(options: {
    transactionRef?: string;
    sessionKey?: string;
    voucherSerial?: string;
    voucherCode?: string;
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.voucherSerial =
      (options !== undefined && options !== null && options.voucherSerial !== undefined && options.voucherSerial !== null) ?
        options.voucherSerial + '' :
       (config.viewModel === true) ? null : undefined;
    this.voucherCode =
      (options !== undefined && options !== null && options.voucherCode !== undefined && options.voucherCode !== null) ?
        options.voucherCode + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IVoucherGetInfoResponseDto
 */
export interface IVoucherGetInfoResponseDto {
  result: IDefaultReturnTypeDto;
  /**
   * The voucher serial number
   */
  voucherSerial?: string;
  /**
   * The status of the voucher (loaded, valid, invalid, used), only returned if result.code=0
   * Enum, one of:
   * - LOADED
   * - VALID
   * - INVALID
   * - USED
   */
  status?: string;
  /**
   * The date and time when the voucher was created.
   */
  created?: string;
  /**
   * The date and time when the voucher becomes valid.
   */
  validFrom?: string;
  /**
   * The date and time when the voucher becomes invalid.
   */
  validUntil?: string;
  /**
   * The voucher's amount.
   */
  amount?: number;
  /**
   * The voucher's currency.
   * Enum, one of:
   * - EUR
   */
  currency?: string;
  /**
   * The voucher-type.
   * E.g.
   * MONEY, TRIAL, etc..
   */
  voucherType?: string;
  multipleUsage?: number;
  /**
   * A flag indicating whether the voucher can be used for the affected subscription (1) or not (0).
   */
  usable: number;
  /**
   * A short description text derived from individual voucher/​text (not voucher_type).
   * Might be displayed in Frontends.
   * - minLength: 0
   * - maxLength: 255
   */
  description?: string;
  trialPeriodRetail?: IPeriodTypeDto;
  trialPeriodWholesale?: IPeriodTypeDto;
  voucherTypeProperty?: IPropertiesTypeDto;
  /**
   * The date and time when the voucher was in status RESERVED (eventtime's from according voucher_history).
   */
  tsRESERVED?: string;
  /**
   * The date and time when the voucher was in status INVALID (eventtime's from according voucher_history).
   */
  tsINVALID?: string;
  /**
   * The date and time when the voucher was in status VALID (eventtime's from according voucher_history).
   */
  tsVALID?: string;
  /**
   * The date and time when the voucher was in status USED (eventtime's from according voucher_history).
   */
  tsUSED?: string;
}


/**
 * VoucherGetInfoResponseDto
 *  see IVoucherGetInfoResponseDto
 */
export class VoucherGetInfoResponseDto {
  readonly result: DefaultReturnTypeDto;
  /**
   * The voucher serial number
   */
  readonly voucherSerial?: string;
  /**
   * The status of the voucher (loaded, valid, invalid, used), only returned if result.code=0
   * Enum, one of:
   * - LOADED
   * - VALID
   * - INVALID
   * - USED
   */
  readonly status?: string;
  /**
   * The date and time when the voucher was created.
   */
  readonly created?: string;
  /**
   * The date and time when the voucher becomes valid.
   */
  readonly validFrom?: string;
  /**
   * The date and time when the voucher becomes invalid.
   */
  readonly validUntil?: string;
  /**
   * The voucher's amount.
   */
  readonly amount?: number;
  /**
   * The voucher's currency.
   * Enum, one of:
   * - EUR
   */
  readonly currency?: string;
  /**
   * The voucher-type.
   * E.g.
   * MONEY, TRIAL, etc..
   */
  readonly voucherType?: string;
  readonly multipleUsage?: number;
  /**
   * A flag indicating whether the voucher can be used for the affected subscription (1) or not (0).
   */
  readonly usable: number;
  /**
   * A short description text derived from individual voucher/​text (not voucher_type).
   * Might be displayed in Frontends.
   * - minLength: 0
   * - maxLength: 255
   */
  readonly description?: string;
  readonly trialPeriodRetail?: PeriodTypeDto;
  readonly trialPeriodWholesale?: PeriodTypeDto;
  readonly voucherTypeProperty?: PropertiesTypeDto;
  /**
   * The date and time when the voucher was in status RESERVED (eventtime's from according voucher_history).
   */
  readonly tsRESERVED?: string;
  /**
   * The date and time when the voucher was in status INVALID (eventtime's from according voucher_history).
   */
  readonly tsINVALID?: string;
  /**
   * The date and time when the voucher was in status VALID (eventtime's from according voucher_history).
   */
  readonly tsVALID?: string;
  /**
   * The date and time when the voucher was in status USED (eventtime's from according voucher_history).
   */
  readonly tsUSED?: string;

  constructor(options: {
    result: DefaultReturnTypeDto;
    voucherSerial?: string;
    status?: string;
    created?: string;
    validFrom?: string;
    validUntil?: string;
    amount?: number;
    currency?: string;
    voucherType?: string;
    multipleUsage?: number;
    usable: number;
    description?: string;
    trialPeriodRetail?: PeriodTypeDto;
    trialPeriodWholesale?: PeriodTypeDto;
    voucherTypeProperty?: PropertiesTypeDto;
    tsRESERVED?: string;
    tsINVALID?: string;
    tsVALID?: string;
    tsUSED?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
    this.voucherSerial =
      (options !== undefined && options !== null && options.voucherSerial !== undefined && options.voucherSerial !== null) ?
        options.voucherSerial + '' :
       (config.viewModel === true) ? null : undefined;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       (config.viewModel === true) ? null : undefined;
    this.created =
      (options !== undefined && options !== null && options.created !== undefined && options.created !== null) ?
        options.created + '' :
       (config.viewModel === true) ? null : undefined;
    this.validFrom =
      (options !== undefined && options !== null && options.validFrom !== undefined && options.validFrom !== null) ?
        options.validFrom + '' :
       (config.viewModel === true) ? null : undefined;
    this.validUntil =
      (options !== undefined && options !== null && options.validUntil !== undefined && options.validUntil !== null) ?
        options.validUntil + '' :
       (config.viewModel === true) ? null : undefined;
    this.amount =
      (options !== undefined && options !== null && options.amount !== undefined && options.amount !== null) ?
        Number(options.amount) :
       (config.viewModel === true) ? null : undefined;
    this.currency =
      (options !== undefined && options !== null && options.currency !== undefined && options.currency !== null) ?
        options.currency + '' :
       (config.viewModel === true) ? null : undefined;
    this.voucherType =
      (options !== undefined && options !== null && options.voucherType !== undefined && options.voucherType !== null) ?
        options.voucherType + '' :
       (config.viewModel === true) ? null : undefined;
    this.multipleUsage =
      (options !== undefined && options !== null && options.multipleUsage !== undefined && options.multipleUsage !== null) ?
        Number(options.multipleUsage) :
       (config.viewModel === true) ? null : undefined;
    this.usable =
      (options !== undefined && options !== null && options.usable !== undefined && options.usable !== null) ?
        Number(options.usable) :
       null;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.trialPeriodRetail =
      (options !== undefined && options !== null && options.trialPeriodRetail !== undefined && options.trialPeriodRetail !== null) ?
        new PeriodTypeDto(options.trialPeriodRetail, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.trialPeriodWholesale =
      (options !== undefined && options !== null && options.trialPeriodWholesale !== undefined && options.trialPeriodWholesale !== null) ?
        new PeriodTypeDto(options.trialPeriodWholesale, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PeriodTypeDto( null, config) : null ) : (config.hierarchy === true ? new PeriodTypeDto( null, config) : undefined );
    this.voucherTypeProperty =
      (options !== undefined && options !== null && options.voucherTypeProperty !== undefined && options.voucherTypeProperty !== null) ?
        new PropertiesTypeDto(options.voucherTypeProperty, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new PropertiesTypeDto( null, config) : null ) : (config.hierarchy === true ? new PropertiesTypeDto( null, config) : undefined );
    this.tsRESERVED =
      (options !== undefined && options !== null && options.tsRESERVED !== undefined && options.tsRESERVED !== null) ?
        options.tsRESERVED + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsINVALID =
      (options !== undefined && options !== null && options.tsINVALID !== undefined && options.tsINVALID !== null) ?
        options.tsINVALID + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsVALID =
      (options !== undefined && options !== null && options.tsVALID !== undefined && options.tsVALID !== null) ?
        options.tsVALID + '' :
       (config.viewModel === true) ? null : undefined;
    this.tsUSED =
      (options !== undefined && options !== null && options.tsUSED !== undefined && options.tsUSED !== null) ?
        options.tsUSED + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IVoucherSetStatusRequestDto
 */
export interface IVoucherSetStatusRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * The voucher serial number
   */
  voucherSerial?: string;
  /**
   * The voucher code
   */
  voucherCode?: string;
  /**
   * The status of the voucher (loaded, valid, invalid, used) to be set.
   * Enum, one of:
   * - LOADED
   * - VALID
   * - INVALID
   * - USED
   */
  status: string;
  account: string;
}


/**
 * VoucherSetStatusRequestDto
 *  see IVoucherSetStatusRequestDto
 */
export class VoucherSetStatusRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * The voucher serial number
   */
  readonly voucherSerial?: string;
  /**
   * The voucher code
   */
  readonly voucherCode?: string;
  /**
   * The status of the voucher (loaded, valid, invalid, used) to be set.
   * Enum, one of:
   * - LOADED
   * - VALID
   * - INVALID
   * - USED
   */
  readonly status: string;
  readonly account: string;

  constructor(options: {
    transactionRef?: string;
    sessionKey?: string;
    voucherSerial?: string;
    voucherCode?: string;
    status: string;
    account: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.voucherSerial =
      (options !== undefined && options !== null && options.voucherSerial !== undefined && options.voucherSerial !== null) ?
        options.voucherSerial + '' :
       (config.viewModel === true) ? null : undefined;
    this.voucherCode =
      (options !== undefined && options !== null && options.voucherCode !== undefined && options.voucherCode !== null) ?
        options.voucherCode + '' :
       (config.viewModel === true) ? null : undefined;
    this.status =
      (options !== undefined && options !== null && options.status !== undefined && options.status !== null) ?
        options.status + '' :
       null;
    this.account =
      (options !== undefined && options !== null && options.account !== undefined && options.account !== null) ?
        options.account + '' :
       null;
  }
}


/**
 * IVoucherSetStatusResponseDto
 */
export interface IVoucherSetStatusResponseDto {
  result: IDefaultReturnTypeDto;
}


/**
 * VoucherSetStatusResponseDto
 *  see IVoucherSetStatusResponseDto
 */
export class VoucherSetStatusResponseDto {
  readonly result: DefaultReturnTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
  }
}


/**
 * IProcessPrecontractualInformationRequestOptionsOptionsAnonymDto
 */
export interface IProcessPrecontractualInformationRequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  optionCode?: string[];
}


/**
 * ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto
 *  see IProcessPrecontractualInformationRequestOptionsOptionsAnonymDto
 */
export class ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto {
  /**
   * - minItems: 1
   */
  readonly optionCode?: string[];

  constructor(options: {
    optionCode?: string[];
  } = {}, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.optionCode = new Array<string>();
    if (options !== undefined && options !== null && options.optionCode !== undefined && options.optionCode !== null) {
      for (const item of options.optionCode) {
        this.optionCode.push(item);
      }
    }
  }
}


/**
 * IProcessPrecontractualInformationRequestDto
 */
export interface IProcessPrecontractualInformationRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  sim: string;
  /**
   * An email address of the customer that pre-contractual information should be sent to.
   * - minLength: 3
   * - maxLength: 80
   */
  email: string;
  /**
   * Unique mvneco tariff code for processing pre-contractual information.
   * - minLength: 0
   * - maxLength: 16
   */
  tariffCode: string;
  options?: IProcessPrecontractualInformationRequestOptionsOptionsAnonymDto;
}


/**
 * ProcessPrecontractualInformationRequestDto
 *  see IProcessPrecontractualInformationRequestDto
 */
export class ProcessPrecontractualInformationRequestDto {
  /**
   * An optional transaction Reference may be provided for every call.
   * - minLength: 0
   * - maxLength: 45
   */
  readonly transactionRef?: string;
  /**
   * The session key obtained on login.
   */
  readonly sessionKey?: string;
  /**
   * Type for SIM serial numbers (als called ICCID).
   * For example 89490200000754655947.
   * SIM serial number of cellullar network applications comprises 19 to 20 decimal digits.
   * - minLength: 12
   * - maxLength: 20
   */
  readonly sim: string;
  /**
   * An email address of the customer that pre-contractual information should be sent to.
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email: string;
  /**
   * Unique mvneco tariff code for processing pre-contractual information.
   * - minLength: 0
   * - maxLength: 16
   */
  readonly tariffCode: string;
  readonly options?: ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto;

  constructor(options: {
    transactionRef?: string;
    sessionKey?: string;
    sim: string;
    email: string;
    tariffCode: string;
    options?: ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.transactionRef =
      (options !== undefined && options !== null && options.transactionRef !== undefined && options.transactionRef !== null) ?
        options.transactionRef + '' :
       (config.viewModel === true) ? null : undefined;
    this.sessionKey =
      (options !== undefined && options !== null && options.sessionKey !== undefined && options.sessionKey !== null) ?
        options.sessionKey + '' :
       (config.viewModel === true) ? null : undefined;
    this.sim =
      (options !== undefined && options !== null && options.sim !== undefined && options.sim !== null) ?
        options.sim + '' :
       null;
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       null;
    this.tariffCode =
      (options !== undefined && options !== null && options.tariffCode !== undefined && options.tariffCode !== null) ?
        options.tariffCode + '' :
       null;
    this.options =
      (options !== undefined && options !== null && options.options !== undefined && options.options !== null) ?
        new ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto(options.options, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto( null, config) : null ) : (config.hierarchy === true ? new ProcessPrecontractualInformationRequestOptionsOptionsAnonymDto( null, config) : undefined );
  }
}


/**
 * IProcessPrecontractualInformationResponseDto
 */
export interface IProcessPrecontractualInformationResponseDto {
  result: IDefaultReturnTypeDto;
}


/**
 * ProcessPrecontractualInformationResponseDto
 *  see IProcessPrecontractualInformationResponseDto
 */
export class ProcessPrecontractualInformationResponseDto {
  readonly result: DefaultReturnTypeDto;

  constructor(options: {
    result: DefaultReturnTypeDto;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.result =
      (options !== undefined && options !== null && options.result !== undefined && options.result !== null) ?
        new DefaultReturnTypeDto(options.result, config) :
        null;
  }
}


/**
 * ICheckEmailRequestDto
 */
export interface ICheckEmailRequestDto {
  /**
   * Email address to validate (only domain part will be validated)
   * - minLength: 3
   * - maxLength: 80
   */
  email: string;
}


/**
 * CheckEmailRequestDto
 *  see ICheckEmailRequestDto
 */
export class CheckEmailRequestDto {
  /**
   * Email address to validate (only domain part will be validated)
   * - minLength: 3
   * - maxLength: 80
   */
  readonly email: string;

  constructor(options: {
    email: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.email =
      (options !== undefined && options !== null && options.email !== undefined && options.email !== null) ?
        options.email + '' :
       null;
  }
}


/**
 * ICheckEmailResponseDto
 */
export interface ICheckEmailResponseDto {
  /**
   * Return code of a operation.
   * If the code == 0, the operation succeeded.
   * Otherwise the return code may indicate a specific incident.
   */
  code: number;
  /**
   * Optional message indicating error cause
   * - maxLength: 80
   */
  message?: string;
}


/**
 * CheckEmailResponseDto
 *  see ICheckEmailResponseDto
 */
export class CheckEmailResponseDto {
  /**
   * Return code of a operation.
   * If the code == 0, the operation succeeded.
   * Otherwise the return code may indicate a specific incident.
   */
  readonly code: number;
  /**
   * Optional message indicating error cause
   * - maxLength: 80
   */
  readonly message?: string;

  constructor(options: {
    code: number;
    message?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.code =
      (options !== undefined && options !== null && options.code !== undefined && options.code !== null) ?
        Number(options.code) :
       null;
    this.message =
      (options !== undefined && options !== null && options.message !== undefined && options.message !== null) ?
        options.message + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IAppFeaturesOtherAppItemDto
 */
export interface IAppFeaturesOtherAppItemDto {
  /**
   * Label of the external application menu option
   */
  label: string;
  /**
   * URL to be opened once user taps the external application menu option
   */
  url: string;
}


/**
 * AppFeaturesOtherAppItemDto
 *  see IAppFeaturesOtherAppItemDto
 */
export class AppFeaturesOtherAppItemDto {
  /**
   * Label of the external application menu option
   */
  readonly label: string;
  /**
   * URL to be opened once user taps the external application menu option
   */
  readonly url: string;

  constructor(options: {
    label: string;
    url: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.label =
      (options !== undefined && options !== null && options.label !== undefined && options.label !== null) ?
        options.label + '' :
       null;
    this.url =
      (options !== undefined && options !== null && options.url !== undefined && options.url !== null) ?
        options.url + '' :
       null;
  }
}


/**
 * IAppFeaturesResponseDto
 */
export interface IAppFeaturesResponseDto {
  /**
   * Type of the app, one of 'web', 'app', 'ccs'
   * Enum, one of:
   * - web
   * - app
   * - ccs
   */
  appType: string;
  /**
   * Whether maintenance is active or not
   */
  maintenanceMode: boolean;
  /**
   * Optional message of the day as string, excluding HTML tags
   */
  messageOfTheDay?: string;
  /**
   * Optional maintenance title as string, excluding HTML tags
   */
  maintenanceTitle?: string;
  /**
   * Optional maintenance message as string, excluding HTML tags
   */
  maintenanceMessage?: string;
  /**
   * Array of additional web locations to be shown as options in the other applications toolbar menu
   * - minItems: 0
   */
  otherApps?: IAppFeaturesOtherAppItemDto[];
  /**
   * Array of blacklisted emails
   * - minItems: 0
   */
  emailBlacklist?: string[];
}


/**
 * AppFeaturesResponseDto
 *  see IAppFeaturesResponseDto
 */
export class AppFeaturesResponseDto {
  /**
   * Type of the app, one of 'web', 'app', 'ccs'
   * Enum, one of:
   * - web
   * - app
   * - ccs
   */
  readonly appType: string;
  /**
   * Whether maintenance is active or not
   */
  readonly maintenanceMode: boolean;
  /**
   * Optional message of the day as string, excluding HTML tags
   */
  readonly messageOfTheDay?: string;
  /**
   * Optional maintenance title as string, excluding HTML tags
   */
  readonly maintenanceTitle?: string;
  /**
   * Optional maintenance message as string, excluding HTML tags
   */
  readonly maintenanceMessage?: string;
  /**
   * Array of additional web locations to be shown as options in the other applications toolbar menu
   * - minItems: 0
   */
  readonly otherApps?: AppFeaturesOtherAppItemDto[];
  /**
   * Array of blacklisted emails
   * - minItems: 0
   */
  readonly emailBlacklist?: string[];

  constructor(options: {
    appType: string;
    maintenanceMode: boolean;
    messageOfTheDay?: string;
    maintenanceTitle?: string;
    maintenanceMessage?: string;
    otherApps?: AppFeaturesOtherAppItemDto[];
    emailBlacklist?: string[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.appType =
      (options !== undefined && options !== null && options.appType !== undefined && options.appType !== null) ?
        options.appType + '' :
       null;
    this.maintenanceMode = (options !== undefined && options !== null && options.maintenanceMode !== undefined && options.maintenanceMode !== null) ?
        !!options.maintenanceMode :
       null;
    this.messageOfTheDay =
      (options !== undefined && options !== null && options.messageOfTheDay !== undefined && options.messageOfTheDay !== null) ?
        options.messageOfTheDay + '' :
       (config.viewModel === true) ? null : undefined;
    this.maintenanceTitle =
      (options !== undefined && options !== null && options.maintenanceTitle !== undefined && options.maintenanceTitle !== null) ?
        options.maintenanceTitle + '' :
       (config.viewModel === true) ? null : undefined;
    this.maintenanceMessage =
      (options !== undefined && options !== null && options.maintenanceMessage !== undefined && options.maintenanceMessage !== null) ?
        options.maintenanceMessage + '' :
       (config.viewModel === true) ? null : undefined;
    this.otherApps = new Array<AppFeaturesOtherAppItemDto>();
    if (options !== undefined && options !== null && options.otherApps !== undefined && options.otherApps !== null) {
      for (const item of options.otherApps) {
        this.otherApps.push(new AppFeaturesOtherAppItemDto(item, config));
      }
    }
    this.emailBlacklist = new Array<string>();
    if (options !== undefined && options !== null && options.emailBlacklist !== undefined && options.emailBlacklist !== null) {
      for (const item of options.emailBlacklist) {
        this.emailBlacklist.push(item);
      }
    }
  }
}


/**
 * IAppSettingsSalesPartnerClassifciationItemDto
 */
export interface IAppSettingsSalesPartnerClassifciationItemDto {
  /**
   * value for sales partner classification, e.g.
   * gold, silver, bronze
   */
  value: string;
  /**
   * name for sales partner classification, e.g.
   * Gold, Silber, Bronze
   */
  name: string;
  /**
   * Whether sales partner classification is active or not, only active classifications shall be assigned
   */
  active: boolean;
}


/**
 * AppSettingsSalesPartnerClassifciationItemDto
 *  see IAppSettingsSalesPartnerClassifciationItemDto
 */
export class AppSettingsSalesPartnerClassifciationItemDto {
  /**
   * value for sales partner classification, e.g.
   * gold, silver, bronze
   */
  readonly value: string;
  /**
   * name for sales partner classification, e.g.
   * Gold, Silber, Bronze
   */
  readonly name: string;
  /**
   * Whether sales partner classification is active or not, only active classifications shall be assigned
   */
  readonly active: boolean;

  constructor(options: {
    value: string;
    name: string;
    active: boolean;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.active = (options !== undefined && options !== null && options.active !== undefined && options.active !== null) ?
        !!options.active :
       null;
  }
}


/**
 * IAppSettingsNeotelManagementConfigDto
 */
export interface IAppSettingsNeotelManagementConfigDto {
  /**
   * Prefix of the URLs used to open Centrex management console
   */
  urlPrefix: string;
}


/**
 * AppSettingsNeotelManagementConfigDto
 *  see IAppSettingsNeotelManagementConfigDto
 */
export class AppSettingsNeotelManagementConfigDto {
  /**
   * Prefix of the URLs used to open Centrex management console
   */
  readonly urlPrefix: string;

  constructor(options: {
    urlPrefix: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.urlPrefix =
      (options !== undefined && options !== null && options.urlPrefix !== undefined && options.urlPrefix !== null) ?
        options.urlPrefix + '' :
       null;
  }
}


/**
 * IAppSettingsInvalidCharactersDto
 */
export interface IAppSettingsInvalidCharactersDto {
  /**
   * Invalid characters for validating customer name
   */
  invalidCharacters: string;
}


/**
 * AppSettingsInvalidCharactersDto
 *  see IAppSettingsInvalidCharactersDto
 */
export class AppSettingsInvalidCharactersDto {
  /**
   * Invalid characters for validating customer name
   */
  readonly invalidCharacters: string;

  constructor(options: {
    invalidCharacters: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.invalidCharacters =
      (options !== undefined && options !== null && options.invalidCharacters !== undefined && options.invalidCharacters !== null) ?
        options.invalidCharacters + '' :
       null;
  }
}


/**
 * IAppSettingsAddressAutocompletionDto
 */
export interface IAppSettingsAddressAutocompletionDto {

  /**
   * Number of digits entered in ZIP input text field after which the first call to getCityByZipcode API service may be issued.
   */
  zipMinDigits: number;

  /**
   * Number of milliseconds without user entry in ZIP input text field after which next call to getCityByZipcode API service shall be issued.
   */
  zipDelayMs: number;

  /**
   * Number of characters entered in street name input text field after which the first call to getStreetnameByCity API service may be issued.
   */
  streetMinChars: number;

  /**
   * Number of milliseconds without user entry in street name input text field after which next call to getStreetnameByCity API service shall be issued.
   */
  streetDelayMs: number;
}


/**
 * AppSettingsAddressAutocompletionDto
 *  see IAppSettingsAddressAutocompletionDto
 */
export class AppSettingsAddressAutocompletionDto {

  /**
   * Number of digits entered in ZIP input text field after which the first call to getCityByZipcode API service may be issued.
   */
  readonly zipMinDigits: number;

  /**
   * Number of milliseconds without user entry in ZIP input text field after which next call to getCityByZipcode API service shall be issued.
   */
  readonly zipDelayMs: number;
  
  /**
   * Number of characters entered in street name input text field after which the first call to getStreetnameByCity API service may be issued.
   */
  readonly streetMinChars: number;
  
  /**
   * Number of milliseconds without user entry in street name input text field after which next call to getStreetnameByCity API service shall be issued.
   */
  readonly streetDelayMs: number;

  constructor(options: {
    zipMinDigits: number;
    zipDelayMs: number;
    streetMinChars: number;
    streetDelayMs: number;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.zipMinDigits =
      (options !== undefined && options !== null && options.zipMinDigits !== undefined && options.zipMinDigits !== null) ?
        options.zipMinDigits :
        null;
    this.zipDelayMs =
      (options !== undefined && options !== null && options.zipDelayMs !== undefined && options.zipDelayMs !== null) ?
        options.zipDelayMs :
        null;
    this.streetMinChars =
      (options !== undefined && options !== null && options.streetMinChars !== undefined && options.streetMinChars !== null) ?
        options.streetMinChars :
        null;
    this.streetDelayMs =
      (options !== undefined && options !== null && options.streetDelayMs !== undefined && options.streetDelayMs !== null) ?
        options.streetDelayMs :
        null;
  }
}


/**
 * IAppSettingsResponseDto
 */
export interface IAppSettingsResponseDto {
  /**
   * Array of value/name pairs for sales partner classification including active flag.
   */
  salesPartnerClassification: IAppSettingsSalesPartnerClassifciationItemDto[];
  neotelManagementConfig?: IAppSettingsNeotelManagementConfigDto;
  invalidCharactersConfig: IAppSettingsInvalidCharactersDto;
  addressAutocompletion: IAppSettingsAddressAutocompletionDto;
  /**
   * RegularExpression presented as string of valid characters
   */
  streetnameAllowedCharacters: string;
  /**
   * RegularExpression presented as string of valid characters
   */
  housenoAllowedCharacters: string;
  /**
   * Boolean value that indicates if payment method can be chosen or not
   */
  choosePaymentMethod: boolean;
  /**
   * Boolean value that indicates if bank data entry should be shown or not
   */
  showBankdataEntry: boolean;
  /**
   * Boolean value that indicates if button for patching demo data will be visible or not
   */
  demoButton: boolean;
  /**
   * Boolean value which indicates if newsletter section should be displayed or not
   */
  showNewsletterSection: boolean;
  /**
   * Boolean value which indicates if third salutation should be displayed or not
   */
  show3rdSalutation: boolean;
  /**
   * Boolean value that indicates if over rule address check is enabled or not
   */
  enableAddressCheckOverruling: boolean;
  /**
   * Properties that will be passed in customer registration as a dictionary
   */
  properties: {[key: string]: fromApiExtraModels.AppSettingsClassificationItemDto };
  /**
   * Additional customer properties that will be passed in customer registration as a dictionary
   */
  customerProperties: {[key: string]: fromApiExtraModels.AppSettingsClassificationItemDto };
  /**
   * Mapping of applicable Telefonica customer consents
   */
  customerConsents: {[key: string]: fromApiExtraModels.AppSettingsConsentItemDto };
  /**
   * Returns the current version
   */
  clientVersion: string;
  /**
   * Array of numbers for validating msisdn
   */
  blacklistMSISDN: string[];
  /**
   * Array of blacklist names for validating firstName and lastName
   */
  blacklistNames: string[];
  /**
   * ISO characters available for using in customer form
   */
  whiteListCharacters: string;
}


/**
 * AppSettingsResponseDto
 *  see IAppSettingsResponseDto
 */
export class AppSettingsResponseDto {
  /**
   * Array of value/name pairs for sales partner classification including active flag.
   */
  readonly salesPartnerClassification: AppSettingsSalesPartnerClassifciationItemDto[];
  readonly neotelManagementConfig?: AppSettingsNeotelManagementConfigDto;
  readonly invalidCharactersConfig: AppSettingsInvalidCharactersDto;
  readonly addressAutocompletion: AppSettingsAddressAutocompletionDto;
  /**
   * RegularExpression presented as string of valid characters
   */
  readonly streetnameAllowedCharacters: string;
  /**
   * RegularExpression presented as string of valid characters
   */
  readonly housenoAllowedCharacters: string;
  /**
   * Boolean value that indicates if payment method can be chosen or not
   */
  readonly choosePaymentMethod: boolean;
  /**
   * Boolean value that indicates if bank data entry should be shown or not
   */
  readonly showBankdataEntry: boolean;
  /**
   * Boolean value that indicates if button for patching demo data will be visible or not
   */
  readonly demoButton: boolean;
  /**
   * Boolean value which indicates if newsletter section should be displayed or not
   */
  readonly showNewsletterSection: boolean;
  /**
   * Boolean value which indicates if third salutation should be displayed or not
   */
  readonly show3rdSalutation: boolean;
  /**
   * Boolean value that indicates if over rule address check is enabled or not
   */
  readonly enableAddressCheckOverruling: boolean;
  /**
   * Properties that will be passed in customer registration as a dictionary
   */
  readonly properties: {[key: string]:fromApiExtraModels.AppSettingsClassificationItemDto };
  /**
   * Additional customer properties that will be passed in customer registration as a dictionary
   */
  readonly customerProperties: {[key: string]:fromApiExtraModels.AppSettingsClassificationItemDto };
  /**
   * Mapping of applicable Telefonica customer consents
   */
  readonly customerConsents: {[key: string]:fromApiExtraModels.AppSettingsConsentItemDto };
  /**
   * Returns the current version
   */
  readonly clientVersion: string;
  /**
   * Array of numbers for validating msisdn
   */
  readonly blacklistMSISDN: string[];
  /**
   * Array of blacklist names for validating firstName and lastName
   */
  readonly blacklistNames: string[];
  /**
   * ISO characters available for using in customer form
   */
  readonly whiteListCharacters: string;

  constructor(options: {
    salesPartnerClassification: AppSettingsSalesPartnerClassifciationItemDto[];
    neotelManagementConfig?: AppSettingsNeotelManagementConfigDto;
    invalidCharactersConfig: AppSettingsInvalidCharactersDto;
    addressAutocompletion: AppSettingsAddressAutocompletionDto;
    streetnameAllowedCharacters: string;
    housenoAllowedCharacters: string;
    choosePaymentMethod: boolean;
    showBankdataEntry: boolean;
    demoButton: boolean;
    showNewsletterSection: boolean;
    show3rdSalutation: boolean;
    enableAddressCheckOverruling: boolean;
    properties: {[key: string]: fromApiExtraModels.AppSettingsClassificationItemDto };
    customerProperties: {[key: string]: fromApiExtraModels.AppSettingsClassificationItemDto };
    customerConsents: {[key: string]: fromApiExtraModels.AppSettingsConsentItemDto };
    clientVersion: string;
    blacklistMSISDN: string[];
    blacklistNames: string[];
    whiteListCharacters: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.salesPartnerClassification = new Array<AppSettingsSalesPartnerClassifciationItemDto>();
    if (options !== undefined && options !== null && options.salesPartnerClassification !== undefined && options.salesPartnerClassification !== null) {
      for (const item of options.salesPartnerClassification) {
        this.salesPartnerClassification.push(new AppSettingsSalesPartnerClassifciationItemDto(item, config));
      }
    }
    this.neotelManagementConfig =
      (options !== undefined && options !== null && options.neotelManagementConfig !== undefined && options.neotelManagementConfig !== null) ?
        new AppSettingsNeotelManagementConfigDto(options.neotelManagementConfig, config) :
        (config.viewModel === true) ? (config.hierarchy === true ? new AppSettingsNeotelManagementConfigDto( null, config) : null ) : (config.hierarchy === true ? new AppSettingsNeotelManagementConfigDto( null, config) : undefined );
    this.invalidCharactersConfig =
      (options !== undefined && options !== null && options.invalidCharactersConfig !== undefined && options.invalidCharactersConfig !== null) ?
        new AppSettingsInvalidCharactersDto(options.invalidCharactersConfig, config) :
        null;
    this.addressAutocompletion =
      (options !== undefined && options !== null && options.addressAutocompletion !== undefined && options.addressAutocompletion !== null) ?
        new AppSettingsAddressAutocompletionDto(options.addressAutocompletion, config) :
        null;
    this.streetnameAllowedCharacters =
      (options !== undefined && options !== null && options.streetnameAllowedCharacters !== undefined && options.streetnameAllowedCharacters !== null) ?
        options.streetnameAllowedCharacters + '' :
       null;
    this.housenoAllowedCharacters =
      (options !== undefined && options !== null && options.housenoAllowedCharacters !== undefined && options.housenoAllowedCharacters !== null) ?
        options.housenoAllowedCharacters + '' :
       null;
    this.choosePaymentMethod = (options !== undefined && options !== null && options.choosePaymentMethod !== undefined && options.choosePaymentMethod !== null) ?
        !!options.choosePaymentMethod :
       null;
    this.showBankdataEntry = (options !== undefined && options !== null && options.showBankdataEntry !== undefined && options.showBankdataEntry !== null) ?
        !!options.showBankdataEntry :
       null;
    this.demoButton = (options !== undefined && options !== null && options.demoButton !== undefined && options.demoButton !== null) ?
        !!options.demoButton :
       null;
    this.showNewsletterSection = (options !== undefined && options !== null && options.showNewsletterSection !== undefined && options.showNewsletterSection !== null) ?
        !!options.showNewsletterSection :
       null;
    this.show3rdSalutation = (options !== undefined && options !== null && options.show3rdSalutation !== undefined && options.show3rdSalutation !== null) ?
        !!options.show3rdSalutation :
       null;
    this.enableAddressCheckOverruling = (options !== undefined && options !== null && options.enableAddressCheckOverruling !== undefined && options.enableAddressCheckOverruling !== null) ?
        !!options.enableAddressCheckOverruling :
       null;
    this.properties =
      (options !== undefined && options !== null && options.properties !== undefined && options.properties !== null) ?
        { ...options.properties} :
        null;
    this.customerProperties =
      (options !== undefined && options !== null && options.customerProperties !== undefined && options.customerProperties !== null) ?
        { ...options.customerProperties} :
        null;
    this.customerConsents =
      (options !== undefined && options !== null && options.customerConsents !== undefined && options.customerConsents !== null) ?
        { ...options.customerConsents} :
        null;
    this.clientVersion =
      (options !== undefined && options !== null && options.clientVersion !== undefined && options.clientVersion !== null) ?
        options.clientVersion + '' :
       null;
    this.blacklistMSISDN = new Array<string>();
    if (options !== undefined && options !== null && options.blacklistMSISDN !== undefined && options.blacklistMSISDN !== null) {
      for (const item of options.blacklistMSISDN) {
        this.blacklistMSISDN.push(item);
      }
    }
    this.blacklistNames = new Array<string>();
    if (options !== undefined && options !== null && options.blacklistNames !== undefined && options.blacklistNames !== null) {
      for (const item of options.blacklistNames) {
        this.blacklistNames.push(item);
      }
    }
    this.whiteListCharacters =
      (options !== undefined && options !== null && options.whiteListCharacters !== undefined && options.whiteListCharacters !== null) ?
        options.whiteListCharacters + '' :
       null;
  }
}


/**
 * IKeywordTypeDto
 */
export interface IKeywordTypeDto {
  name: string;
  value: string;
}


/**
 * KeywordTypeDto
 *  see IKeywordTypeDto
 */
export class KeywordTypeDto {
  readonly name: string;
  readonly value: string;

  constructor(options: {
    name: string;
    value: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       null;
    this.value =
      (options !== undefined && options !== null && options.value !== undefined && options.value !== null) ?
        options.value + '' :
       null;
  }
}


/**
 * IFileAttachmentTypeDto
 */
export interface IFileAttachmentTypeDto {
  /**
   * File name incl.
   * extension.
   * - minLength: 0
   * - maxLength: 1024
   */
  name?: string;
  content?: string;
  /**
   * Enum, one of:
   * - image/png
   * - image/jpeg
   * - text/csv
   * - text/plain
   * - message/rfc822
   * - application/pdf
   * - application/octet-stream
   * - application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   * - application/vnd.ms-excel
   * - application/json
   * - application/zip
   */
  contentType?: string;
  contentSize: number;
  /**
   * mapped to keywording/maskNameOriginal (e.g.
   * Auftragsanschreiben, Verkauf, SEPA Lastschrift, Sales Partner Dokument)
   */
  documentCategory?: string;
  /**
   * mapped to keywording/fields/DOKUMENTTYP, e.g.
   */
  documentType?: string;
  /**
   * mapped to info/dateCustom
   */
  documentDateTime?: string;
  /**
   * mapped to info/dateCustom if no documentDate is present
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  documentSignatureDate?: string;
  /**
   * mapped to info/desc
   */
  description?: string;
  /**
   * Type for strings in UUID format.
   */
  uuid?: string;
  /**
   * - minItems: 0
   */
  keyword?: IKeywordTypeDto[];
}


/**
 * FileAttachmentTypeDto
 *  see IFileAttachmentTypeDto
 */
export class FileAttachmentTypeDto {
  /**
   * File name incl.
   * extension.
   * - minLength: 0
   * - maxLength: 1024
   */
  readonly name?: string;
  readonly content?: string;
  /**
   * Enum, one of:
   * - image/png
   * - image/jpeg
   * - text/csv
   * - text/plain
   * - message/rfc822
   * - application/pdf
   * - application/octet-stream
   * - application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   * - application/vnd.ms-excel
   * - application/json
   * - application/zip
   */
  readonly contentType?: string;
  readonly contentSize: number;
  /**
   * mapped to keywording/maskNameOriginal (e.g.
   * Auftragsanschreiben, Verkauf, SEPA Lastschrift, Sales Partner Dokument)
   */
  readonly documentCategory?: string;
  /**
   * mapped to keywording/fields/DOKUMENTTYP, e.g.
   */
  readonly documentType?: string;
  /**
   * mapped to info/dateCustom
   */
  readonly documentDateTime?: string;
  /**
   * mapped to info/dateCustom if no documentDate is present
   * - date: Pattern /^\d{4}-\d{2}-\d{2}$/ applies
   */
  readonly documentSignatureDate?: string;
  /**
   * mapped to info/desc
   */
  readonly description?: string;
  /**
   * Type for strings in UUID format.
   */
  readonly uuid?: string;
  /**
   * - minItems: 0
   */
  readonly keyword?: KeywordTypeDto[];

  constructor(options: {
    name?: string;
    content?: string;
    contentType?: string;
    contentSize: number;
    documentCategory?: string;
    documentType?: string;
    documentDateTime?: string;
    documentSignatureDate?: string;
    description?: string;
    uuid?: string;
    keyword?: KeywordTypeDto[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.name =
      (options !== undefined && options !== null && options.name !== undefined && options.name !== null) ?
        options.name + '' :
       (config.viewModel === true) ? null : undefined;
    this.content =
      (options !== undefined && options !== null && options.content !== undefined && options.content !== null) ?
        options.content + '' :
       (config.viewModel === true) ? null : undefined;
    this.contentType =
      (options !== undefined && options !== null && options.contentType !== undefined && options.contentType !== null) ?
        options.contentType + '' :
       (config.viewModel === true) ? null : undefined;
    this.contentSize =
      (options !== undefined && options !== null && options.contentSize !== undefined && options.contentSize !== null) ?
        Number(options.contentSize) :
       null;
    this.documentCategory =
      (options !== undefined && options !== null && options.documentCategory !== undefined && options.documentCategory !== null) ?
        options.documentCategory + '' :
       (config.viewModel === true) ? null : undefined;
    this.documentType =
      (options !== undefined && options !== null && options.documentType !== undefined && options.documentType !== null) ?
        options.documentType + '' :
       (config.viewModel === true) ? null : undefined;
    this.documentDateTime =
      (options !== undefined && options !== null && options.documentDateTime !== undefined && options.documentDateTime !== null) ?
        options.documentDateTime + '' :
       (config.viewModel === true) ? null : undefined;
    this.documentSignatureDate =
      (options !== undefined && options !== null && options.documentSignatureDate !== undefined && options.documentSignatureDate !== null) ?
        options.documentSignatureDate + '' :
       (config.viewModel === true) ? null : undefined;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.uuid =
      (options !== undefined && options !== null && options.uuid !== undefined && options.uuid !== null) ?
        options.uuid + '' :
       (config.viewModel === true) ? null : undefined;
    this.keyword = new Array<KeywordTypeDto>();
    if (options !== undefined && options !== null && options.keyword !== undefined && options.keyword !== null) {
      for (const item of options.keyword) {
        this.keyword.push(new KeywordTypeDto(item, config));
      }
    }
  }
}


/**
 * IDi18nMessageFileHeaderDto
 */
export interface IDi18nMessageFileHeaderDto {
  /**
   * UUID of this recoprd (file or other source)
   */
  id: string;
  /**
   * Timestamp (JS Date string or JS Date ISO string), should reflect application extraction date
   */
  ts: string;
  /**
   * Name of the program which created the file
   */
  creator?: string;
  /**
   * Origin locale; the locale received from application development
   */
  locale: string;
  /**
   * Project name
   */
  project?: string;
  /**
   * Description of file contents
   */
  description?: string;
  /**
   * Version string
   */
  version: string;
  /**
   * Build number
   */
  build?: number;
  /**
   * Includes/supported locales on allocation level.
   * Properties toolkitLocaleOrigin and toolkitLocalesTarget may restrict this for DI18N-Toolikt use
   */
  locales: string[];
  /**
   * File name
   */
  filename?: string;
  /**
   * Name of the person which edited the file
   */
  editor?: string;
  /**
   * Origin locale for for DI18N-Toolikt use, must be out of locales and all messages need to be defined for this locale.
   * Ideally to be set as locale
   */
  toolkitLocaleOrigin?: string;
  /**
   * Optional target locales for DI18N-Toolikt use, must be out of locales
   */
  toolkitLocalesTarget?: string[];
  /**
   * DI18N-Toolkit allows to edit/modify origin locale
   */
  toolkitAllowEditOrigin?: boolean;
  /**
   * Revision number of the record, used for optimitic locking, If not set, 0 is assumed, increasing.
   * Automaticaly maintained on write.
   */
  revision: number;
  /**
   * Optional revision timestamp as ISO string; If undefined, either file timestamp or header timestamp.
   * Automatically maintained on write.
   */
  revisionTs: string;
  /**
   * Optional revision message
   */
  revisionMessage?: string;
  /**
   * Optional revision username, name of the user which have saved this reviision
   */
  revisionUsername?: string;
}


/**
 * Di18nMessageFileHeaderDto
 *  see IDi18nMessageFileHeaderDto
 */
export class Di18nMessageFileHeaderDto {
  /**
   * UUID of this recoprd (file or other source)
   */
  readonly id: string;
  /**
   * Timestamp (JS Date string or JS Date ISO string), should reflect application extraction date
   */
  readonly ts: string;
  /**
   * Name of the program which created the file
   */
  readonly creator?: string;
  /**
   * Origin locale; the locale received from application development
   */
  readonly locale: string;
  /**
   * Project name
   */
  readonly project?: string;
  /**
   * Description of file contents
   */
  readonly description?: string;
  /**
   * Version string
   */
  readonly version: string;
  /**
   * Build number
   */
  readonly build?: number;
  /**
   * Includes/supported locales on allocation level.
   * Properties toolkitLocaleOrigin and toolkitLocalesTarget may restrict this for DI18N-Toolikt use
   */
  readonly locales: string[];
  /**
   * File name
   */
  readonly filename?: string;
  /**
   * Name of the person which edited the file
   */
  readonly editor?: string;
  /**
   * Origin locale for for DI18N-Toolikt use, must be out of locales and all messages need to be defined for this locale.
   * Ideally to be set as locale
   */
  readonly toolkitLocaleOrigin?: string;
  /**
   * Optional target locales for DI18N-Toolikt use, must be out of locales
   */
  readonly toolkitLocalesTarget?: string[];
  /**
   * DI18N-Toolkit allows to edit/modify origin locale
   */
  readonly toolkitAllowEditOrigin?: boolean;
  /**
   * Revision number of the record, used for optimitic locking, If not set, 0 is assumed, increasing.
   * Automaticaly maintained on write.
   */
  readonly revision: number;
  /**
   * Optional revision timestamp as ISO string; If undefined, either file timestamp or header timestamp.
   * Automatically maintained on write.
   */
  readonly revisionTs: string;
  /**
   * Optional revision message
   */
  readonly revisionMessage?: string;
  /**
   * Optional revision username, name of the user which have saved this reviision
   */
  readonly revisionUsername?: string;

  constructor(options: {
    id: string;
    ts: string;
    creator?: string;
    locale: string;
    project?: string;
    description?: string;
    version: string;
    build?: number;
    locales: string[];
    filename?: string;
    editor?: string;
    toolkitLocaleOrigin?: string;
    toolkitLocalesTarget?: string[];
    toolkitAllowEditOrigin?: boolean;
    revision: number;
    revisionTs: string;
    revisionMessage?: string;
    revisionUsername?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        options.id + '' :
       null;
    this.ts =
      (options !== undefined && options !== null && options.ts !== undefined && options.ts !== null) ?
        options.ts + '' :
       null;
    this.creator =
      (options !== undefined && options !== null && options.creator !== undefined && options.creator !== null) ?
        options.creator + '' :
       (config.viewModel === true) ? null : undefined;
    this.locale =
      (options !== undefined && options !== null && options.locale !== undefined && options.locale !== null) ?
        options.locale + '' :
       null;
    this.project =
      (options !== undefined && options !== null && options.project !== undefined && options.project !== null) ?
        options.project + '' :
       (config.viewModel === true) ? null : undefined;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.version =
      (options !== undefined && options !== null && options.version !== undefined && options.version !== null) ?
        options.version + '' :
       null;
    this.build =
      (options !== undefined && options !== null && options.build !== undefined && options.build !== null) ?
        Number(options.build) :
       (config.viewModel === true) ? null : undefined;
    this.locales = new Array<string>();
    if (options !== undefined && options !== null && options.locales !== undefined && options.locales !== null) {
      for (const item of options.locales) {
        this.locales.push(item);
      }
    }
    this.filename =
      (options !== undefined && options !== null && options.filename !== undefined && options.filename !== null) ?
        options.filename + '' :
       (config.viewModel === true) ? null : undefined;
    this.editor =
      (options !== undefined && options !== null && options.editor !== undefined && options.editor !== null) ?
        options.editor + '' :
       (config.viewModel === true) ? null : undefined;
    this.toolkitLocaleOrigin =
      (options !== undefined && options !== null && options.toolkitLocaleOrigin !== undefined && options.toolkitLocaleOrigin !== null) ?
        options.toolkitLocaleOrigin + '' :
       (config.viewModel === true) ? null : undefined;
    this.toolkitLocalesTarget = new Array<string>();
    if (options !== undefined && options !== null && options.toolkitLocalesTarget !== undefined && options.toolkitLocalesTarget !== null) {
      for (const item of options.toolkitLocalesTarget) {
        this.toolkitLocalesTarget.push(item);
      }
    }
    this.toolkitAllowEditOrigin = (options !== undefined && options !== null && options.toolkitAllowEditOrigin !== undefined && options.toolkitAllowEditOrigin !== null) ?
        !!options.toolkitAllowEditOrigin :
       (config.viewModel === true) ? null : undefined;
    this.revision =
      (options !== undefined && options !== null && options.revision !== undefined && options.revision !== null) ?
        Number(options.revision) :
       null;
    this.revisionTs =
      (options !== undefined && options !== null && options.revisionTs !== undefined && options.revisionTs !== null) ?
        options.revisionTs + '' :
       null;
    this.revisionMessage =
      (options !== undefined && options !== null && options.revisionMessage !== undefined && options.revisionMessage !== null) ?
        options.revisionMessage + '' :
       (config.viewModel === true) ? null : undefined;
    this.revisionUsername =
      (options !== undefined && options !== null && options.revisionUsername !== undefined && options.revisionUsername !== null) ?
        options.revisionUsername + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IDi18nMessageFileDto
 */
export interface IDi18nMessageFileDto {
  header: IDi18nMessageFileHeaderDto;
  /**
   * DI18N messages as dictionary, by id
   */
  messages: {[key: string]: fromApiExtraModels.IDi18nMessageFileMessageDto };
}


/**
 * Di18nMessageFileDto
 *  see IDi18nMessageFileDto
 */
export class Di18nMessageFileDto {
  readonly header: Di18nMessageFileHeaderDto;
  /**
   * DI18N messages as dictionary, by id
   */
  readonly messages: {[key: string]:fromApiExtraModels.IDi18nMessageFileMessageDto };

  constructor(options: {
    header: Di18nMessageFileHeaderDto;
    messages: {[key: string]: fromApiExtraModels.IDi18nMessageFileMessageDto };
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.header =
      (options !== undefined && options !== null && options.header !== undefined && options.header !== null) ?
        new Di18nMessageFileHeaderDto(options.header, config) :
        null;
    this.messages =
      (options !== undefined && options !== null && options.messages !== undefined && options.messages !== null) ?
        { ...options.messages} :
        null;
  }
}


/**
 * IDi18nListMessagesResponseType
 */
export interface IDi18nListMessagesResponseType {
  headers: IDi18nMessageFileHeaderDto[];
}


/**
 * Di18nListMessagesResponseType
 *  see IDi18nListMessagesResponseType
 */
export class Di18nListMessagesResponseType {
  readonly headers: Di18nMessageFileHeaderDto[];

  constructor(options: {
    headers: Di18nMessageFileHeaderDto[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.headers = new Array<Di18nMessageFileHeaderDto>();
    if (options !== undefined && options !== null && options.headers !== undefined && options.headers !== null) {
      for (const item of options.headers) {
        this.headers.push(new Di18nMessageFileHeaderDto(item, config));
      }
    }
  }
}


/**
 * IDi18nFileHeaderType
 */
export interface IDi18nFileHeaderType {
  /**
   * UUID of this recoprd (file or other source)
   */
  id: string;
  /**
   * Timestamp (JS Date string or JS Date ISO string), should reflect application extraction date
   */
  ts: string;
  /**
   * Name of the program which created the file
   */
  creator?: string;
  /**
   * Origin locale; the locale received from application development
   */
  locale: string;
  /**
   * Project name
   */
  project?: string;
  /**
   * Description of file contents
   */
  description?: string;
  /**
   * Version string
   */
  version: string;
  /**
   * Build number
   */
  build?: number;
  /**
   * Includes/supported locales on allication level.
   * Properties toolkitLocaleOrigin and toolkitLocalesTarget may restrict this for DI18N-Toolikt use
   */
  locales: string[];
  /**
   * File name
   */
  filename?: string;
  /**
   * Name of the person which edited the file
   */
  editor?: string;
  /**
   * Origin locale for for DI18N-Toolikt use, must be out of locales and all messages need to be defined for this locale.
   * Ideally to be set as locale
   */
  toolkitLocaleOrigin?: string;
  /**
   * Optional target locales for DI18N-Toolikt use, must be out of locales
   */
  toolkitLocalesTarget?: string[];
  /**
   * DI18N-Toolkit allows to edit/modify origin locale
   */
  toolkitAllowEditOrigin?: boolean;
  /**
   * Revision number of the record, used for optimitic locking, If not set, 0 is assumed, increasing.
   * Automaticaly maintained on write.
   */
  revision: number;
  /**
   * Optional revision timestamp as ISO string; If undefined, either file timestamp or header timestamp.
   * Automatically maintained on write.
   */
  revisionTs: string;
  /**
   * Optional revision message
   */
  revisionMessage?: string;
  /**
   * Optional revision username, name of the user which have saved this reviision
   */
  revisionUsername?: string;
}


/**
 * Di18nFileHeaderType
 *  see IDi18nFileHeaderType
 */
export class Di18nFileHeaderType {
  /**
   * UUID of this recoprd (file or other source)
   */
  readonly id: string;
  /**
   * Timestamp (JS Date string or JS Date ISO string), should reflect application extraction date
   */
  readonly ts: string;
  /**
   * Name of the program which created the file
   */
  readonly creator?: string;
  /**
   * Origin locale; the locale received from application development
   */
  readonly locale: string;
  /**
   * Project name
   */
  readonly project?: string;
  /**
   * Description of file contents
   */
  readonly description?: string;
  /**
   * Version string
   */
  readonly version: string;
  /**
   * Build number
   */
  readonly build?: number;
  /**
   * Includes/supported locales on allication level.
   * Properties toolkitLocaleOrigin and toolkitLocalesTarget may restrict this for DI18N-Toolikt use
   */
  readonly locales: string[];
  /**
   * File name
   */
  readonly filename?: string;
  /**
   * Name of the person which edited the file
   */
  readonly editor?: string;
  /**
   * Origin locale for for DI18N-Toolikt use, must be out of locales and all messages need to be defined for this locale.
   * Ideally to be set as locale
   */
  readonly toolkitLocaleOrigin?: string;
  /**
   * Optional target locales for DI18N-Toolikt use, must be out of locales
   */
  readonly toolkitLocalesTarget?: string[];
  /**
   * DI18N-Toolkit allows to edit/modify origin locale
   */
  readonly toolkitAllowEditOrigin?: boolean;
  /**
   * Revision number of the record, used for optimitic locking, If not set, 0 is assumed, increasing.
   * Automaticaly maintained on write.
   */
  readonly revision: number;
  /**
   * Optional revision timestamp as ISO string; If undefined, either file timestamp or header timestamp.
   * Automatically maintained on write.
   */
  readonly revisionTs: string;
  /**
   * Optional revision message
   */
  readonly revisionMessage?: string;
  /**
   * Optional revision username, name of the user which have saved this reviision
   */
  readonly revisionUsername?: string;

  constructor(options: {
    id: string;
    ts: string;
    creator?: string;
    locale: string;
    project?: string;
    description?: string;
    version: string;
    build?: number;
    locales: string[];
    filename?: string;
    editor?: string;
    toolkitLocaleOrigin?: string;
    toolkitLocalesTarget?: string[];
    toolkitAllowEditOrigin?: boolean;
    revision: number;
    revisionTs: string;
    revisionMessage?: string;
    revisionUsername?: string;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.id =
      (options !== undefined && options !== null && options.id !== undefined && options.id !== null) ?
        options.id + '' :
       null;
    this.ts =
      (options !== undefined && options !== null && options.ts !== undefined && options.ts !== null) ?
        options.ts + '' :
       null;
    this.creator =
      (options !== undefined && options !== null && options.creator !== undefined && options.creator !== null) ?
        options.creator + '' :
       (config.viewModel === true) ? null : undefined;
    this.locale =
      (options !== undefined && options !== null && options.locale !== undefined && options.locale !== null) ?
        options.locale + '' :
       null;
    this.project =
      (options !== undefined && options !== null && options.project !== undefined && options.project !== null) ?
        options.project + '' :
       (config.viewModel === true) ? null : undefined;
    this.description =
      (options !== undefined && options !== null && options.description !== undefined && options.description !== null) ?
        options.description + '' :
       (config.viewModel === true) ? null : undefined;
    this.version =
      (options !== undefined && options !== null && options.version !== undefined && options.version !== null) ?
        options.version + '' :
       null;
    this.build =
      (options !== undefined && options !== null && options.build !== undefined && options.build !== null) ?
        Number(options.build) :
       (config.viewModel === true) ? null : undefined;
    this.locales = new Array<string>();
    if (options !== undefined && options !== null && options.locales !== undefined && options.locales !== null) {
      for (const item of options.locales) {
        this.locales.push(item);
      }
    }
    this.filename =
      (options !== undefined && options !== null && options.filename !== undefined && options.filename !== null) ?
        options.filename + '' :
       (config.viewModel === true) ? null : undefined;
    this.editor =
      (options !== undefined && options !== null && options.editor !== undefined && options.editor !== null) ?
        options.editor + '' :
       (config.viewModel === true) ? null : undefined;
    this.toolkitLocaleOrigin =
      (options !== undefined && options !== null && options.toolkitLocaleOrigin !== undefined && options.toolkitLocaleOrigin !== null) ?
        options.toolkitLocaleOrigin + '' :
       (config.viewModel === true) ? null : undefined;
    this.toolkitLocalesTarget = new Array<string>();
    if (options !== undefined && options !== null && options.toolkitLocalesTarget !== undefined && options.toolkitLocalesTarget !== null) {
      for (const item of options.toolkitLocalesTarget) {
        this.toolkitLocalesTarget.push(item);
      }
    }
    this.toolkitAllowEditOrigin = (options !== undefined && options !== null && options.toolkitAllowEditOrigin !== undefined && options.toolkitAllowEditOrigin !== null) ?
        !!options.toolkitAllowEditOrigin :
       (config.viewModel === true) ? null : undefined;
    this.revision =
      (options !== undefined && options !== null && options.revision !== undefined && options.revision !== null) ?
        Number(options.revision) :
       null;
    this.revisionTs =
      (options !== undefined && options !== null && options.revisionTs !== undefined && options.revisionTs !== null) ?
        options.revisionTs + '' :
       null;
    this.revisionMessage =
      (options !== undefined && options !== null && options.revisionMessage !== undefined && options.revisionMessage !== null) ?
        options.revisionMessage + '' :
       (config.viewModel === true) ? null : undefined;
    this.revisionUsername =
      (options !== undefined && options !== null && options.revisionUsername !== undefined && options.revisionUsername !== null) ?
        options.revisionUsername + '' :
       (config.viewModel === true) ? null : undefined;
  }
}


/**
 * IDi18nProjectVersion
 */
export interface IDi18nProjectVersion {
  /**
   * Project name as string
   */
  project: string;
  /**
   * Project version striung
   */
  version: string;
  headerLatest: IDi18nFileHeaderType;
  /**
   * Revisions of this file.
   * Each save creates a new revision.
   * Impacts optimistic locking.
   * Exists only if hierarchay was requested.
   */
  revisions?: IDi18nFileHeaderType[];
}


/**
 * Di18nProjectVersion
 *  see IDi18nProjectVersion
 */
export class Di18nProjectVersion {
  /**
   * Project name as string
   */
  readonly project: string;
  /**
   * Project version striung
   */
  readonly version: string;
  readonly headerLatest: Di18nFileHeaderType;
  /**
   * Revisions of this file.
   * Each save creates a new revision.
   * Impacts optimistic locking.
   * Exists only if hierarchay was requested.
   */
  readonly revisions?: Di18nFileHeaderType[];

  constructor(options: {
    project: string;
    version: string;
    headerLatest: Di18nFileHeaderType;
    revisions?: Di18nFileHeaderType[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.project =
      (options !== undefined && options !== null && options.project !== undefined && options.project !== null) ?
        options.project + '' :
       null;
    this.version =
      (options !== undefined && options !== null && options.version !== undefined && options.version !== null) ?
        options.version + '' :
       null;
    this.headerLatest =
      (options !== undefined && options !== null && options.headerLatest !== undefined && options.headerLatest !== null) ?
        new Di18nFileHeaderType(options.headerLatest, config) :
        null;
    this.revisions = new Array<Di18nFileHeaderType>();
    if (options !== undefined && options !== null && options.revisions !== undefined && options.revisions !== null) {
      for (const item of options.revisions) {
        this.revisions.push(new Di18nFileHeaderType(item, config));
      }
    }
  }
}


/**
 * IDi18nProject
 */
export interface IDi18nProject {
  /**
   * project name as string
   */
  project: string;
  headerLatest: IDi18nFileHeaderType;
  /**
   * Project versions (list).
   * Present only, if hierarchy was enabled.
   */
  versions?: IDi18nProjectVersion[];
}


/**
 * Di18nProject
 *  see IDi18nProject
 */
export class Di18nProject {
  /**
   * project name as string
   */
  readonly project: string;
  readonly headerLatest: Di18nFileHeaderType;
  /**
   * Project versions (list).
   * Present only, if hierarchy was enabled.
   */
  readonly versions?: Di18nProjectVersion[];

  constructor(options: {
    project: string;
    headerLatest: Di18nFileHeaderType;
    versions?: Di18nProjectVersion[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.project =
      (options !== undefined && options !== null && options.project !== undefined && options.project !== null) ?
        options.project + '' :
       null;
    this.headerLatest =
      (options !== undefined && options !== null && options.headerLatest !== undefined && options.headerLatest !== null) ?
        new Di18nFileHeaderType(options.headerLatest, config) :
        null;
    this.versions = new Array<Di18nProjectVersion>();
    if (options !== undefined && options !== null && options.versions !== undefined && options.versions !== null) {
      for (const item of options.versions) {
        this.versions.push(new Di18nProjectVersion(item, config));
      }
    }
  }
}


/**
 * IDi18nListProjectsResponseType
 */
export interface IDi18nListProjectsResponseType {
  projects: IDi18nProject[];
}


/**
 * Di18nListProjectsResponseType
 *  see IDi18nListProjectsResponseType
 */
export class Di18nListProjectsResponseType {
  readonly projects: Di18nProject[];

  constructor(options: {
    projects: Di18nProject[];
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.projects = new Array<Di18nProject>();
    if (options !== undefined && options !== null && options.projects !== undefined && options.projects !== null) {
      for (const item of options.projects) {
        this.projects.push(new Di18nProject(item, config));
      }
    }
  }
}


/**
 * IDi18nListProjectVersionsResponseType
 */
export interface IDi18nListProjectVersionsResponseType {
  versions: IDi18nProjectVersion[];
  headerLatest: IDi18nFileHeaderType;
}


/**
 * Di18nListProjectVersionsResponseType
 *  see IDi18nListProjectVersionsResponseType
 */
export class Di18nListProjectVersionsResponseType {
  readonly versions: Di18nProjectVersion[];
  readonly headerLatest: Di18nFileHeaderType;

  constructor(options: {
    versions: Di18nProjectVersion[];
    headerLatest: Di18nFileHeaderType;
  }, config: {viewModel?: boolean, hierarchy?: boolean} = {}) {
    this.versions = new Array<Di18nProjectVersion>();
    if (options !== undefined && options !== null && options.versions !== undefined && options.versions !== null) {
      for (const item of options.versions) {
        this.versions.push(new Di18nProjectVersion(item, config));
      }
    }
    this.headerLatest =
      (options !== undefined && options !== null && options.headerLatest !== undefined && options.headerLatest !== null) ?
        new Di18nFileHeaderType(options.headerLatest, config) :
        null;
  }
}


