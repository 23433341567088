export enum MnpCustomerType {
    PRIVATE = 'MNP_PRIVATE',
    BUSINESS = 'MNP_BUSINESS'
}

export enum MnpInquiryType {
    ASAP = 'Asap',
    END_OF_CONTRACT = 'Vertragsende'
}

export class MnpData {
    selected: boolean;
    msisdn?: string;
    providerCode?: string;
    customerType?: MnpCustomerType;
    companyName?: string;
    customerNumber?: string;
    inquiryType?: MnpInquiryType;
    serviceProviderId?: number;
}
