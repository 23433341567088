export enum ActivationErrorTypes {
  AUTHORIZATION_FAILURE = 'AUTHORIZATION_FAILURE',
  SIM_NOT_FOUND = 'SIM_NOT_FOUND',                           // EB-17531 / Requirement 3 - Scenario 1
  SIM_NOT_ALLOWED = 'SIM_NOT_ALLOWED',                       // EB-17531 / Requirement 3 - Scenario 2
  SIM_CURRENTLY_BLOCKED = 'SIM_CURRENTLY_BLOCKED',
  CUSTOMER_DATA_ALREADY_SENT = 'CUSTOMER_DATA_ALREADY_SENT', // EB-17531 / Requirement 3 - Scenario 3
  SIM_CARD_DEACTIVATED = 'SIM_CARD_DEACTIVATED',             // EB-17531 / Requirement 3 - Scenario 4
  MANUAL_CHECK_IN_PROGRESS = 'MANUAL_CHECK_IN_PROGRESS',     // EB-17531 / Requirement 3 - Scenario 5
  SIM_STATUS_PRE_INITIAL = 'SIM_STATUS_PRE_INITIAL',         // #4737 MDAT-1165
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export interface ActivationErrorsModel {
  simDataError: ActivationErrorTypes;
}
