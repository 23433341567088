import { PropertyTypeDto } from 'src/app/shared/api-types';

export interface PropertyConsentModel {
    [code: string]: PropertyTypeDto[];
}

export const initialProperties: PropertyConsentModel = {

    nutzungsprofil_bedarfsgerechte_produkte_anbieten: [
        { name: 'nutzungsprofil_bedarfsgerechte_produkte_anbieten_traffic_data', value: 'false' },
        { name: 'nutzungsprofil_bedarfsgerechte_produkte_anbieten_user_data', value: 'false' }
    ],

    bestandsdaten_alle_produkte: [
        { name: 'bestandsdaten_alle_produkte_neu_email', value: 'false' },
        { name: 'bestandsdaten_alle_produkte_neu_phone', value: 'false' },
        { name: 'bestandsdaten_alle_produkte_neu_sen', value: 'false' },
    ],

    document: [
        { name: 'selectedDocumentType', value: null},
        { name: 'selectedDocumentCategory', value: null},
        { name: 'documentValidTo', value: null},
        { name: 'documentCountryIsocode', value: null}
    ]
};
