import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, of } from 'rxjs';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { take, delay } from 'rxjs/operators';

// const uuidv4 = require('uuid/v4');
import { v4 as uuidv4 } from 'uuid';


export interface IPendingItem {
  token: string;
  ts: Date;
  releaseThreshholdMs: number;
  issuer?: string;
  sub?: Subscription;
}

export interface IPendingOptions {
  releaseThreshholdMs?: number;
  issuer?: string;
  url?: string;
}


const pendingExclusionList: string[] = [
  'services/V10/util/hello',
  'services/V10/reportClickStream',
  'services/V10/address-check/is-email',
];

@Injectable({
  providedIn: 'root'
})
export class PendingService {

  public readonly defaultReleaseThreshholdMs = 1000 * 60 * 5; // 5 minutes

  private pendingItems: {
    [token: string]: IPendingItem
  } = {};

  // Monitor this Observable to get notified on pending/busy state
  public pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    // this.pending$.subscribe (res=>{console.log ("pending$ state: ", res)})
  }

  public request(options: IPendingOptions = {}): string {
    if (options.url) {
     // console.log ('url: ', options.url.split('?')[0])
    }
    if (!isNullOrUndefined(options.url) && pendingExclusionList.indexOf(options.url.split('?')[0]) !== -1) {
      // no pending status management for this HTTP call
      return uuidv4();
    } else {
      const item: IPendingItem = {
        token: uuidv4(),
        ts: new Date(),
        releaseThreshholdMs: !isNullOrUndefined(options.releaseThreshholdMs) ? options.releaseThreshholdMs :
                                                                               this.defaultReleaseThreshholdMs,
      };
      item.sub = of(item.token).pipe(
        delay(item.releaseThreshholdMs),
        take(1)
      ).subscribe(token => {
        if (!isNullOrUndefined(this.pendingItems[token])) {
          delete this.pendingItems[token];
          const nextPending2: boolean = (Object.keys(this.pendingItems).length > 0);
          if (this.pending$.getValue() !== nextPending2) {
            this.pending$.next(nextPending2);
          }
        }
      });
      this.pendingItems[item.token] = item;
      const nextPending: boolean = (Object.keys(this.pendingItems).length > 0);
      if (this.pending$.getValue() !== nextPending) {
        this.pending$.next(nextPending);
      }
      return item.token;
    }
  }

  public release(token: string) {
    if (!isNullOrUndefined(this.pendingItems[token])) {
      if (!isNullOrUndefined(this.pendingItems[token].sub)) {
        this.pendingItems[token].sub.unsubscribe();
      }
      delete this.pendingItems[token];
      const nextPending: boolean = (Object.keys(this.pendingItems).length > 0);
      if (this.pending$.getValue() !== nextPending) {
        this.pending$.next(nextPending);
      }
    }
  }
}
