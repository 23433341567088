import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-session-expired',
  templateUrl: './prompt-session-expired.component.html',
  styleUrls: ['./prompt-session-expired.component.scss']
})
export class PromptSessionExpiredComponent implements OnInit {

  constructor(
    private ref: MatDialogRef<PromptSessionExpiredComponent>,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }

}
