import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
import { Location } from '@angular/common';

export enum envType {
  PPD = 'PPD',
  PROD = 'PROD',
  NEITHER = 'NEITHER'
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(
    // private router: Router,
    private location: Location) { }

  public environmentType(): envType {
    const url = this.location.path();
    if (url.includes('pre-simcard')) {
      return envType.PPD;
    } else if (url.includes('simcard')) {
      return envType.PROD;
    } else {
      return envType.NEITHER;
    }
  }
}
