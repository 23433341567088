import { createAction, props } from '@ngrx/store';
import { AppSettingsConfigState } from '../reducers/app-settings-config.reducer';


/**
 * action to initiate loading of AppSettingsConfig from service
 */
export const loadAppSettingsConfig = createAction(
  '[AppSettingsConfig] Load AppSettingsConfig'
);

/**
 * action created by effect on successful load of AppSettingsConfig
 */
export const loadAppSettingsConfigSuccess = createAction(
  '[AppSettingsConfig] Load AppSettingsConfig Success',
  props<{ data: AppSettingsConfigState }>()
);

/**
 * action created by effect on unsuccessful load of AppSettingsConfig
 */
export const loadAppSettingsConfigFailure = createAction(
  '[AppSettingsConfig] Load AppSettingsConfig Failure',
  props<{ error: any, data: AppSettingsConfigState }>()
);

/**
 * action to set store contents for AppSettingsConfig
 */
export const setAppSettingsConfig = createAction(
  '[AppSettingsConfig] Set AppSettingsConfig',
  props<{data: AppSettingsConfigState}>()
);

/**
 * action to delete store contents for AppSettingsConfig
 */
export const deleteAppSettingsConfig = createAction(
  '[AppSettingsConfig] Delete AppSettingsConfig'
);
