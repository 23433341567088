import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Di18nModule } from '@mvneco/di18n';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PipesModule } from '../pipes/pipes.module';
import { AuthRoutingModule } from './auth-routing.module';
import { MaintenanceContentComponent } from './components/maintenance/maintenance-content/maintenance-content.component';
import { MaintenanceTitleComponent } from './components/maintenance/maintenance-title/maintenance-title.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { PromptSessionExpiredComponent } from './popups/prompt-session-expired/prompt-session-expired.component';
import { AuthEffects } from './store/effects/auth.efects';
import * as fromAuthReducer from './store/reducers/auth.reducer';

@Injectable()
export class ShowOnInvalidErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return !!(control && control.invalid);
  }
}

registerLocaleData(localeDe, 'de');

// export async function initAuthModule(
//   httpClient: HttpClient,
//   store: Store<fromAuthReducer.AuthState>,
//   authService: AuthService
// ): Promise<boolean> {
//   return new Promise<boolean>((resolve) => {
//     httpClient.get<CustomUserModelDto>('services/V10/auth/connection').toPromise()
//       .then(
//         (res) => {
//           // console.log(res);
//           // console.log('APP_INITIALIZER, res:', res);
//           const user = transform_UserModelDto_to_UserModel(res);
//           authService.userPerms$.next(user.userPerms);
//           store.dispatch(new SessionResume(user));
//           resolve(true);
//         },
//         (err) => {
//           // console.log('APP_INITIALIZER, err:', err);
//           authService.userPerms$.next({});
//           store.dispatch(new SessionResume(null));
//           resolve(true);
//         });
//   });
// }



@NgModule({
  declarations: [
    MaintenanceComponent,
    PromptSessionExpiredComponent,
    MaintenanceTitleComponent,
    MaintenanceContentComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AuthRoutingModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule,
    StoreModule.forFeature('authFeature', fromAuthReducer.reducer, { initialState: fromAuthReducer.INITIAL_STORE_STATE }),
    EffectsModule.forFeature([AuthEffects]),
    Di18nModule
  ],
  providers: [
    DatePipe, { provide: LOCALE_ID, useValue: 'de-DE' },
  ]
})
export class AuthModule { }
