import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as appSettingsConfigState from './reducers/app-settings-config.reducer';

export const appSettingsStateFeatureKey = 'appSettingsState';

export interface State {
  config: appSettingsConfigState.AppSettingsConfigState;
}

export const reducers: ActionReducerMap<State> = {
  config: appSettingsConfigState.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
