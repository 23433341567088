import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { IAppSettingsResponseDto } from '../../shared/api-types';
// import { AppSettingsTimePeriodHintPipe } from './pipes/app-settings-time-period-hint.pipe';
import * as fromAppSettingsState from './store';
import * as fromAppSettingsConfigActions from './store/actions/app-settings-config.actions';
import { AppSettingsConfigEffects } from './store/effects/app-settings-config.effects';

// export async function initAppSettingsModule(httpClient: HttpClient, store: Store<fromAppSettingsState.State>): Promise<boolean> {
//   return new Promise<boolean>((resolve) => {
//     setTimeout(() => httpClient.get<IAppSettingsResponseDto>('services/V10/app-settings').toPromise()
//     .then(
//       (res) => {
//         store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
//           data:
//           {
//             ...res
//           }
//         }));
//         resolve(true);
//       },
//       (err) => {
//         // store.dispatch (fromAppSettingsConfigActions.deleteAppSettingsConfig());
//         store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
//           data:
//           {
//             invalidCharactersConfig: null,
//             housenoAllowedCharacters: null,
//             streetnameAllowedCharacters: null,
//             properties: {},
//             blacklistNames: [],
//             blacklistMSISDN: [],
//             whiteListCharacters: null
//           }
//         }));
//         resolve(true);
//       }), 3000)

//   });
// }

export async function initAppSettingsModule(httpClient: HttpClient, store: Store<fromAppSettingsState.State>): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    httpClient.get<IAppSettingsResponseDto>('services/V10/app-settings').toPromise()
    .then(
      (res) => {
        store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
          data:
          {
            ...res
          }
        }));
        resolve(true);
      },
      (err) => {
        // store.dispatch (fromAppSettingsConfigActions.deleteAppSettingsConfig());
        store.dispatch(fromAppSettingsConfigActions.setAppSettingsConfig({
          data:
          {
            invalidCharactersConfig: null,
            addressAutocompletion: null,
            housenoAllowedCharacters: null,
            streetnameAllowedCharacters: null,
            properties: {},
            blacklistNames: [],
            blacklistMSISDN: [],
            whiteListCharacters: null
          }
        }));
        resolve(true);
      })

  });
}

/**
 * AppSettingsModule to be imported running application
 * Not on testing: salesPartnerClassification will be an empty array
 */
@NgModule({
  declarations: [
    // AppSettingsTimePeriodHintPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromAppSettingsState.appSettingsStateFeatureKey,
      fromAppSettingsState.reducers,
      { metaReducers: fromAppSettingsState.metaReducers }
    ),
    EffectsModule.forFeature([AppSettingsConfigEffects])
  ],
  providers: 
  [
    {
      provide: APP_INITIALIZER, useFactory: (httpClient: HttpClient, store: Store<fromAppSettingsState.State>) => {
        return () => initAppSettingsModule(httpClient, store);
      },
      multi: true,
      deps: [HttpClient, Store]
    }
  ],
  exports: [
    // AppSettingsTimePeriodHintPipe
  ]
})
export class AppSettingsModule { }
