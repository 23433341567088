// tslint:disable: variable-name
/**
 * Reducer for user authentication
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { AuthErrorTypes } from '../models/auth-error.model';
import { Credentials } from '../models/credentials.model';
import { UserChangePassword } from '../models/user-change-password.model';
import { UserLostPassword } from '../models/user-lost-password.model';
import { UserModel } from '../models/user.model';


export interface AuthState {
    credentials: Credentials | null;
    user: UserModel | null;
    error: AuthErrorTypes | null;
    userChangePassword: UserChangePassword;
    userLostPassword: UserLostPassword;
    mfaTokenData: {
        origin: string | null
    };
}

export const initialAuthState: AuthState = {
    credentials: null,
    user: null,
    error: null,
    userChangePassword: {
        request: null,
        result: null
    },
    userLostPassword: {
        request: null,
        result: null
    },
    mfaTokenData: {
        origin: null
    }
};

export const INITIAL_STORE_STATE = initialAuthState;

export function reducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.CredentialsUpdate: {
            return { ...state, credentials: action.payload };
        }
        case AuthActionTypes.UserSet:
            return { ...state, user: action.payload.user };

        case AuthActionTypes.LoginSuccess:
            return { ...state, user: action.payload, error: null };

        case AuthActionTypes.LoginFailure:
            return { ...state, user: null, error: action.payload };

        case AuthActionTypes.SessionResume:
            return { ...state, user: action.payload };

        case AuthActionTypes.LogoutConfirmed:
            return initialAuthState;

        case AuthActionTypes.Auth_userLostPassword_init:
            return {
                ...state,
                userLostPassword: {
                    request: {
                        ...state.userLostPassword.request,
                        username: action.payload.username
                    },
                    result: null
                }
            };

        case AuthActionTypes.Auth_userLostPassword_cancel:
            return {
                ...state,
                userLostPassword: { ...initialAuthState.userLostPassword }
            };

        case AuthActionTypes.Auth_userLostPassword_apply:
            return {
                ...state,
                userLostPassword: {
                    request: {
                        ...state.userLostPassword.request
                    },
                    result: null
                }
            };

        case AuthActionTypes.Auth_userLostPassword_completed_ok:
            return {
                ...state,
                userLostPassword: {
                    request: {
                        ...state.userLostPassword.request,
                        username: null,
                    },
                    result: {
                        ...action.payload
                    }
                }
            };

        case AuthActionTypes.Auth_userLostPassword_completed_ko:
            return {
                ...state,
                userLostPassword: {
                    request: {
                        ...state.userLostPassword.request,
                        username: null
                    },
                    result: {
                        ...action.payload
                    }
                }
            };
        default:
            return state;
    }
}

export const select_credentials = (state: AuthState) => state.credentials;
export const select_user = (state: AuthState) => state.user;
export const select_error = (state: AuthState) => state.error;
export const select_userLostPassword = (state: AuthState) => state.userLostPassword;

export const selectAuthState = createFeatureSelector<AuthState>('authFeature');

export const selectCredentials = createSelector(
    selectAuthState,
    select_credentials
);

export const selectUser = createSelector(
    selectAuthState,
    select_user,
);
export const selectError = createSelector(
    selectAuthState,
    select_error,
);

export const selectUserLostPassword = createSelector(
    selectAuthState,
    select_userLostPassword
);

