import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompactTariffOption3TypeDto, IOptionTypeDto, PropertyTypeDto,
   SubscriptionGetCompactTariffOptions3RequestDto, SubscriptionGetCompactTariffOptions3ResponseDto } from 'src/app/shared/api-types';
import { TariffDataModel, TariffOptionGroupModel, TariffOptionModel } from '../store/models/tariff-data.model';

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogueService {

  public updateProductCatalogue$: Subject<void> = new Subject<void>();
  constructor(
    private httpClient: HttpClient,
  ) { }

  public getProductCatalogue(sim: string): Observable<TariffDataModel> {
    const req: SubscriptionGetCompactTariffOptions3RequestDto = {
      sim,
    };

    // console.log(req);

    return this.httpClient.post<SubscriptionGetCompactTariffOptions3ResponseDto>('services/V10/aldi-talk-internet-service-service/subscriptionGetCompactTariffOptions3', req).pipe(
      map((res: SubscriptionGetCompactTariffOptions3ResponseDto) => this.mapCompactTariffOptions3(res))
    );
  }

  public updateProductCatalogue(req): Observable<TariffDataModel> {


    // console.log(req);

    return this.httpClient.post<SubscriptionGetCompactTariffOptions3ResponseDto>('services/V10/aldi-talk-internet-service-service/subscriptionGetCompactTariffOptions3', req).pipe(
      map((res: SubscriptionGetCompactTariffOptions3ResponseDto) => this.mapCompactTariffOptions3(res))
    );
  }

  private mapCompactTariffOptions3(res: SubscriptionGetCompactTariffOptions3ResponseDto): TariffDataModel {
    if (isNullOrUndefined(res.productBundle)) {
      throw new Error('subscriptionGetCompactTariffOptions3: productBundle missing in the response');
    }

    const tariffData: TariffDataModel = {
      tenantId: res.productBundle.tariff.tenantId,
      tariffCode: res.productBundle.tariff.code,
      name: res.productBundle.tariff.name,
      hasOptionSelected: false,
      tariffOptionGroups: {},
      promotionText: res.productBundle?.promotiontext,
      infoText: res.productBundle?.infotext
      // for testing
      // promotionText: !isNullOrUndefined(res.productBundle.promotiontext) ? res.productBundle.promotiontext :
      //  'DEMO <span style="color:red;">PROMOTION</span> TEXT, <a href="https://www.advenage.com/">LINK</a>',
      // infoText: !isNullOrUndefined(res.productBundle.infotext) ? res.productBundle.infotext : 'DEMO INFO TEXT'
    };

    if (isNullOrUndefined(res.compactTariffOptions) || isNullOrUndefined(res.compactTariffOptions.compactTariffOption)) {
      return tariffData;
    }

    res.compactTariffOptions.compactTariffOption
      .map((option: CompactTariffOption3TypeDto) => this.map_CompactTariffOption3TypeDto_Option(option))
      .forEach((tariffOption: TariffOptionModel) => {
        if (isNullOrUndefined(tariffData.tariffOptionGroups[tariffOption.groupCode])) {
          const newTariffOptionGroup: TariffOptionGroupModel = {
            code: tariffOption.groupCode,
            name: tariffOption.groupName,
            type: tariffOption.groupType,
            hasOptionSelected: false,
            tariffOptions: {}
          };
          newTariffOptionGroup.tariffOptions[tariffOption.code] = tariffOption;
          tariffData.tariffOptionGroups[newTariffOptionGroup.code] = newTariffOptionGroup;
        } else {
          tariffData.tariffOptionGroups[tariffOption.groupCode].tariffOptions[tariffOption.code] = tariffOption;
        }
      });

      // console.log('tariffData', tariffData);
    return tariffData;
  }

  private map_CompactTariffOption3TypeDto_Option(compactTariffOption: CompactTariffOption3TypeDto): TariffOptionModel {

    let groupName: string = null;
    let groupType: string = null;
    let marketingTitle: string = null;
    let marketingBulletPoint: string = null;
    let isVisible = false;
    let selectedByDefault = false;

    if (!isNullOrUndefined(compactTariffOption.properties) && !isNullOrUndefined(compactTariffOption.properties.property)) {
      compactTariffOption.properties.property.forEach((property: PropertyTypeDto) => {
        switch (property.name) {
          case 'DisplayGroup':
            const values: string[] = property.value.split(':');
            if (values.length >= 2) {
              groupName = values[0];
              groupType = values[1];
            }
            break;
          case 'MARKETINGTITLE':
            marketingTitle = property.value;
            break;
          case 'MARKETINGBULLETPOINT':
            marketingBulletPoint = property.value;
            break;
          case 'PresentationContext':
            if (property.value === 'CRM#all') {
              isVisible = true;
            }
            break;
          default:
            break;
        }
      });
    }

    // tslint:disable-next-line: max-line-length
    selectedByDefault = !isNullOrUndefined(compactTariffOption.properties) && compactTariffOption.properties.property.some(prop => prop.name === 'selectedByDefault') &&
    compactTariffOption?.properties?.property?.filter(prop => prop.name === 'selectedByDefault')[0]?.value === 'true';
    return { ...compactTariffOption,
      groupName,
      groupType,
      marketingTitle,
      marketingBulletPoint,
      isVisible,
      isSelected: false,
      selectedByDefault
    };

  }

}
