import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { ClickService } from './modules/activation/services/click.service';
import { ConfigService } from './services/config.service';
import { PendingService } from './services/pending.service';
import { SettingsService } from './services/settings.service';
import { AppFeaturesService } from '@mvneco/app-features';
import { DisableScreenService } from './services/disable-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('footnote_accordion') footnoteAccordion: ElementRef;
  @ViewChild('footnote_link') footnoteLink: ElementRef;
  @ViewChild('header') header: ElementRef;

  @ViewChildren('footnote') footnotes: QueryList<ElementRef>;

  private subscriptions: Subscription[] = new Array<Subscription>();
  public appTitle: string;

  public pending$ = this.pendingService.pending$.pipe(delay(0));
  // public customClass$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  // public loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // public dataProtectionNoticeLink = $localize`:xdi18ntagged|AppComponent@@663be05d-235a-4217-9992-5177c4cfd67f:https://www.alditalk.de/datenschutzmerkblatt`;
  // public impressumLink = $localize`:xdi18ntagged|AppComponent@@a3af4506-5ca2-4759-94c1-e354b14f5d08:https://www.alditalk.de/impressum`;
  
  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private pendingService: PendingService,
    private clickService: ClickService,
    private viewportScroller: ViewportScroller,
    private router: Router,
    public appFeatures: AppFeaturesService,
    private activatedRoute: ActivatedRoute, // EB-17713
    private disableScreenService: DisableScreenService, // EB-17970

  ) {
      router.events.pipe(
      filter(event => event instanceof NavigationEnd)).
      subscribe((event: NavigationEnd) => {
        // if (!isNullOrUndefined(event) && !isNullOrUndefined(event.url) && event.url.endsWith('confirmIdentity')) {
        //     this.customClass$.next(true);
        // }
        // else
        // {
        //   this.customClass$.next(false);
        // }

        // this.loaded$.next(true);
        // Angular v2-v6
        window.scroll(0, 0);
        // Angular v7+
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  get disableClick$() { // EB-17970
    return this.disableScreenService.disableScreen$;
  }

  ngOnInit() {

    // If the script isn't loaded in this way,
    // JQuery functions and other code will not work!!!
    this.loadScript('assets/js/alditalk_responsive_4-1-0v1.js');

    // this.subscriptions.push(
    //   this.disableScreenService.disableScreen$.pipe(
    //     tap(res => {
    //       if (res === true) {

    //       }
    //     })
    //   ).subscribe()
    // );

  }

  ngAfterViewInit() {


    this.viewportScroller.setOffset([0, this.header.nativeElement.offsetHeight]);

    this.subscriptions.push(this.clickService.clickSubject.pipe(
      filter(res => !isNullOrUndefined(res?.target?.href)
        && (res?.target?.href.includes('simcard/reg/options#footnote_')
          || res?.target?.href.includes('simcard/reg/confirm#footnote_'))),
      tap(res => res.preventDefault()),
      tap(() => this.footnotes.forEach(fn => fn.nativeElement.style = '')),
      tap(() => {
        if (!this.footnoteAccordion.nativeElement.classList.contains('is-open')) {
          this.footnoteLink.nativeElement.click();
        }
      }),
      map(res => res?.target?.href),
      map(res => res.split('#')[1]),
      map(res => this.footnotes.filter(fn => fn.nativeElement.id === res)?.[0]?.nativeElement),
      // tap(res => console.log(res)),
      filter(res => !isNullOrUndefined(res)),
      tap(res => this.viewportScroller.scrollToAnchor(res.id)),
      tap(res => res.style = 'outline: 1px solid red;'),
    ).subscribe());

    this.subscriptions.push(this.router.events.pipe(
      tap(() => {
        if (this.footnoteAccordion.nativeElement.classList.contains('is-open')) {
          this.footnoteLink.nativeElement.click();
        }
        this.footnotes.forEach(fn => fn.nativeElement.style = '');
      }),
    ).subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); });
  }
  get appFeaturesAppType(): Observable<string> {
    return this.appFeatures.appType$;
  }

  // get impressumLink(): Observable<string> {
  //   return this.appFeatures.impressum$;
  // }

  public onFootnoteElementClicked() {
    this.footnotes.forEach(fn => fn.nativeElement.style = '');
  }

  private loadScript(url) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
