/**
 * SettingsService is in charge to maintain local settings stored to and retrieved from local storage
 */
import { Injectable } from '@angular/core';


// import * as CryptoJS from 'crypto-js';
// import * as moment from 'moment';
// import { isNullOrUndefined } from '@mvneco/eb-core';



/**
 * settings provides all local settings.
 */


export interface ISettings {
  consentCookies: string | null; // version string
  locale: string; // user selected locale setting for the app
}

const initialSetings: ISettings = {
  consentCookies: null,
  locale: 'de'
};

// initial settings for universal rendering
const initialSetingsUniversal: ISettings = {
  consentCookies: null,
  locale: 'de'
};



@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() { }

  /**
   * get current settings
   * The settings are encrypted, decrypt before delivering them.
   */
  public get(): ISettings {
    if (this.isBrowser() === true) {
      if (typeof localStorage === 'undefined') {
        return this.defaultSettings;
      } else if (localStorage.getItem('settings') == null) {
        return this.defaultSettings;
      } else {
        try {
          return { ...this.defaultSettings, ...JSON.parse(localStorage.getItem('settings')) };
        } catch (err) {
          return this.defaultSettings;
        }
      }
    } else {
      // universal rendering
      return initialSetingsUniversal;
    }


  }
  /**
   * save (set) current settings and return them
   * Please use the returned value. It allows to work with defaults on new versions of the App.
   */
  public set(settings: ISettings): ISettings {
    if (this.isBrowser() === true) {
      if (typeof localStorage === 'undefined') {
        // return defaults
        return this.defaultSettings;
      } else {
        localStorage.setItem('settings', JSON.stringify(settings));
        return JSON.parse(JSON.stringify(settings));
      }
    } else {
      // universal rendering
      return initialSetingsUniversal;
    }

  }
  /**
   * clear (delete) current settings and ensure that only factory defaults are used further on
   * Please use the returned value.
   */
  public clear(): ISettings {
    if (this.isBrowser() === true) {
      if (typeof localStorage === 'undefined') {
        return this.defaultSettings;
      } else {
        localStorage.clear();
        return this.defaultSettings;
      }
    } else {
      // universal rendering
      return initialSetingsUniversal;
    }
  }

  public get defaultSettings(): ISettings {
    return initialSetings;
  }



  public isBrowser(): boolean {
    if (typeof window === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

}
