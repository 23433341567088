import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromActivationReducer from './store/reducers/activation.reducer';
import { ActivationState } from './store/reducers/activation.reducer';
import { LoadActivationState, ResetActivation } from './store/actions/activation.actions';
import { ActivationEffects } from './store/effects/activation.effects';
import { Store, StoreModule } from '@ngrx/store';
import { ActivationService } from './services/activation.service';
import { EffectsModule } from '@ngrx/effects';



export async function initActivationStoreModule(storeActivation: Store<fromActivationReducer.ActivationState>,
                                                activationService: ActivationService) {
  return new Promise<boolean>((resolve) => {
    activationService.getActivationState().toPromise()
      .then(
        (res: ActivationState) => {
          // console.log('initActivationModule, res:', res);
          storeActivation.dispatch(new LoadActivationState(res));
          resolve(true);
        },
        (err) => {
          // console.log('initActivationModule, err:', err);
          storeActivation.dispatch(new ResetActivation());
          resolve(true);
        });
  });
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('activation', fromActivationReducer.reducer, { initialState: fromActivationReducer.INITIAL_STORE_STATE }),
    EffectsModule.forFeature([ActivationEffects]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER, useFactory: (storeActivation: Store<ActivationState>,
                                             activationService: ActivationService) => {
        return () => initActivationStoreModule(storeActivation, activationService);
      },
      multi: true,
      deps: [Store, ActivationService]
    }
  ],
})
export class ActivationStoreModule { }
