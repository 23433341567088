import { RouterStateSnapshot } from '@angular/router';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl, ILabelsPerApp } from '../models/router.model';

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    // console.log('Entered CustomSerializer.serialize(), routerState:', routerState);

    let route = routerState.root;

    let path = '';
    while (route.firstChild) {
      // console.log('In while:', route.routeConfig?.path);
      if (!isNullOrUndefined(route.routeConfig?.path)) {
        path += '/' + route.routeConfig.path;
      }
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    const {
      params
    } = route;

    let labelPerApp: ILabelsPerApp;
    if (route.data?.breadcrumb) {
      if (isNullOrUndefined(route.data?.breadcrumbPerApp)) {
        labelPerApp = {
          default: route.data.breadcrumb
        };
      } else {
        labelPerApp = {
          sp: isNullOrUndefined(route.data.breadcrumbPerApp.sp) ? undefined : route.data.breadcrumbPerApp.sp,
          ccs: isNullOrUndefined(route.data.breadcrumbPerApp.ccs) ? undefined : route.data.breadcrumbPerApp.ccs,
          sc: isNullOrUndefined(route.data.breadcrumbPerApp.sc) ? undefined : route.data.breadcrumbPerApp.sc,
          default: route.data.breadcrumb
        };
      }
    }

    if (route.routeConfig?.path) {
      path += '/' + route.routeConfig?.path;
    }

    let forceReset = false;
    if (route.data?.forceReset) {
      forceReset = route.data.forceReset;
    }

    /*
    console.log('About to return from CustomSerializer.serialize():', {
      labelPerApp,
      url,
      path,
      params,
      queryParams,
      forceReset
    });
    */

    // Instead of the entire snapshot, only return an object including the URL, params and query params
    return {
      labelPerApp,
      url,
      path,
      params,
      queryParams,
      forceReset
    };
  }
}
