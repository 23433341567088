import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SanitizeResourceUrlPipe } from './sanitize-resource-url.pipe';
import { LimitTextPipe } from './limit-text.pipe';
import { FirstPipe } from './first.pipe';
import { WrapableTextPipe } from './wrapable-text.pipe';
import { ContainsPipe } from './contains.pipe';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { StringifyPipe } from './stringify.pipe';
import { SalutationPipe } from './salutation.pipe';
import { StreetCombinedPipe } from './street-combined.pipe';
import { CustContactCombinedPipe } from './cust-contact-combined.pipe';
import { MergeErrorsPipe } from './merge-errors.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SanitizeHtmlPipe,
    SanitizeResourceUrlPipe,
    LimitTextPipe,
    FirstPipe,
    WrapableTextPipe,
    ContainsPipe,
    SanitizeUrlPipe,
    StringifyPipe,
    SalutationPipe,
    StreetCombinedPipe,
    CustContactCombinedPipe,
    MergeErrorsPipe,
  ],
  exports: [
    SanitizeHtmlPipe,
    SanitizeResourceUrlPipe,
    LimitTextPipe,
    FirstPipe,
    WrapableTextPipe,
    ContainsPipe,
    SanitizeUrlPipe,
    StringifyPipe,
    SalutationPipe,
    StreetCombinedPipe,
    CustContactCombinedPipe,
    MergeErrorsPipe,
  ]
})
export class PipesModule { }
