import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './modules/breadcrumb/utils/custom-serializer';
// import { environment } from '../environments/environment';

// const routes: Routes = [
//   {
//     path: 'simcard',
//     loadChildren: () => import('./modules/activation/activation.module').then(mod => mod.ActivationModule),
//   },
//   {
//     path: 'pre-simcard',
//     loadChildren: () => import('./modules/activation/activation.module').then(mod => mod.ActivationModule),
//   },
// ];

const routes: Routes = ['pre-simcard', 'simcard']
.map((path) => ({path, loadChildren: () => import('./modules/activation/activation.module').then(mod => mod.ActivationModule)}));

@NgModule({
  imports: [
    BrowserModule,
    StoreModule.forRoot({
      router: routerReducer
    }),
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
