import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormControl, FormGroupDirective, NgForm, ReactiveFormsModule } from '@angular/forms';
// import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
// import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
// import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppFeaturesModule, APP_FEATURES_MODULE_CONFIG } from '@mvneco/app-features';
import { Di18nMode, Di18nModule, Di18nProviderModule, Di18nService, Di18nTranspiledJsonFileType, isNullOrUndefined } from '@mvneco/di18n';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { httpInterceptorProviders } from './http-interceptors';
/* Error when control is invalid, dirty touched, or submitted are ignored. */
import di18nMessages from './MESSAGES.json';
import { ActivationStoreModule } from './modules/activation-store/activation-store.module';
import { AppSettingsModule } from './modules/app-settings/app-settings.module';
import { AuthModule } from './modules/auth/auth.module';
import { BreadcrumbModule } from './modules/breadcrumb/breadcrumb.module';
import { PipesModule } from './modules/pipes/pipes.module';
import { metaReducers, reducers } from './reducers';
import { AppInfo } from './shared/app-info';
import { MatPaginatorIntlDe } from './shared/mat-paginator-intl';
import { OverlayQrCodeComponent } from './components/overlay-qr-code/overlay-qr-code.component'; // EB-17970, overlay component
/**
 * EB-17970, buttons for overlay component
 */
import { GoogleAndAppleComponent } from './components/overlay-qr-code/google-and-apple/google-and-apple.component';

/**
 * Factory for application initalization
 * - attemts to load matching DI18N messages from protal layer
 * - If this succeeds,, messages from portal layer wilkl replace local version
 * @param httpClient HTTP service
 * @param di18nService DI18N nservice
 * @returns Promise<any>
 */
function initializeAppFactory(httpClient: HttpClient, di18nService: Di18nService): () => Promise<any> {
  return () => new Promise<any>((resolve) => {
    httpClient.get<Di18nTranspiledJsonFileType>(`services/V10/di18n/messages/${AppInfo.name}/${AppInfo.version}`).toPromise()
      .then(
        (res) => {
          di18nService.updateDi18n(res);
          resolve(true);
        },
        (err) => {
          resolve(true);
        });
  });
 }


@Injectable()
export class ShowOnInvalidErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    return !!(control && control.invalid);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    InfoDialogComponent,
    ConfirmationDialogComponent,
    OverlayQrCodeComponent, // EB-17970
    GoogleAndAppleComponent, // EB-17970
  ],
  imports: [
    AppFeaturesModule,
    AppRoutingModule,
    AppSettingsModule,
    AuthModule,
    BreadcrumbModule,
    ActivationStoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule,
    HttpClientModule,
    // MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    // MatMenuModule,
    MatProgressBarModule,
    // MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    PipesModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects]),
    Di18nProviderModule.forRoot({
      di18nMode: AppInfo.di18nMode as Di18nMode,
      locale: AppInfo.defaultLocale,
      messages: new Di18nTranspiledJsonFileType(di18nMessages),
    }),
    Di18nModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [HttpClient, Di18nService]
    },
    {
      provide: APP_FEATURES_MODULE_CONFIG,
      useValue: {
          serviceUrl: '/services/V10/app-features',
          socketioPath: '/services/V10/socketio',
      },
  },
    DatePipe,
    Title,
    httpInterceptorProviders,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
    { provide: ErrorStateMatcher, useClass: ShowOnInvalidErrorStateMatcher },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private di18nService: Di18nService
  ) {}
}
