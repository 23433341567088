import { HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { PendingService } from '../services/pending.service';
import { envType, UrlService } from '../services/url.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    readonly urlExcludeList: string[] = [
        './3rdpartylicenses.txt'
    ];

    constructor(
        private pendingService: PendingService,
        private urlService: UrlService
    ) { }


    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = this.pendingService.request({ url: req.url });

        // TODO: check this

        let url = req.url;

        const env = this.urlService.environmentType();

        if (!url.startsWith('http') && env === 'PPD') {
            // adapt the URL to include baseUrl from configuration
            // do not add baseUrl to the url that starts with http,
            // as that means the application wants to access some third-party (non PL) URLs
            url = req.url.replace('services/V10', 'pre-services/V10');
        }
        const authReq = req.clone({
            url,
        });

        // console.log('NEW URL', authReq.url);

        // send cloned request with header to the next handler.
        // return next.handle(req).pipe(
        return next.handle(authReq).pipe(
            map(event => {
                // console.log('Normal event: ', event)
                switch (event.type) {
                    case HttpEventType.Response:
                        this.pendingService.release(token);
                        break;
                }
                return event;
            }),
            catchError((error, caught) => {
                // console.log('Error event: ', event)
                this.pendingService.release(token);
                return throwError(error);
            }),
            finalize(() => {
                // console.log('Finalize event: ', event)
                this.pendingService.release(token);
            })
        );
    }
}
