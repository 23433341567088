<div mat-content>
    <h3 mat-dialog-title><di18n di18nd="PromptSessionExpiredComponent|xdi18ntagged@@4e911c90-9af5-11eb-86b5-ddb791afb374">
        Sitzung abgelaufen
    </di18n></h3>
    <mat-dialog-content>
        <p><di18n di18nd="PromptSessionExpiredComponent|xdi18ntagged@@4e911c91-9af5-11eb-86b5-ddb791afb374">Ihre Sitzung ist abgelaufen</di18n></p>
        <p><di18n di18nd="PromptSessionExpiredComponent|xdi18ntagged@@4e911c92-9af5-11eb-86b5-ddb791afb374">Bitte loggen Sie sich neu ein.</di18n></p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button id="btnCancel" mat-raised-button type="button" (click)="cancel()" aria-label="ignorieren"><di18n di18nd="PromptSessionExpiredComponent|xdi18ntagged@@4e911c93-9af5-11eb-86b5-ddb791afb374">
            Ignorieren
        </di18n></button>
        <button mat-raised-button type="button" color="accent" (click)="confirm()" aria-label="login"><di18n di18nd="PromptSessionExpiredComponent|xdi18ntagged@@4e911c94-9af5-11eb-86b5-ddb791afb374">
            Login
        </di18n></button>
    </mat-dialog-actions>
</div>