import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MnpListServiceProviderResponseDto, MnpServiceProviderTypeDto, OptionTypeDto,
   OptionTypeOptionDetailOptionDetailAnonymDto, ResourceGetSim2ResponseDto } from 'src/app/shared/api-types';
import { MnpCustomerType, MnpInquiryType, MnpData } from '../models/mnp-data.model';
import { CustomerDataModel } from '../store/models/customer-data.model';
import { SimDataModel } from '../store/models/sim-data.model';

@Injectable({
  providedIn: 'root'
})
export class MnpService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getmsisdn(req: SimDataModel): Observable<string> {
    return this.httpClient.post<ResourceGetSim2ResponseDto>('services/V10/aldi-talk-internet-service-service/resourceGetSim2',
    {sim: req.sim, msisdn: req.oldmsisdn}).pipe(
      map(res => {
        if (res && res.resource && res.resource.msisdn) {
          return res.resource.msisdn;
        }
        else {
          return null;
        }
      }),
      catchError((err) => of(null)
      ));
  }

  public getMnpProviders(): Observable<MnpServiceProviderTypeDto[]> {
    return this.httpClient.post<MnpListServiceProviderResponseDto>
    ('services/V10/aldi-talk-internet-service-service/mnpListServiceProvider', {}).pipe(
      map(res => {
        let providers: MnpServiceProviderTypeDto[] = [];
        if (res && res.mnpServiceProviderList) {
          providers = res.mnpServiceProviderList.mnpServiceProvider;
        }
        return providers;
      }),
      catchError((err) => of([])
      ));
  }

  public toMnpOptionTypeDto(customerData: CustomerDataModel): OptionTypeDto {
    if (isNullOrUndefined(customerData) || isNullOrUndefined(customerData.mnpData) || !customerData.mnpData.selected) {
      return null;
    }

    let res: OptionTypeDto = null;
    if (customerData.mnpData.customerType === MnpCustomerType.PRIVATE) {
      res = this.toPrivateMnpOptionTypeDto(customerData);
    } else if (customerData.mnpData.customerType === MnpCustomerType.BUSINESS) {
      res = this.toBusinessMnpOptionTypeDto(customerData.mnpData);
    }

    return res;
  }

  private toPrivateMnpOptionTypeDto(customerData: CustomerDataModel): OptionTypeDto {
    return {
      optionCode: 'O0MNPINP',
      optionGroupCode: 'G0MNP',
      action: 'ADD',
      optionDetail: [
        {
          optionDetailId: 'OD1MNPRN1',
          value: '49' + customerData.mnpData.msisdn.substring(1)
        },
        {
          optionDetailId: 'OD1MNPAB1',
          value: customerData.mnpData.providerCode
        },
        {
          optionDetailId: 'OD1MNPVN',
          value: customerData.firstName
        },
        {
          optionDetailId: 'OD1MNPNN',
          value: customerData.lastName
        },
        {
          optionDetailId: 'OD1MNPDOB',
          value: format(new Date(customerData.dateOfBirth), 'yyyy-MM-dd')
        },
        {
          optionDetailId: 'OD1MNPANF1',
          value: customerData.mnpData.inquiryType
        }
      ]
    };
  }

  private toBusinessMnpOptionTypeDto(mnpData: MnpData): OptionTypeDto {
    return {
      optionCode: 'O0MNPINB',
      optionGroupCode: 'G0MNP',
      action: 'ADD',
      optionDetail: [
        {
          optionDetailId: 'OD1MNPRN2',
          value: '49' + mnpData.msisdn.substring(1)
        },
        {
          optionDetailId: 'OD1MNPAB2',
          value: mnpData.providerCode
        },
        {
          optionDetailId: 'OD1MNPFN',
          value: mnpData.companyName
        },
        {
          optionDetailId: 'OD1MNPKN',
          value: mnpData.customerNumber
        },
        {
          optionDetailId: 'OD1MNPANF2',
          value: mnpData.inquiryType
        }
      ]
    };
  }

  public toMnpDataFromOptionArray(optionArray: OptionTypeDto[]): MnpData {
    const ind = optionArray.findIndex(o => o.optionGroupCode === 'G0MNP');
    return ind === -1 ? null : this.toMnpDataFromOption(optionArray[ind]);
  }

  public toMnpDataFromOption(option: OptionTypeDto): MnpData {
    let customerType: MnpCustomerType;
    if (option.optionCode === 'O0MNPINP') {
      customerType = MnpCustomerType.PRIVATE;
    } else if (option.optionCode === 'O0MNPINB') {
      customerType = MnpCustomerType.BUSINESS;
    }

    let msisdn: string;
    let providerCode: string;
    let companyName: string;
    let customerNumber: string;
    let inquiryType: MnpInquiryType;

    if (!isNullOrUndefined(option.optionDetail)) {
      option.optionDetail.forEach((od: OptionTypeOptionDetailOptionDetailAnonymDto) => {
        switch (od.optionDetailId) {
          case 'OD1MNPRN1':
          case 'OD1MNPRN2':
            msisdn = '0' + od.value.substring(2);
            break;

          case 'OD1MNPAB1':
          case 'OD1MNPAB2':
            providerCode = od.value;
            break;

          case 'OD1MNPFN':
            companyName = od.value;
            break;

          case 'OD1MNPKN':
            customerNumber = od.value;
            break;

          case 'OD1MNPANF1':
          case 'OD1MNPANF2':
            inquiryType = od.value as MnpInquiryType;
            break;

          default:
            break;
        }
      });
    }

    return {
      selected: true,
      msisdn,
      providerCode,
      customerType,
      companyName,
      customerNumber,
      inquiryType
    };
  }
}
