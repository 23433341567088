<div mat-content class="mat-body-1">
    <h3 mat-dialog-title>
        {{data.title}}
         <!-- <mat-icon class="mat-navIcon" inline="true">error</mat-icon> -->
    </h3>
    <mat-dialog-content class="mat-content-1">
        <p>{{data.infoText}}</p>
        <p>{{data.additionalText}}</p>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-content-1" fxLayout="row" fxLayoutAlign="end center">
        <button type="button" cdkFocusInitial mat-raised-button color="accent" [mat-dialog-close]="true" aria-label="ok">
            {{data.btnText}}
        </button>
    </mat-dialog-actions>
</div>
