import { Injectable } from '@angular/core';

export interface ICookieConsentType {
  version: string;
  text: string;
}

const initialCookieConsent: ICookieConsentType = {
  version: '0.0.1',
  text: `Wir nutzen technisch notwendige Cookies sowie Cookies von Drittanbietern, um Ihnen die
  Internetseite bestmöglich darzustellen und durch Analyse der Seitenbesuche stets zu optimieren. Weitere Informationen zu den
  von uns benutzten Cookies und wie Sie diese im Nachhinein aktivieren oder deaktivieren können finden Sie in
  unserer <a href="hhttps://www.alditalk-kundenbetreuung.de/de/datenschutz" target="_blank"
      rel="noopener" style="color:rgba(255, 255, 255, 0.7);">Datenschutzerklärung</a>`
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public phoneAutoThreshold = 3; // threshold for autocompletes regarding phone numbers.

  constructor() {
  }

  public cookieConsent(): ICookieConsentType {
    return initialCookieConsent;
  }
}
