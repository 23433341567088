import { Injectable } from '@angular/core';
import moment from 'moment';
import { isNullOrUndefined } from '@mvneco/eb-core';

@Injectable({
  providedIn: 'root'
})
export class TimeHandlerService {

  constructor() { }

  correctMoment(date: string, hours?: number): moment.Moment {
    const offset = (moment() as any)?._d?._offset;

    if (!isNullOrUndefined(date) && isNullOrUndefined(hours)) {
      return moment().utcOffset() >= 0 ? moment(date + 'T12:00:00Z').subtract({ minutes: offset }) :
      moment(date + 'T12:00:00Z').add({ minutes: 240 });
    }
    else if (isNullOrUndefined(date) && !isNullOrUndefined(hours))
    {
      return moment().utcOffset() >= 0 ? moment().hours(12).subtract({ minutes: offset }) :
      moment().hours(12).add({ minutes: 240 });
    }
    else if (isNullOrUndefined(date) && isNullOrUndefined(hours))
    {

    }
    else
    {

    }



    // const newDate: any = moment().utcOffset() >= 0 ? moment(res.data.dateOfBirth + 'T12:00:00Z').subtract({ minutes: offset }) :
    // moment(res.data.dateOfBirth + 'T12:00:00Z').add({ minutes: 240 });


  }

  correctDateString(date: any, format: string, hours?: number, special?: boolean): string {
    const offset = (moment() as any)?._d?._offset;

    if (!isNullOrUndefined(special) && special === true) {
      return moment().utcOffset() >= 0 ? moment(date).subtract({ minutes: -60 }).format(format) :
      moment(date).add({ minutes: 240 }).format(format);
    }
    else if (isNullOrUndefined(hours)) {
    return moment().utcOffset() >= 0 ? moment(date, format, 'de-DE', false).subtract({ minutes: -60 }).format(format) :
    moment(date, format, 'de-DE', false).add({ minutes: 240 }).format(format);
    }
    else if (!isNullOrUndefined(hours)) {
      return moment().utcOffset() >= 0 ?
      moment(date).hours(hours).subtract({ minutes: offset }).format(format) :
      moment(date).hours(hours).add({ minutes: 240 }).format(format);
    }
  }
}
