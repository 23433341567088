import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@mvneco/eb-core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionExpired } from '../modules/auth/store/actions/auth.actions';
import * as fromAuthReducer from '../modules/auth/store/reducers/auth.reducer';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  readonly urlExcludeList: string[] = [
    'services/V10/app-settings',
    'services/V10/auth/connection',
  ];

  constructor(
    private store: Store<fromAuthReducer.AuthState>
  ) { }

  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param req the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return next.handle(req);
    if (this.urlExcludeList.indexOf(req.url) !== -1) {
      return next.handle(req);
    } else {
      return next.handle(req).pipe(
        catchError((error, caught) => {
          // intercept the respons error and manage it
          return throwError(this.handleHttpError(error));
        }));
    }
  }

  /**
   * handleHttpError
   * handles HTTP errors:
   * - connection errors are directly reported to store slice
   * @param err error
   * @returns Observable<any>
   */
  private handleHttpError(err: HttpErrorResponse): Error {
    if (err instanceof HttpErrorResponse && !isNullOrUndefined(err.status) && err.status === 401) {
      // session expired
      // emit to store and rethrow as ServiceError 401
      this.store.dispatch(new SessionExpired());
      //   return new ServiceError('Not Authorized.', 401);
      // } else {
      //   return err;
    }
    return err;
  }
}
