<!-- 'opacity-level' class only when overlay is shown, also 'disabled-clicks' class, EB-17970-->

<div id="mm-0" class="mm-menu mm-offcanvas mm-top mm-front mm-autoheight mm-fullscreen" aria-hidden="true" [ngClass]="(disableClick$ | async) ? 'opacity-level' : ''">
    <div class="mm-panels">
        <div class="mm-panel mm-hasnavbar mm-opened" id="mmenu">
            <div class="mm-navbar"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-title"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4450-9af5-11eb-86b5-ddb791afb374">Menü</di18n></a></div>
            <ul class="mm-listview">
                <li class>
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-next" href="#mm-1" aria-owns="mm-1" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4451-9af5-11eb-86b5-ddb791afb374">Open
                            submenu
                            (Tarifdetails)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="toggle-submenu" data-target="#submenu-0" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4452-9af5-11eb-86b5-ddb791afb374">Tarifdetails</di18n></a>
                </li>
                <li class>
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-next" href="#mm-2" aria-owns="mm-2" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4453-9af5-11eb-86b5-ddb791afb374">Open
                            submenu
                            (Handys & Tablets)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="toggle-submenu" data-target="#submenu-1" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4454-9af5-11eb-86b5-ddb791afb374">Handys &
                        Tablets</di18n></a>
                </li>
                <li class>
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-next" href="#mm-3" aria-owns="mm-3" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4455-9af5-11eb-86b5-ddb791afb374">Open
                            submenu
                            (Hilfe & Service)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="toggle-submenu" data-target="#submenu-2" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4456-9af5-11eb-86b5-ddb791afb374">Hilfe &
                        Service</di18n></a>
                </li>
                <li class="nurmobil">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="nav-service__flex-wrapper" data-target="https://www.alditalk.de/simcard/register" href="https://www.alditalk.de/simcard/register"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4457-9af5-11eb-86b5-ddb791afb374">SIM aktivieren</di18n></a>
                </li>
            </ul>
        </div>
        <div class="mm-panel mm-hidden mm-hasnavbar" id="mm-1" aria-hidden="true">
            <div class="mm-navbar"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-btn mm-prev" href="#mmenu" aria-owns="mmenu" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4458-9af5-11eb-86b5-ddb791afb374">Close submenu (Tarifdetails)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-title" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4459-9af5-11eb-86b5-ddb791afb374">Tarifdetails</di18n></a></div>
            <ul class="mm-listview">
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/basis-prepaid-tarif-sim-karte"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445a-9af5-11eb-86b5-ddb791afb374">Prepaid Starter-Set</di18n></b></a>
                </li>
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/basis-prepaid-tarif-sim-karte"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445b-9af5-11eb-86b5-ddb791afb374">Basis-Prepaid-Tarif</di18n></b></a>
                </li>
                <li class="util-icon--after">
                    <p class="util-icon--before util-icon--open-package util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445c-9af5-11eb-86b5-ddb791afb374">Kombi-Pakete</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/paket-s"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445d-9af5-11eb-86b5-ddb791afb374">Paket S</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/paket-m"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445e-9af5-11eb-86b5-ddb791afb374">Paket M</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/paket-l"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a445f-9af5-11eb-86b5-ddb791afb374">Paket L</di18n></a></li>
                <li class="util-icon--after">
                    <p class="util-icon--before util-icon--paket-musik util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a4460-9af5-11eb-86b5-ddb791afb374">Musik-Streaming</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/musik-paket-m"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b60-9af5-11eb-86b5-ddb791afb374">Musik-Paket M</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/musik-paket-l"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b61-9af5-11eb-86b5-ddb791afb374">Musik-Paket L</di18n></a></li>
                <li class="util-icon--after">
                    <p class="util-icon--before util-icon--www util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b62-9af5-11eb-86b5-ddb791afb374">Internet-Flatrates</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/internet-flat-s"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b63-9af5-11eb-86b5-ddb791afb374">Internet-Flatrate S</di18n></a>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/internet-flat-m"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b64-9af5-11eb-86b5-ddb791afb374">Internet-Flatrate M</di18n></a>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/internet-flat-l"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b65-9af5-11eb-86b5-ddb791afb374">Internet-Flatrate L</di18n></a>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/internet-flat-xl"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b66-9af5-11eb-86b5-ddb791afb374">Internet-Flatrate XL</di18n></a>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/surf-tickets"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b67-9af5-11eb-86b5-ddb791afb374">Surf-Tickets</di18n></b></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/reisepakete"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b68-9af5-11eb-86b5-ddb791afb374">Reise-Pakete</di18n></b></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/community-flatrate"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b69-9af5-11eb-86b5-ddb791afb374">Community-Flatrate</di18n></b></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/high-speed-datenvolumen-aufladen"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6a-9af5-11eb-86b5-ddb791afb374">Zusätzliches
                            High-Speed-Volumen</di18n></b></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/jahrespaket"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6b-9af5-11eb-86b5-ddb791afb374">Jahrespakete</di18n></b></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/hotspot"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6c-9af5-11eb-86b5-ddb791afb374">ALDI TALK Hotspot</di18n></b></a></li>
            </ul>
        </div>
        <div class="mm-panel mm-hidden mm-hasnavbar" id="mm-2" aria-hidden="true">
            <div class="mm-navbar"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-btn mm-prev" href="#mmenu" aria-owns="mmenu" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6d-9af5-11eb-86b5-ddb791afb374">Close submenu (Handys & Tablets)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-title" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6e-9af5-11eb-86b5-ddb791afb374">Handys &
                    Tablets</di18n></a></div>
            <ul class="mm-listview">
                <li class="util-icon--after">
                    <p class="util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b6f-9af5-11eb-86b5-ddb791afb374">Marken</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/samsung-smartphones"><img alt="Samsung" src="https://media.medion.com/s/ALDItalk/Logo/brands/samsung.png" class="util-hidden-md-down" style="height: 20px;"><img alt="Samsung_w" src="https://media.medion.com/s/ALDItalk/Logo/navigation/samsung_w.png" class="util-hidden-lg-up" style="height: 20px;"></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/apple-smartphones"><img alt="Apple" src="https://media.medion.com/s/ALDItalk/Logo/brands/apple.png" class="util-hidden-md-down" style="height: 20px;"><img alt="Apple_w" src="https://media.medion.com/s/ALDItalk/Logo/navigation/apple_w.png" class="util-hidden-lg-up" style="height: 20px;"></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/nokia-smartphones"><img alt="Nokia" src="https://media.medion.com/s/ALDItalk/Logo/brands/nokia.png" class="util-hidden-md-down" style="height: 20px;"><img alt="Nokia_w" src="https://media.medion.com/s/ALDItalk/Logo/navigation/nokia_w.png" class="util-hidden-lg-up" style="height: 20px;"></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/xiaomi-smartphones"><img alt="Mi" src="https://media.medion.com/s/ALDItalk/Logo/brands/mi.png" style="height: 20px;"></a></li>
                <li class="util-icon--after">
                    <p class="util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b70-9af5-11eb-86b5-ddb791afb374">Kategorien</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/smartphones"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b71-9af5-11eb-86b5-ddb791afb374">Smartphones</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/mobiltelefone"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b72-9af5-11eb-86b5-ddb791afb374">Mobiltelefone</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/tablets"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b73-9af5-11eb-86b5-ddb791afb374">Tablets</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/fitness"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b74-9af5-11eb-86b5-ddb791afb374">Fitness</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/zubehoer"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b75-9af5-11eb-86b5-ddb791afb374">Zubehör</di18n></a></li>
            </ul>
        </div>
        <div class="mm-panel mm-hidden mm-hasnavbar" id="mm-3" aria-hidden="true">
            <div class="mm-navbar"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-btn mm-prev" href="#mmenu" aria-owns="mmenu" aria-haspopup="true"><span class="mm-sronly"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b76-9af5-11eb-86b5-ddb791afb374">Close submenu (Hilfe & Service)</di18n></span></a><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " class="mm-title" href="#"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b77-9af5-11eb-86b5-ddb791afb374">Hilfe &
                    Service</di18n></a></div>
            <ul class="mm-listview">
                <li class="util-icon--after">
                    <p class="util-unstyled"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b78-9af5-11eb-86b5-ddb791afb374">Rund um Ihren Tarif</di18n></b></p>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/registrieren"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b79-9af5-11eb-86b5-ddb791afb374">Starter-Set registrieren und
                        aktivieren</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/rufnummernmitnahme"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7a-9af5-11eb-86b5-ddb791afb374">Rufnummer
                        mitnehmen</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/tarifverwaltung"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7b-9af5-11eb-86b5-ddb791afb374">Tarif verwalten</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/guthaben-aufladen"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7c-9af5-11eb-86b5-ddb791afb374">Guthaben aufladen</di18n></a>
                </li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/bezahlung-per-bankkonto"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7d-9af5-11eb-86b5-ddb791afb374">Tarifoption per
                        Bankkonto bezahlen</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/sim-karte-service"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7e-9af5-11eb-86b5-ddb791afb374">Rund um die
                        SIM-Karte</di18n></a></li>
                <li class="util-icon--after"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/3g-abschaltung"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b7f-9af5-11eb-86b5-ddb791afb374">Informationen zur
                        3G-Abschaltung</di18n></a></li>
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/alditalk-app"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b80-9af5-11eb-86b5-ddb791afb374">ALDI Talk App</di18n></b></a>
                </li>
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/faq"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b81-9af5-11eb-86b5-ddb791afb374">FAQ</di18n></b></a>
                </li>
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/downloads-formulare"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b82-9af5-11eb-86b5-ddb791afb374">Dokumente & Formulare zum
                            Download</di18n></b></a>
                </li>
                <li class="util-icon--after">
                    <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/contact"><b><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b83-9af5-11eb-86b5-ddb791afb374">Kontakt</di18n></b></a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div id="page-wrapper" [ngClass]="(disableClick$ | async) ? 'opacity-level' : ''">
    <header #header class="header">
        <div class="hint-list hint-list--small">
            <div class="container util-flexbox">
                <div class="yCmsContentSlot util-flex-grow-1">
                    <div class="content">
                        <ul class="hint-list__list util-unstyled">
                            <li class="hint-list__item util-reset-padding util-text-right util-icon--before"><a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/impressum" title="Ein Angebot der MEDION AG"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b84-9af5-11eb-86b5-ddb791afb374">Ein
                                    Angebot der MEDION AG</di18n></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="header__sticky-sticky-wrapper" class="sticky-wrapper">
            <div id="header__sticky" class="mmenu_fixed headroom headroom--top headroom--not-bottom">
                <div class="container">
                    <div class="header__logo-wrapper clearfix">
                        <div class="yCmsComponent logo">
                            <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/">
                                <h1 style="font-size: 0.8125rem; font-weight:normal; display:inline;"><img title="ALDI" alt="ALDI" src="https://media.medion.com/cms/medion/alditalkde/aldilogo.png">
                                </h1>
                            </a>
                        </div>
                        <div class="logo logo--name">
                            <span><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b85-9af5-11eb-86b5-ddb791afb374">ALDI</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b86-9af5-11eb-86b5-ddb791afb374"> TALK</di18n></span>
                        </div>
                    </div>
                    <div class="yCmsComponent header__search">
                        <form name="search_form_SearchBox" method="get" action="/search/" class="form">
                            <div class="form__field-wrapper search-wrapper">
                            </div>
                        </form>
                    </div>
                    <div class="nav-service">
                        <ul class="util-unstyled">
                            <li class="util-hidden-lg-up">
                                <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " id="mmenu-toggle" class="header__mmenu-toggle" href="javascript:void(0);">
                                    <span id="mobile-menu-toggle">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span class="nav-service__tex mmenu-toggle-label"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b87-9af5-11eb-86b5-ddb791afb374">Menü</di18n></span>
                                </a>
                            </li>
                            <!-- Commented out due to EB-15778 -->
                            <!-- <li class="nav-service__search util-hidden-lg-up">
                                <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="#" class="util-icon--before search-trigger">
                                    <span class="nav-service__icon util-icon--after util-icon--search"></span>
                                    <span class="nav-service__text">Suchen</span>
                                </a>
                            </li> -->
                            <li class="util-flex-grow-1 util-hidden-lg-up"></li>
                            <li class="nav-service__sim" *ngIf="(appFeaturesAppType | async) !== 'app'">
                                <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://service.alditalk.de/simcard/register" class="util-flexbox">
                                    <span class="nav-service__icon util-icon--after util-icon--sim"></span>
                                    <span class="nav-service__text"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b88-9af5-11eb-86b5-ddb791afb374">SIM aktivieren</di18n></span>
                                </a>
                            </li>
                            <li *ngIf="(appFeaturesAppType | async) !== 'app'">
                                <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk-kundenbetreuung.de/de" class="util-flexbox">
                                    <span class="nav-service__icon util-icon--after util-icon--avatar"></span>
                                    <span class="nav-service__text"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b89-9af5-11eb-86b5-ddb791afb374">Mein ALDI TALK</di18n></span>
                                </a>
                            </li>
                            <li class="tooltip" data-group="cart">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content">
                    <nav class="navigation navigation--bottom js_navigation--bottom js-enquire-offcanvas-navigation nav">
                        <div class="nav__mainmenu util-hidden-md-down">
                            <div class="container">
                                <ul id="menu-main">
                                    <li class></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- EB-17713 mat-progress-bar loading until page-header isn't loaded-->
    <!-- <mat-progress-bar *ngIf="(loaded$ | async) !== true" color="primary" mode="indeterminate"></mat-progress-bar> -->
    <!-- EB-17713 added (loaded$) subject which will be true when customClass$ gets value: true/false -->
    <main data-currency-iso-code="EUR" id="main" class="mm-page mm-slideout">
        <div class="container">
            <!--EB-17713 'class='page-header' changed to [ngClass]....'-->
            <div class="page-header">
                <h2><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8a-9af5-11eb-86b5-ddb791afb374">SIM-Karte aktivieren</di18n></h2>
            </div>
            <mat-progress-bar *ngIf="pending$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
            <div class="page-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>

    <footer class="footer ">
        <div *ngIf="(appFeaturesAppType | async) !== 'app'" class="container">
            <div class="row">
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-lg-12">
                                <div class="row accordion accordion--mobile">
                                    <div class="col-xs-12 col-sm-3">
                                        <div class="links">
                                            <div class="title accordion__trigger"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8b-9af5-11eb-86b5-ddb791afb374">Über diese Seite</di18n></div>
                                            <div class="accordion__content-hidden">
                                                <ul>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/verbraucherinformationen" title="Verbraucherinformationen"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8c-9af5-11eb-86b5-ddb791afb374">Verbraucherinformationen</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/datenschutz" title="Datenschutz (www.alditalk.de)"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8d-9af5-11eb-86b5-ddb791afb374">Datenschutz
                                                            (www.alditalk.de)</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/nutzungsrichtlinien" title="Nutzungsrichttlinien"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8e-9af5-11eb-86b5-ddb791afb374">Nutzungsrichttlinien</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/impressum" title="Impressum"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b8f-9af5-11eb-86b5-ddb791afb374">Impressum</di18n></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-3">
                                        <div class="links">
                                            <div class="title accordion__trigger"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b90-9af5-11eb-86b5-ddb791afb374">ALDI TALK Webshop</di18n></div>
                                            <div class="accordion__content-hidden">
                                                <ul>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/basis-prepaid-tarif-sim-karte" title="ALDI TALK Starter Set"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b91-9af5-11eb-86b5-ddb791afb374">ALDI TALK Starter
                                                            Set</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/smartphones" title="Smartphones"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b92-9af5-11eb-86b5-ddb791afb374">Smartphones</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/tablets" title="Tablets"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b93-9af5-11eb-86b5-ddb791afb374">Tablets</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/mobiltelefone" title="Klassische Mobiltelefone"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b94-9af5-11eb-86b5-ddb791afb374">Klassische
                                                            Mobiltelefone</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/zubehoer" title="Zubehör"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b95-9af5-11eb-86b5-ddb791afb374">Zubehör</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/garantiebedingungen" title="Garantiebedingungen"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b96-9af5-11eb-86b5-ddb791afb374">Garantiebedingungen</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/rueckgabe" title="Wichtige Hinweise zur Rückgabe"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b97-9af5-11eb-86b5-ddb791afb374">Wichtige Hinweise zur
                                                            Rückgabe</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/altprodukt-ruecknahme" title="Elektronik-Altprodukt Rücknahme"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b98-9af5-11eb-86b5-ddb791afb374">Elektronik-Altprodukt
                                                            Rücknahme</di18n></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-3">
                                        <div class="links">
                                            <div class="title accordion__trigger"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b99-9af5-11eb-86b5-ddb791afb374">ALDI TALK Mobilfunk</di18n></div>
                                            <div class="accordion__content-hidden">
                                                <ul>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://media.medion.com/cms/medion/alditalkde/Pflicht-Informationen-gem-Paragraf52-TKG.pdf" title="Pflicht-Informationen gem. §52 TKG" target="_blank" rel="noopener noreferrer"><di18n di18nd="AppComponent|xdi18ntagged@@b55e5430-2f91-4748-8c59-a956a74a129f">Pflichtinfos gem. §52 TKG</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/leistungsbeschreibung" title="AGB & Tarifübersicht" target="_blank" rel="noopener noreferrer"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9a-9af5-11eb-86b5-ddb791afb374">AGB &
                                                            Tarifübersicht</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/preisliste" title="Preisliste" target="_blank" rel="noopener noreferrer"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9b-9af5-11eb-86b5-ddb791afb374">Preisliste</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/datenschutz" title="Datenschutz (Mobilfunk)"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9c-9af5-11eb-86b5-ddb791afb374">Datenschutz (Mobilfunk)</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/downloads-formulare" title="Downloads & Formulare"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9d-9af5-11eb-86b5-ddb791afb374">Downloads &
                                                            Formulare</di18n></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-3">
                                        <div class="links">
                                            <div class="title accordion__trigger"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9e-9af5-11eb-86b5-ddb791afb374">Hilfe-Themen</di18n></div>
                                            <div class="accordion__content-hidden">
                                                <ul>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/registrieren" title="SIM-Registrierung / Aktivierung"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6b9f-9af5-11eb-86b5-ddb791afb374">SIM-Registrierung /
                                                            Aktivierung</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/rufnummernmitnahme" title="Rufnummer mitnehmen"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba0-9af5-11eb-86b5-ddb791afb374">Rufnummer mitnehmen</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/guthaben-aufladen" title="Guthaben aufladen"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba1-9af5-11eb-86b5-ddb791afb374">Guthaben aufladen</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/bezahlung-per-bankkonto" title="Bezahlung per Bankkonto"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba2-9af5-11eb-86b5-ddb791afb374">Bezahlung per
                                                            Bankkonto</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/tarifverwaltung" title="Tarifverwaltung / Mein ALDI TALK"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba3-9af5-11eb-86b5-ddb791afb374">Tarifverwaltung /
                                                            Mein
                                                            ALDI TALK</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/faq" title="FAQ"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba4-9af5-11eb-86b5-ddb791afb374">FAQ</di18n></a>
                                                    </li>
                                                    <li>
                                                        <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="https://www.alditalk.de/contact" title="Kontakt"><di18n di18nd="AppComponent|xdi18ntagged@@4e9a6ba5-9af5-11eb-86b5-ddb791afb374">Kontakt</di18n></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer__border">
            <div class="container">
                <div class="content">
                    <div class="container">
                        <div class="col-xs-12">
                            <div class="accordion footer__footnote">
                                <div [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : ''" #footnote_accordion (click)="onFootnoteElementClicked()" class="title accordion__trigger"><span #footnote_link><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9270-9af5-11eb-86b5-ddb791afb374">Rechtliche Hinweise</di18n></span>
                                </div>
                                <div class="accordion__content-hidden">
                                    <p #footnote id="footnote_1"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9275-9af5-11eb-86b5-ddb791afb374">1</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9276-9af5-11eb-86b5-ddb791afb374">ALDI TALK: Starter-Set einm 9,99 € inkl. 10 € Startguthaben (Startguthaben nicht auszahlbar). Leistungserbringer der Mobilfunkdienstleistung ist E-Plus Service GmbH (EPS), E-Plus-Straße 1, 40472 Düsseldorf. Die EPS erbringt ihre Leistung im Netz der Telefónica Germany GmbH & Co. OHG. ALDI und MEDION handeln im Namen und für Rechnung der EPS. Aktivierung der SIM-Karte unter alditalk.de. AGB/Preisliste unter <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " href="http://www.alditalk.de" style="color: blue" target="_blank">www.alditalk.de</a>. Preise im Basistarif: Innerhalb Deutschlands und im EU-Ausland: Gespräche (60/1) und SMS zu ALDI TALK 0,03 €/Min./SMS, Standard-Gespräche (60/1) und SMS 0,11 €/Min./SMS. Datenverbindung 0,24 €/MB (mit bis zu 25 Mbit/s im Download und bis zu 10 Mbit/s im Upload, Taktung 10 KB Schritte). Preise u. Taktungsangaben gelten nicht für Sondernr., (Mehrwert-)Dienste. Alle Infos:</di18n> <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " style="color: blue" href="https://www.alditalk.de" target="_blank">alditalk.de</a>.</p>
                                    <p #footnote id="footnote_2"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9279-9af5-11eb-86b5-ddb791afb374">2</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927a-9af5-11eb-86b5-ddb791afb374"> Pakete/Flatrates von ALDI TALK: Buchbar zum ALDI TALK Basistarif. Laufzeit: 4 Wochen. Automat. Verlängerung um 4 Wochen bei ausreichendem Guthaben, wenn keine Abbestellung zum Ende der Laufzeit erfolgt. Bei der jeweils angegebenen Datenübertragungsrate handelt es sich um die technisch mögliche Maximalgeschwindigkeit. Die tatsächlich und durchschnittlich erreichte Geschwindigkeit kann davon abweichen und ist u.a. abhängig vom Endgerätetyp und Netzausbaugebiet.</di18n></p>
                                    <p #footnote id="footnote_3"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927b-9af5-11eb-86b5-ddb791afb374">3</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927c-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Paket S: 7,99 €/4 Wochen. Enthalten: Gespräche/ SMS innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Internet-Flatrate mit 3 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_4"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927d-9af5-11eb-86b5-ddb791afb374">4</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927e-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Paket M: 12,99 €/4 Wochen. Enthalten: Gespräche/ SMS innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Internet-Flatrate mit 6 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_5"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a927f-9af5-11eb-86b5-ddb791afb374">5</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9280-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Paket L: 17,99 €/4 Wochen. Enthalten: Gespräche/ SMS innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Internet-Flatrate mit 12 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_6"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9281-9af5-11eb-86b5-ddb791afb374">6</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9282-9af5-11eb-86b5-ddb791afb374"> Internet-Flatrate S: 3,99 €/4 Wochen. Enthält EU-weite Internet-Flatrate mit 1 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_7"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9283-9af5-11eb-86b5-ddb791afb374">7</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9284-9af5-11eb-86b5-ddb791afb374"> Internet-Flatrate M: 6,99 €/4 Wochen. Enthält EU-weite Internet-Flatrate mit 3 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s</di18n></p>
                                    <p #footnote id="footnote_8"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9285-9af5-11eb-86b5-ddb791afb374">8</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9286-9af5-11eb-86b5-ddb791afb374"> Internet-Flatrate L: 9,99 €/4 Wochen. Enthält EU-weite Internet-Flatrate mit 5 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_9"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9287-9af5-11eb-86b5-ddb791afb374">9</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9288-9af5-11eb-86b5-ddb791afb374"> Internet-Flatrate XL: 14,99 €/4 Wochen. Enthält EU-weite Internet-Flatrate mit 10 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s.</di18n></p>
                                    <p #footnote id="footnote_10"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9289-9af5-11eb-86b5-ddb791afb374">10</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928a-9af5-11eb-86b5-ddb791afb374"> Die Buchung von zusätzlichem High-Speed-Datenvolumen ist jederzeit möglich, wenn eine der folgenden Optionen aktiv ist: Internet-Flatrate S/M/L/XL, Paket S/M/L, Musik-Paket M/L. Das zusätzlich gebuchte High-Speed-Datenvolumen kann für die verbleibende Laufzeit der gebuchten Option genutzt werden. Die Zusatzdienstleistung kann mehrmals innerhalb der Optionslaufzeit gebucht werden.</di18n></p>
                                    <p #footnote id="footnote_11"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928b-9af5-11eb-86b5-ddb791afb374">11</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928c-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Musik-Paket M: 9,99 €/4 Wochen. Enthalten: Gespräche/ SMS innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Internet-Flatrate mit 2 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s. Zugang zum Musik-Streaming-Dienst „ALDI Music powered by Napster“ ohne weitere Berechnung (separate Registrierung bei ALDI Music sowie Zustimmung zu den jew. AGB und Datenschutzbestimmungen von „ALDI Music powered by Napster“ erforderl.. Anbieter des Musik-Streaming-Dienstes ist Napster Luxembourg S.à r.l. 60, Route de Luxembourg L-5408 Bous, Luxemburg. Infos: <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " target="_blank" href="http://aldi-music.de" style="color:blue">aldi-music.de</a>). Umsatzsteuerrechtlicher Leistungserbringer ist die EPS. Nutzung per Smartphone/ Tablet (Apps für iOS, Android u. Windowsphone) oder PC über <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " target="_blank" href="http://aldi-music.de" style="color:blue">aldi-music.de</a>. Internetverbindung per (W)LAN oder ungedrosselte mobile Datenverbindung erforderl..</di18n></p>
                                    <p #footnote id="footnote_12"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928d-9af5-11eb-86b5-ddb791afb374">12</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928e-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Musik-Paket L: 14,99 €/4 Wochen. Enthalten: Gespräche/ SMS innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Internet-Flatrate mit 5 GB High-Speed-Datenvolumen. Maximale Geschwindigkeit bis zum Verbrauch des im Tarif enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s. Zugang zum Musik-Streaming-Dienst „ALDI Music powered by Napster“ ohne weitere Berechnung (separate Registrierung bei ALDI Music sowie Zustimmung zu den jew. AGB und Datenschutzbestimmungen von „ALDI Music powered by Napster“ erforderl.. Anbieter des Musik-Streaming-Dienstes ist Napster Luxembourg S.à r.l. 60, Route de Luxembourg L-5408 Bous, Luxemburg. Infos:  <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " target="_blank" href="http://aldi-music.de" style="color:blue">aldi-music.de</a>). Umsatzsteuerrechtlicher Leistungserbringer ist die EPS. Nutzung per Smartphone/ Tablet (Apps für iOS, Android u. Windowsphone) oder PC über <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " target="_blank" href="http://aldi-music.de" style="color:blue">aldi-music.de</a>. Internetverbindung per (W)LAN oder ungedrosselte mobile Datenverbindung erforderl..</di18n></p>
                                    <p #footnote id="footnote_13"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a928f-9af5-11eb-86b5-ddb791afb374">13</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9290-9af5-11eb-86b5-ddb791afb374"> Buchbar zum ALDI TALK Basistarif. Enthalten: Gespräche aus dem EU-Ausland, Großbritannien u. Schweiz in die EU, Großbritannien u. Schweiz (Minutentakt). Laufzeit 7 Tage. Nicht verbrauchte Inklusiveinheiten verfallen am Laufzeitende. Nach Ablauf gelten die Preise gem. Roaming-Preisliste unter www.alditalk.de.</di18n></p>
                                    <p #footnote id="footnote_14"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9291-9af5-11eb-86b5-ddb791afb374">14</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9292-9af5-11eb-86b5-ddb791afb374"> Roaming-Preise gelten aus dem EU-Ausland in die EU. Gespräche (60/1) und SMS zu ALDI TALK 0,03 €/Min./SMS, Standard-Gespräche (60/1) und SMS 0,11 €/Min./SMS. Datenverbindung 0,24 €/MB (Taktung 10 KB Schritte).</di18n></p>
                                    <p #footnote id="footnote_15"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9293-9af5-11eb-86b5-ddb791afb374">15</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9294-9af5-11eb-86b5-ddb791afb374"> Neukundenvorteil: Bei Auswahl des Pakets M, L, Musik-Paket L oder Internet-Flatrate XL im online SIM-Karten-Aktivierungsprozess auf alditalk.de, kostet die Tarifoption für die ersten 4 Wochen ab Aktivierung der SIM-Karte nur 10 €, die direkt bei Aktivierung mit dem Startguthaben verrechnet werden. Bei Auswahl der Zahlungsart „Bankkonto“ wird der ermäßigte Preis in Höhe von 10 € direkt vom Bankkonto abgebucht. Maximale Geschwindigkeit bis zum Verbrauch des in der jeweiligen Tarifoption enthaltenen High-Speed-Datenvolumens 25 Mbit/s im Download und 10 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s. Bei 25 Mbit/s handelt es sich um die technisch mögliche Maximalgeschwindigkeit. Die tatsächlich und durchschnittlich erreichte Geschwindigkeit kann davon abweichen und ist u.a. abhängig vom Endgerätetyp und Netzausbaugebiet.</di18n></p>
                                    <p #footnote id="footnote_16"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9295-9af5-11eb-86b5-ddb791afb374">16</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9296-9af5-11eb-86b5-ddb791afb374"> Bei Optionsbuchung (ausgenommen 24 Stunden Internet-Flatrate): 4 Wochen Laufzeit mit automat. Verlängerung. Abbestellung jederzeit - zum Laufzeitende - möglich.</di18n></p>
                                    <p #footnote id="footnote_17"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9297-9af5-11eb-86b5-ddb791afb374">17</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9298-9af5-11eb-86b5-ddb791afb374"> Voraussetzung für die Gewährung des Bonusguthabens (von 25 €) auf dem Prepaid Konto: erfolgreiche Rufnummern-Mitnahme in den ALDI TALK Prepaid Tarif. Auszahlung des Bonusguthabens nicht möglich. Für die Rufnummern-Mitnahme wird in der Regel ein Entgelt vom bisherigen Mobilfunkanbieter erhoben.</di18n></p>
                                    <p #footnote id="footnote_18"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a9299-9af5-11eb-86b5-ddb791afb374">18</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929a-9af5-11eb-86b5-ddb791afb374"> Buchbar zum ALDI TALK Basistarif oder zum ALDI TALK Hotspot. Enthalten: Datenverbindungen im EU-Ausland, Großbritannien u. Schweiz. Laufzeit 7 Tage. Ausschöpfung Inklusiveinheiten taktungsabhängig: 100 KB-Taktung. Jederzeit neu buchbar. Nicht verbrauchte Inklusiveinheiten verfallen am Laufzeitende/ bei Neubuchung. Nach Ablauf gelten im ALDI TALK Basistarif die Preise gem. Roaming-Preisliste unter www.alditalk.de.</di18n></p>
                                    <p #footnote id="footnote_19"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929b-9af5-11eb-86b5-ddb791afb374">19</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929c-9af5-11eb-86b5-ddb791afb374"> Die LTE-Technologie ist nur in Gebieten mit LTE-Netzabdeckung und nur mit kompatibler Hardware nutzbar. Die Nutzung der Technologie erfolgt im Rahmen der maximalen Geschwindigkeit von 25 Mbit/s im Download und 10 Mbit/s im Upload.</di18n></p>
                                    <p #footnote id="footnote_20"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929d-9af5-11eb-86b5-ddb791afb374">20</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929e-9af5-11eb-86b5-ddb791afb374"> Die jeweiligen Mitgliedstaaten der EU (derzeit: Belgien, Bulgarien, Dänemark, Estland, Finnland, Frankreich – inkl. Französisch-Guayana, Guadeloupe, La Réunion, Martinique, Mayotte, Saint-Barthélemy, Saint-Martin –, Griechenland, Irland, Italien – inkl. Vatikanstadt –, San Marino, Kroatien, Lettland, Litauen, Luxemburg, Malta, Niederlande, Österreich, Polen, Portugal – inkl. Azoren und Madeira –, Rumänien, Schweden, Slowakei, Slowenien, Spanien – inkl. Kanarische Inseln –, Tschechien, Ungarn, Zypern – südlicher Teil –) sowie alle weiteren Länder, für die die Preisvorgaben der EU (Verordnung (EU) 2015/2120 vom 25.11.2015 in der jeweils geltenden Fassung (derzeit: Verordnung (EU) 2018/1971 vom 11.12.2018)) gelten (derzeit: Island, Liechtenstein, Norwegen). Soweit die Preisvorgaben der EU Verordnung in einem Land nicht (mehr) anwendbar sind, fällt das Land automatisch in die Zone „Ländergruppe 2“ und es gilt der für diese Zone ausgewiesene Preis. Für Großbritannien wird trotz Ausscheidens aus der EU bis zum 31.12.2021 weiterhin nur der Preis gem. Zone 1 (EU-reguliert) abgerechnet (Verlängerung vorbehalten).</di18n></p>
                                    <p #footnote id="footnote_21"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a929f-9af5-11eb-86b5-ddb791afb374">21</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a92a0-9af5-11eb-86b5-ddb791afb374"> Buchbar zum ALDI TALK Basistarif oder zum ALDI TALK Hotspot. Enthalten: Datenverbindungen im Ausland gemäß jeweils aktuell gültiger Länderliste abrufbar unter alditalk.de/weltpaket. Laufzeit 7 Tage. Ausschöpfung Inklusiveinheiten taktungsabhängig: 100 KB-Taktung. Jederzeit neu buchbar. Nicht verbrauchte Inklusiveinheiten verfallen am Laufzeitende/ bei Neubuchung. Nach Ablauf gelten im ALDI TALK Basistarif die Preise gem. Roaming-Preisliste unter www.alditalk.de</di18n></p>
                                    <p #footnote id="footnote_22"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a92a1-9af5-11eb-86b5-ddb791afb374">22</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a92a2-9af5-11eb-86b5-ddb791afb374"> Leistungserbringer der Mobilfunkdienstleistung ist E-Plus Service GmbH (EPS), E-Plus-Straße 1, 40472 Düsseldorf. ALDI und MEDION handeln im Namen und für Rechnung der EPS. Startguthaben nicht auszahlbar. Aktivierung der SIM-Karte unter alditalk.de. Die Internet-Nutzung über den ALDI TALK Hotspot ist nach Buchung einer Tarifoption (z.B. Internet-Paket) möglich.</di18n></p>
                                    <p #footnote id="footnote_23"><strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a92a3-9af5-11eb-86b5-ddb791afb374">23</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@4e9a92a4-9af5-11eb-86b5-ddb791afb374"> ALDI TALK Internet-Paket XL Neukundenvorteil: Bei Auswahl des Internet Pakets XL im online SIM-Karten-Aktivierungsprozess auf alditalk.de, kostet die Tarifoption für die ersten 4 Wochen ab Aktivierung der SIM-Karte nur 10 €, die direkt bei Aktivierung mit dem Startguthaben verrechnet werden. Bei Auswahl der Zahlungsart „Bezahlen per Bankkonto“ wird der ermäßigte Preis in Höhe von 10 € direkt vom Bankkonto abgebucht. Danach kostet das ALDI TALK Internet-Paket XL14,99 €/4 Wochen. Enthalten: Datenverbindungen innerhalb Deutschlands und im EU-Ausland. Das Internet-Paket XL wird nach vollständigem Verbrauch des Inklusiv-Datenvolumens, spätestens jedoch nach Ablauf der jeweiligen Laufzeit, deaktiviert. Nicht verbrauchte Inklusiveinheiten verfallen am Ende der Laufzeit. Maximale Geschwindigkeit: 25 Mbit/s im Download und 10 Mbit/s im Upload. Bei 25 Mbit/s handelt es sich um die technisch mögliche Maximalgeschwindigkeit. Die tatsächlich und durchschnittlich erreichte Geschwindigkeit kann davon abweichen und ist u.a. abhängig vom Endgerätetyp und Netzausbaugebiet. Die erfolgreiche Buchung wird Ihnen im SMS-Postfach der Hotspot-Konfigurationsseite bestätigt. Keine automatische Verlängerung des Pakets.</di18n></p>
                                    <p #footnote id="footnote_24"><strong><di18n di18nd="AppComponent|xdi18ntagged@@fe0a664f-cdf6-41ec-bf19-f0c64101679d">24</di18n></strong><di18n di18nd="AppComponent|xdi18ntagged@@8c23487a-7cad-4523-9460-d09a69269535"> ALDI TALK Kidswatch Tarifoption: 3,99 €/4 Wochen. Automat. Verlängerung um 4 Wochen, wenn keine Abbestellung zum Ende der Laufzeit erfolgt. Für Sprachverbindungen innerhalb Deutschlands und im EU-Ausland. Ohne Sondernr., (Mehrwert-)Dienste. EU-weite Datennutzung mit 400 MB Datenvolumen mit einer maximalen Geschwindigkeit von 0,5 Mbit/s im Download und 0,5 Mbit/s im Upload, danach bis zum Ende des jew. Abrechnungszeitraums max. 64 kbit/s im Up- und Download. Kidswatch Tarifoption nur mit Kidswatch SIM-Karte buchbar (liegt kostenlos der Kidswatch bei). Voraussetzung zur Buchung der Tarifoption ist die Teilnahme am Lastschriftverfahren. Leistungserbringer der Mobilfunkdienstleistung ist E-Plus Service GmbH (EPS), E-Plus-Straße 1, 40472. Die EPS erbringt ihre Leistung im Netz der Telefónica Germany GmbH & Co. OHG. ALDI und MEDION handeln im Namen und für Rechnung der EPS. Alle Infos: <a [ngClass]="(disableClick$ | async) ? 'disabled-clicks' : '' " target="_blank" href="https://alditalk.de" style="color:blue">alditalk.de</a></di18n></p>
                                </div>
                            </div>
                            <div class="accordion footer__footnote" *ngIf="(appFeaturesAppType | async) === 'app'"><div class="title impressum"><di18n di18nd="AppComponent|xdi18ntagged@@2c318975-60a6-4036-a944-9d7a956df350"><a style="color: #002c5c" href="https://www.alditalk.de/datenschutzmerkblatt" >Datenschutzmerkblatt</a></di18n></div>
                            </div>
                            <div class="accordion footer__footnote" *ngIf="(appFeaturesAppType | async) === 'app'"><div class="title impressum"><di18n di18nd="AppComponent|xdi18ntagged@@01515919-0758-4784-8cf4-a47b6ff5ecdc"><a style="color: #002c5c" href="https://www.alditalk.de/impressum" >Impressum</a></di18n></div>
                            </div>
                            <div class="footer__footnote-logos"><img src="https://media.medion.com/cms/medion/alditalkde/medion-mobile-23.png" border="0" alt="MEDION mobile" width="160" height="23" style="padding-right: 6px;"> <img src="https://media.medion.com/cms/medion/alditalkde/eplus-23.png" border="0" alt="e-plus" width="59" height="23"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>